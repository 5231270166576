import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INationality, INationalityInitialState } from "./nationalityModel";
import {
  ActiveInactiveNationality,
  createNationality,
  deleteNationality,
  getAllActiveNationalities,
  getAllNationalities,
  getNationalityByID,
  updateNationality,
} from "./services/nationality.services";

const initialState: INationalityInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  NationalityList: [],
  NationalityID: null,
  NationalityDetail: {
    ID: null,
    Nationality: "",
    IsActive: false,
  },
};

const nationalitySlice = createSlice({
  name: "nationality",
  initialState,
  reducers: {
    clearNationalityDetails: (state) => {
      state.NationalityDetail = initialState.NationalityDetail;
    },
    setNationalityID: (state, action) => {
      state.NationalityID = action.payload;
    },
    clearNationalityID: (state) => {
      state.NationalityID = initialState.NationalityID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllNationalities.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllNationalities.fulfilled,
      (state, action: PayloadAction<INationality[]>) => {
        state.loading = false;
        state.NationalityList = action.payload || [];
      }
    );
    builder.addCase(getAllNationalities.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.NationalityList = [];
    });
    builder.addCase(createNationality.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createNationality.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createNationality.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateNationality.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateNationality.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateNationality.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteNationality.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteNationality.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteNationality.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getNationalityByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getNationalityByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.NationalityDetail = action.payload;
    });
    builder.addCase(getNationalityByID.rejected, (state, action) => {
      state.formLoading = false;
      state.NationalityDetail = initialState.NationalityDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveNationalities.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveNationalities.fulfilled,
      (state, action: PayloadAction<INationality[]>) => {
        state.loading = false;
        state.NationalityList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveNationalities.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.NationalityList = [];
    });
    builder.addCase(ActiveInactiveNationality.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveNationality.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveNationality.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearNationalityDetails,
  setNationalityID,
  clearNationalityID,
} = nationalitySlice.actions;
export default nationalitySlice.reducer;
