import React, { useRef } from "react";
// import { Table } from "antd";
// import type { TableColumnsType } from "antd";
import { FaStar } from "react-icons/fa";
import RippleButton from "../../components/common/RippleButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";

// interface DataType {
//   key: React.Key;
//   HotelName: string;
//   LandMark: string;
//   StarRating: string;
//   RateNoOfNights: number | null;
//   RoomType: string;
//   ExtraAdultRate: number | null;
//   CWBRate: number | null;
//   CNBRate: number | null;
// }

const renderStar = (starCount: number) => {
  const stars = [];
  for (let i = 0; i < starCount; i++) {
    stars.push(<FaStar color="goldenrod" />);
  }
  return stars;
};

// const columns: TableColumnsType<DataType> = [
//   {
//     title: "Hotel",
//     dataIndex: "HotelName",
//     render: (text: string) => <p>{text}</p>,
//   },
//   {
//     title: "Area",
//     dataIndex: "LandMark",
//   },
//   {
//     title: "Star",
//     dataIndex: "StarRating",
//     render: (star: string) => renderStar(parseInt(star, 10)),
//     defaultSortOrder: "descend",
//     sorter: (a, b) =>
//       a.StarRating && b.StarRating ? +a.StarRating - +b.StarRating : 0,
//   },
//   {
//     title: "Nights",
//     dataIndex: "RateNoOfNights",
//     defaultSortOrder: "descend",
//     sorter: (a, b) =>
//       a.RateNoOfNights && b.RateNoOfNights
//         ? a.RateNoOfNights - b.RateNoOfNights
//         : 0,
//   },
//   {
//     title: "Room",
//     dataIndex: "RoomType",
//   },
//   {
//     title: "Adult",
//     dataIndex: "ExtraAdultRate",
//   },
//   {
//     title: "CWB",
//     dataIndex: "CWBRate",
//   },
//   {
//     title: "CNB",
//     dataIndex: "CNBRate",
//   },
// ];

const Package: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const TourID = location.state?.TourID;
  const NoOfNights = location.state?.NoOfNights;
  const FromDate = location.state?.FromDate;
  const gridRef = useRef<any>(null);
  const loading = useAppSelector((state) => state.readymadePackage.loading);
  const PackageDetail = useAppSelector(
    (state) => state.readymadePackage.PackageDetail
  );
  // console.log("PackageDetail", PackageDetail);
  const [packageID, setPackageID] = React.useState(null);

  const handleRadioChange = (item: any) => {
    setPackageID(item?.PackageID ? item?.PackageID : null);
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <div>
      <div style={{ textAlign: "end", marginBottom: 10 }}>
        <RippleButton
          style={{ marginTop: 5 }}
          disabled={packageID ? false : true}
          onClick={() =>
            navigate("/customizesearch", {
              state: {
                TourID: TourID ? TourID : null,
                NoOfNights: NoOfNights ? NoOfNights : null,
                FromDate: FromDate ? FromDate : "",
                PackageID: packageID ? packageID : null,
              },
            })
          }
        >
          Select Option & Proceed
        </RippleButton>
      </div>
      {/* <Table
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}
        columns={columns}
        dataSource={data}
      /> */}
      {PackageDetail?.PackagesList?.flatMap((item: any, index: number) => {
        return (
          <table width={"100%"} className="packageTabel mb-2">
            <tr>
              <th></th>
              <th>Hotel</th>
              <th>Area</th>
              <th>Star</th>
              <th>Nights</th>
              <th>Room</th>
              <th>Adult</th>
              <th>CWB</th>
              <th>CNB</th>
            </tr>
            <tr>
              <td style={{ width: "3%" }}>
                <input
                  type="radio"
                  key={index}
                  name="package"
                  onClick={() => handleRadioChange(item)}
                />
              </td>
              <td style={{ width: "22%" }}>
                {item?.Packages?.map(
                  (packages: any, innerIndex: number, array: any[]) => (
                    <span
                      style={{
                        display: "block",
                        borderBottom: `${
                          innerIndex !== array.length - 1
                            ? "1px solid black"
                            : "none"
                        }`,
                      }}
                    >
                      {packages?.HotelName || "-"}
                    </span>
                  )
                )}
              </td>
              <td style={{ width: "10%" }}>
                {item?.Packages?.map(
                  (packages: any, innerIndex: number, array: any[]) => (
                    <span
                      style={{
                        display: "block",
                        borderBottom: `${
                          innerIndex !== array.length - 1
                            ? "1px solid black"
                            : "none"
                        }`,
                      }}
                    >
                      {packages?.LandMark || "-"}
                    </span>
                  )
                )}
              </td>
              <td style={{ width: "10%" }}>
                {item?.Packages?.map(
                  (packages: any, innerIndex: number, array: any[]) => (
                    <span
                      style={{
                        display: "block",
                        borderBottom: `${
                          innerIndex !== array.length - 1
                            ? "1px solid black"
                            : "none"
                        }`,
                      }}
                    >
                      {packages?.StarRating && packages?.StarRating > 0
                        ? renderStar(packages?.StarRating)
                        : "-"}
                    </span>
                  )
                )}
              </td>
              <td style={{ width: "10%" }}>
                {item?.Packages?.map(
                  (packages: any, innerIndex: number, array: any[]) => (
                    <span
                      style={{
                        display: "block",
                        borderBottom: `${
                          innerIndex !== array.length - 1
                            ? "1px solid black"
                            : "none"
                        }`,
                      }}
                    >
                      {packages?.RateNoOfNights}
                    </span>
                  )
                )}
              </td>
              <td style={{ width: "15%" }}>
                {item?.Packages?.map(
                  (packages: any, innerIndex: number, array: any[]) => (
                    <span
                      style={{
                        display: "block",
                        borderBottom: `${
                          innerIndex !== array.length - 1
                            ? "1px solid black"
                            : "none"
                        }`,
                      }}
                    >
                      {packages?.RoomType || "-"}
                    </span>
                  )
                )}
              </td>
              <td style={{ width: "10%" }}>
                {item?.Packages?.map(
                  (packages: any, innerIndex: number, array: any[]) => (
                    <span
                      style={{
                        display: "block",
                        borderBottom: `${
                          innerIndex !== array.length - 1
                            ? "1px solid black"
                            : "none"
                        }`,
                      }}
                    >
                      {packages?.ExtraAdultRate}
                    </span>
                  )
                )}
              </td>
              <td style={{ width: "10%" }}>
                {item?.Packages?.map(
                  (packages: any, innerIndex: number, array: any[]) => (
                    <span
                      style={{
                        display: "block",
                        borderBottom: `${
                          innerIndex !== array.length - 1
                            ? "1px solid black"
                            : "none"
                        }`,
                      }}
                    >
                      {packages?.CWBRate}
                    </span>
                  )
                )}
              </td>
              <td style={{ width: "10%" }}>
                {item?.Packages?.map(
                  (packages: any, innerIndex: number, array: any[]) => (
                    <span
                      style={{
                        display: "block",
                        borderBottom: `${
                          innerIndex !== array.length - 1
                            ? "1px solid black"
                            : "none"
                        }`,
                      }}
                    >
                      {packages?.CNBRate}
                    </span>
                  )
                )}
              </td>
            </tr>
          </table>
        );
      })}
    </div>
  );
};

export default Package;
