import { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  createSector,
  getAllSectors,
  getSectorByID,
  updateSector,
} from "./services/sector.services";
import { clearSectorDetails } from "./sectorSlice";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { SECTORTYPE } from "../../_contstants/common";
import { IRegion } from "../region/regionModel";
import { ICountry } from "../country/countryModel";
import { getAllActiveCountries } from "../country/services/country.services";
import { getAllActiveRegions } from "../region/services/region.services";
import { ICity } from "../city/cityModel";
import { getAllActiveCities } from "../city/services/city.services";
import FormMultiSelectionFiled from "../../components/formFields/FormMultiSelectionFiled";
import { closed } from "../../components/drawer/drawerSlice";

const CreateSector = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const SectorDetail = useAppSelector((state) => state.sector.SectorDetail);
  const SectorID = useAppSelector((state) => state.drawer.data);
  const RegionList = useAppSelector((state) => state.region.RegionList);
  const CityList = useAppSelector((state) => state.city.CityList);
  const CountryList = useAppSelector((state) => state.country.CountryList);
  const formLoading = useAppSelector((state) => state.sector.formLoading);

  useEffect(() => {
    dispatch(getAllActiveCities());
    dispatch(getAllActiveRegions());
    dispatch(getAllActiveCountries());
    return () => {
      dispatch(clearSectorDetails());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (SectorID) {
      dispatch(getSectorByID(SectorID));
    }
  }, [SectorID]);

  const handleSubmit = async (values: any) => {
    try {
      if (!SectorID) {
        const insertPayload = {
          CityID: Array.isArray(values.CityID)
            ? values.CityID.join(",")
            : values.CityID,
          CountryID: Array.isArray(values.CountryID)
            ? values.CountryID.join(",")
            : values.CountryID,
          Prefix: values.Prefix,
          RegionID: values.RegionID,
          SectorName: values.SectorName,
          SectorType: values.SectorType,
          IsActive: true,
        };
        const response = await dispatch(createSector(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearSectorDetails());
          dispatch(getAllSectors());
        }
      } else {
        const payload = {
          ID: SectorID,
          CityID: Array.isArray(values.CityID)
            ? values.CityID.join(",")
            : values.CityID,
          CountryID: Array.isArray(values.CountryID)
            ? values.CountryID.join(",")
            : values.CountryID,
          Prefix: values.Prefix,
          RegionID: values.RegionID,
          SectorName: values.SectorName,
          SectorType: values.SectorType,
          IsActive: values.IsActive,
        };
        const response = await dispatch(updateSector(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearSectorDetails());
          dispatch(getAllSectors());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={SectorDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="SectorName"
                label="Sector"
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="Prefix"
                label="Prefix"
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="SectorType"
                label="Sector Type"
                component={FormSelectionField}
                options={SECTORTYPE?.map((sectorType: any) => {
                  return {
                    value: sectorType?.value,
                    label: sectorType?.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="RegionID"
                label="Region"
                component={FormSelectionField}
                options={RegionList?.map((region: IRegion) => {
                  return {
                    value: region?.ID,
                    label: region?.RegionName,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="CityID"
                label="City"
                component={FormMultiSelectionFiled}
                options={CityList?.map((city: ICity) => {
                  return {
                    value: city?.ID,
                    label: city?.CityName,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="CountryID"
                label="Country"
                component={FormMultiSelectionFiled}
                options={CountryList?.map((coountry: ICountry) => {
                  return {
                    value: coountry?.ID,
                    label: coountry?.CountryName,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "end",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginRight: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
              <ButtonWithLoading
                label={SectorID ? "Update" : "Create"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateSector;
