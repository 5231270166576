import React from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormRichTextField from "../../../components/formFields/FormRichTextField";
import FormTextField from "../../../components/formFields/FormTextField";

const TourItineraryArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((TourPlace: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card className="fieldarray-card">
              <GridLayout
                style={{ marginRight: 10 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[{ width: "10%" }, { width: "90%" }]}
              >
                <GridLayoutItem>
                  <div style={{ marginTop: 20 }} className="itinerary-label">
                    Day {index + 1}
                  </div>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`TourItineraryList.${index}.Title`}
                    label="Title"
                    component={FormTextField}
                    validator={requiredValidator}
                    wrapperClassName="m-0"
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    name={`TourItineraryList.${index}.Description`}
                    label="Itinerary Description"
                    component={FormRichTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
    </GridLayout>
  );
};

export default TourItineraryArray;
