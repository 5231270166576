import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import {
  requiredArrayValidator,
  requiredValidator,
} from "../../../components/formFields/CommonValidator";
import FormTextField from "../../../components/formFields/FormTextField";
import FormTextArea from "../../../components/formFields/FormTextArea";
// import FormTimePicker from "../../../components/formFields/FormTimeField";
import {
  SECTORTYPE,
  STATUSARRAY,
  TRAVELTYPE,
} from "../../../_contstants/common";
import { getAllActiveCompanies } from "../../company/services/company.services";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ICompany } from "../../company/companyModel";
import { getAllActiveSectors } from "../../sector/services/sector.services";
import { ISector } from "../../sector/sectorModel";
import { getAllActiveSpecialityTypes } from "../../specialitytype/services/specialityType.services";
import { ISpecialityType } from "../../specialitytype/specialityTypeModel";
import RippleButton from "../../../components/common/RippleButton";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import {
  createTour,
  getTourByID,
  updateTour,
  uploadTourImage,
} from "../services/tour.services";
import moment from "moment";
import { clearTourInformation, increaseActiveStep } from "../tourSlice";
import FormMultiSelectionFiled from "../../../components/formFields/FormMultiSelectionFiled";
import { IGST } from "../../gst/gstModel";
import { getAllActiveGST } from "../../gst/services/gst.services";
import ShadowCard from "../../../components/common/ShadowCard";
import FormRichTextField from "../../../components/formFields/FormRichTextField";
import FormImageUpload from "../../../components/formFields/FormImageUpload";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const TourCodeChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const SectorID = formRenderProps.valueGetter("SectorID");
  const SectorList = useAppSelector((state) => state.sector.SectorList);
  const isTourCodeRef = useRef(true);

  const sectorCode = SectorList?.filter((item) => item.ID === SectorID);
  useEffect(() => {
    if (!isTourCodeRef.current) {
      formRenderProps.onChange("TourCode", {
        value: sectorCode[0]?.Prefix ? sectorCode[0]?.Prefix : "",
      });
    } else {
      isTourCodeRef.current = false;
    }
  }, [SectorID]);
  return null;
};
const TourInformation: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const TourID = location.state?.TourID;
  const loading = useAppSelector((state) => state.tour.loading);
  const TourDetail = useAppSelector((state) => state.tour.TourDetail);
  const CompanyList = useAppSelector((state) => state.company.CompanyList);
  const SectorList = useAppSelector((state) => state.sector.SectorList);
  const GSTList = useAppSelector((state) => state.gst.GSTList);
  const SpecialityTypeList = useAppSelector(
    (state) => state.specialityType.SpecialityTypeList
  );

  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    dispatch(getAllActiveCompanies());
    dispatch(getAllActiveGST());
    dispatch(getAllActiveSectors());
    dispatch(getAllActiveSpecialityTypes());
    if (TourID) {
      dispatch(getTourByID(+TourID));
    }
    return () => {
      dispatch(clearTourInformation());
    };
  }, []);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [TourDetail]);

  const handleNoOfNightsChange = (e: any, formRenderProps: FormRenderProps) => {
    formRenderProps?.onChange(`NoOfDays`, {
      value: +e.value + 1 || null,
    });
  };

  const handleSubmit = async (values: any) => {
    try {
      const TourImage = values.TourImage;
      if (!TourID) {
        const insertPayload = {
          SectorType: values.SectorType ? values.SectorType : "",
          SectorID: values.SectorID ? +values.SectorID : null,
          SubSectorID: values.SubSectorID ? +values.SubSectorID : null,
          GSTID: values.GSTID ? +values.GSTID : null,
          TravelType: values.TravelType ? values.TravelType : "",
          TourName: values.TourName ? values.TourName : "",
          Inclusion: values.Inclusion ? values.Inclusion : "",
          Exclusion: values.Exclusion ? values.Exclusion : "",
          OtherInformation: values.OtherInformation
            ? values.OtherInformation
            : "",
          SpecialityTypeID: Array.isArray(values.SpecialityTypeID)
            ? values.SpecialityTypeID.join(",")
            : values.SpecialityTypeID,
          TourCode: values.TourCode ? values.TourCode : "",
          CompanyID: values.CompanyID ? +values.CompanyID : null,
          NoOfNights: values.NoOfNights ? +values.NoOfNights : null,
          NoOfDays: values.NoOfDays ? +values.NoOfDays : null,
          SpecialRemarks: values.SpecialRemarks ? values.SpecialRemarks : "",
          PickupPlace: values.PickupPlace ? values.PickupPlace : "",
          PickupTime: values.PickupTime
            ? moment(values.PickupTime).format("HH:mm:ss")
            : "",
          PickupRemarks: values.PickupRemarks ? values.PickupRemarks : "",
          DropPlace: values.DropPlace ? values.DropPlace : "",
          DropTime: values.DropTime
            ? moment(values.DropTime).format("HH:mm:ss")
            : "",
          DropRemarks: values.DropRemarks ? values.DropRemarks : "",
          PickupFlightPlace: values.PickupFlightPlace
            ? values.PickupFlightPlace
            : "",
          DropFlightPlace: values.DropFlightPlace ? values.DropFlightPlace : "",
          IsActive: values.IsActive === 1,
        };
        const response = await dispatch(createTour(insertPayload));
        if (
          response?.meta?.requestStatus === "fulfilled" &&
          response.payload.Data?.ID
        ) {
          if (TourImage && typeof TourImage !== "string") {
            const ImageData = new FormData();
            const file = TourImage[0].getRawFile();
            ImageData.append("ID", response.payload.Data?.ID);
            ImageData.append("TourImage", file);
            const responseData = await dispatch(uploadTourImage(ImageData));
            if (responseData?.meta?.requestStatus === "fulfilled") {
              navigate("/tour/edit", {
                state: {
                  TourID: response.payload.Data?.ID,
                  NoOfDays: values.NoOfDays ? +values.NoOfDays : null,
                  SectorID: values.SectorID ? values.SectorID : "",
                },
              });
              dispatch(increaseActiveStep());
            } else {
              navigate("/tour/edit", {
                state: {
                  TourID: response.payload.Data?.ID,
                  NoOfDays: values.NoOfDays ? +values.NoOfDays : null,
                  SectorID: values.SectorID ? values.SectorID : "",
                },
              });
              dispatch(increaseActiveStep());
            }
          } else {
            navigate("/tour/edit", {
              state: {
                TourID: response.payload.Data?.ID,
                NoOfDays: values.NoOfDays ? +values.NoOfDays : null,
                SectorID: values.SectorID ? values.SectorID : "",
              },
            });
            dispatch(increaseActiveStep());
          }
        }
      } else {
        const editPayload = {
          ID: TourID,
          SectorType: values.SectorType ? values.SectorType : "",
          SectorID: values.SectorID ? +values.SectorID : null,
          SubSectorID: values.SubSectorID ? +values.SubSectorID : null,
          GSTID: values.GSTID ? +values.GSTID : null,
          TravelType: values.TravelType ? values.TravelType : "",
          TourName: values.TourName ? values.TourName : "",
          Inclusion: values.Inclusion ? values.Inclusion : "",
          Exclusion: values.Exclusion ? values.Exclusion : "",
          OtherInformation: values.OtherInformation
            ? values.OtherInformation
            : "",
          SpecialityTypeID: Array.isArray(values.SpecialityTypeID)
            ? values.SpecialityTypeID.join(",")
            : values.SpecialityTypeID,
          TourCode: values.TourCode ? values.TourCode : "",
          CompanyID: values.CompanyID ? +values.CompanyID : null,
          NoOfNights: values.NoOfNights ? +values.NoOfNights : null,
          NoOfDays: values.NoOfDays ? +values.NoOfDays : null,
          SpecialRemarks: values.SpecialRemarks ? values.SpecialRemarks : "",
          PickupPlace: values.PickupPlace ? values.PickupPlace : "",
          PickupTime: values.PickupTime
            ? moment(values.PickupTime).format("HH:mm:ss")
            : "",
          PickupRemarks: values.PickupRemarks ? values.PickupRemarks : "",
          DropPlace: values.DropPlace ? values.DropPlace : "",
          DropTime: values.DropTime
            ? moment(values.DropTime).format("HH:mm:ss")
            : "",
          DropRemarks: values.DropRemarks ? values.DropRemarks : "",
          PickupFlightPlace: values.PickupFlightPlace
            ? values.PickupFlightPlace
            : "",
          DropFlightPlace: values.DropFlightPlace ? values.DropFlightPlace : "",
          IsActive: values.IsActive === 1,
        };
        const response = await dispatch(updateTour(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          if (TourImage && TourID && typeof TourImage !== "string") {
            const ImageData = new FormData();
            const file = TourImage[0].getRawFile();
            ImageData.append("ID", TourID);
            ImageData.append("TourImage", file);
            const responseData = await dispatch(uploadTourImage(ImageData));
            if (responseData?.meta?.requestStatus === "fulfilled") {
              navigate("/tour/edit", {
                state: {
                  TourID: TourID,
                  NoOfDays: values.NoOfDays ? +values.NoOfDays : null,
                  SectorID: values.SectorID ? values.SectorID : "",
                },
              });
              dispatch(increaseActiveStep());
            } else {
              navigate("/tour/edit", {
                state: {
                  TourID: TourID,
                  NoOfDays: values.NoOfDays ? +values.NoOfDays : null,
                  SectorID: values.SectorID ? values.SectorID : "",
                },
              });
              dispatch(increaseActiveStep());
            }
          } else {
            navigate("/tour/edit", {
              state: {
                TourID: TourID,
                NoOfDays: values.NoOfDays ? +values.NoOfDays : null,
                SectorID: values.SectorID ? values.SectorID : "",
              },
            });
            dispatch(increaseActiveStep());
          }
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      key={formKey}
      initialValues={TourDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <ShadowCard style={{ padding: 10 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem colSpan={3}>
                <Typography.h4>{"Tour Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"SectorType"}
                  name={"SectorType"}
                  label={"Sector type"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={SECTORTYPE?.map((sectorType: any) => {
                    return {
                      value: sectorType.value,
                      label: sectorType.label,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"TravelType"}
                  name={"TravelType"}
                  label={"Travel type"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={TRAVELTYPE?.map((sectorType: any) => {
                    return {
                      value: sectorType.value,
                      label: sectorType.label,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"CompanyID"}
                  name={"CompanyID"}
                  label={"Company"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={CompanyList.map((company: ICompany) => {
                    return {
                      value: company.ID,
                      label: company.CompanyName,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"SectorID"}
                  name={"SectorID"}
                  label={"Sector"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={SectorList?.map((sector: ISector) => {
                    return {
                      value: sector?.ID,
                      label: sector?.SectorName,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="TourName"
                  label="Tour Name"
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="TourCode"
                  label="Tour Code"
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"SpecialityTypeID"}
                  name={"SpecialityTypeID"}
                  label={"Tour Speciality"}
                  component={FormMultiSelectionFiled}
                  validator={requiredArrayValidator}
                  options={SpecialityTypeList?.map(
                    (sector: ISpecialityType) => {
                      return {
                        value: sector?.ID,
                        label: sector?.SpecialityType,
                      };
                    }
                  )}
                />
              </GridLayoutItem>
              <GridLayoutItem
                style={{ width: "100%", display: "flex", gap: 10 }}
              >
                <Field
                  wrapperClassName="w-100"
                  name="NoOfNights"
                  label="Nights"
                  type="number"
                  onChange={(e) => handleNoOfNightsChange(e, formRenderProps)}
                  component={FormTextField}
                  validator={requiredValidator}
                />
                <Field
                  wrapperClassName="w-100"
                  name="NoOfDays"
                  label="Days"
                  disabled
                  type="number"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"GSTID"}
                  name={"GSTID"}
                  label={"GST"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={GSTList?.map((gst: IGST) => {
                    return {
                      value: gst?.ID,
                      label: gst?.Title,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"Inclusion"}
                  label={"Inclusion"}
                  component={FormRichTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"Exclusion"}
                  label={"Exclusion"}
                  component={FormRichTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"OtherInformation"}
                  label={"Other Information"}
                  component={FormRichTextField}
                />
              </GridLayoutItem>

              <GridLayoutItem>
                <Field
                  name="SpecialRemarks"
                  label="Special Remarks"
                  component={FormTextArea}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`IsActive`}
                  label="Status"
                  component={FormSelectionField}
                  options={STATUSARRAY?.map((status: any) => {
                    return {
                      value: status?.value,
                      label: status?.label,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="TourImage"
                  label="Image"
                  component={FormImageUpload}
                />
              </GridLayoutItem>
              <GridLayoutItem
                colSpan={3}
                style={{
                  textAlign: "end",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <RippleButton
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 4 }}
                  onClick={() => navigate("/tour")}
                >
                  Cancel
                </RippleButton>
                <ButtonWithLoading
                  label={TourID ? "Update" : "Add"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || loading}
                  loading={loading}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
          {/* <ShadowCard style={{ padding: 10, marginTop: 10 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem colSpan={3}>
                <Typography.h4>{"Pickup Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="PickupPlace"
                  label="Pickup Place"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="PickupFlightPlace"
                  label="Pickup Flight"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  id={"PickupTime"}
                  name={"PickupTime"}
                  label={"Pickup Time"}
                  component={FormTimePicker}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="PickupRemarks"
                  label="Pickup Remarks"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="DropPlace"
                  label="Drop Place"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="DropFlightPlace"
                  label="Drop Flight"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  id={"DropTime"}
                  name={"DropTime"}
                  label={"Drop Time"}
                  component={FormTimePicker}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="DropRemarks"
                  label="Drop Remarks"
                  component={FormTextField}
                />
              </GridLayoutItem>
              
            </GridLayout>
          </ShadowCard> */}
          <TourCodeChangeWatcher formRenderProps={formRenderProps} />
        </FormElement>
      )}
    />
  );
};

export default TourInformation;
