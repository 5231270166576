import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { clearCustomizeSearchDetails } from "./customizeSearchSlice";
import ShadowCard from "../../components/common/ShadowCard";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import FormDatePicker from "../../components/formFields/FormDateField";
import CustomizeRoomArray from "./CustomizeRoomArray";
import { getAllActiveSectors } from "../sector/services/sector.services";
import { ISector } from "../sector/sectorModel";
import { getAllFixedORCustomizedPackageByTour } from "../planner/services/planner.services";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
// import { ICity } from "../city/cityModel";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const CheckInDatechangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const FromDate = formRenderProps.valueGetter("FromDate");
  const Nights = formRenderProps.valueGetter("Nights");

  useEffect(() => {
    const ToDate = moment(FromDate)
      .add(+Nights || 0, "days")
      .toDate();
    formRenderProps.onChange("ToDate", {
      value: FromDate ? ToDate : "",
    });
  }, [FromDate, Nights]);

  return null;
};

const SectorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const NoOfNights = location.state?.NoOfNights;
  const FromDate = moment().add(1, "days").toDate();
  const SectorID = JSON.parse(sessionStorage.getItem("SectorID") as any);

  useEffect(() => {
    formRenderProps.onChange("FromDate", {
      value: FromDate || "",
    });
  }, []);

  if (NoOfNights) {
    useEffect(() => {
      formRenderProps.onChange("Nights", {
        value: NoOfNights,
      });
    }, [NoOfNights]);
  }

  if (SectorID) {
    useEffect(() => {
      formRenderProps.onChange("SectorID", {
        value: SectorID,
      });
    }, [SectorID]);
  }

  return null;
};

const CustomizeSearch: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const TourID = location.state?.TourID;
  const isHotel = location.state?.isHotel;
  const DayNo = location.state?.DayNo;
  const PackageID = location.state?.PackageID;
  const loading = useAppSelector((state) => state.customizeSearch.loading);
  const CustomizeSearchDetails = useAppSelector(
    (state) => state.customizeSearch.CustomizeSearchDetails
  );
  const SectorList = useAppSelector((state) => state.sector.SectorList);
  // const CityList = useAppSelector((state) => state.city.CityList);

  useEffect(() => {
    dispatch(getAllActiveSectors());

    return () => {
      dispatch(clearCustomizeSearchDetails());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    if (isHotel === true) {
      const Payload = {
        FromDate: values.FromDate
          ? moment(values.FromDate).format("YYYY-MM-DD")
          : "",
        ToDate: values.ToDate ? moment(values.ToDate).format("YYYY-MM-DD") : "",
        TourID: TourID ? TourID : null,
        PackageID: PackageID ? PackageID : null,
        PassengerDetails: values.PassengerDetails
          ? values.PassengerDetails.map((passenger: any) => {
              return {
                Adult: passenger.Adult ? passenger.Adult : null,
                CWB: passenger.CWB ? passenger.CWB : null,
                CNB: passenger.CNB ? passenger.CNB : null,
                Infant: passenger.Infant ? passenger.Infant : null,
              };
            })
          : [],
      };
      sessionStorage.setItem(
        "PassengerDetails",
        JSON.stringify(Payload.PassengerDetails)
      );
      navigate("/hotelandresort", {
        state: {
          FromDate: Payload.FromDate ? Payload?.FromDate : "",
          ToDate: Payload.ToDate ? Payload?.ToDate : "",
          DayNo: DayNo ? DayNo : null,
        },
      });
    } else {
      const Payload = {
        FromDate: values.FromDate
          ? moment(values.FromDate).format("YYYY-MM-DD")
          : "",
        ToDate: values.ToDate ? moment(values.ToDate).format("YYYY-MM-DD") : "",
        TourID: TourID ? TourID : null,
        PackageID: PackageID ? PackageID : null,
        PassengerDetails: values.PassengerDetails
          ? values.PassengerDetails.map((passenger: any) => {
              return {
                Adult: passenger.Adult ? passenger.Adult : null,
                CWB: passenger.CWB ? passenger.CWB : null,
                CNB: passenger.CNB ? passenger.CNB : null,
                Infant: passenger.Infant ? passenger.Infant : null,
              };
            })
          : [],
      };
      try {
        const response = await dispatch(
          getAllFixedORCustomizedPackageByTour(Payload)
        );
        if (response?.meta?.requestStatus === "fulfilled") {
          sessionStorage.setItem(
            "PassengerDetails",
            JSON.stringify(Payload.PassengerDetails)
          );
          sessionStorage.setItem(
            "PlannerList",
            JSON.stringify(response.payload)
          );
          sessionStorage.setItem("FromDate", values?.FromDate);
          sessionStorage.setItem("ToDate", values?.ToDate);
          navigate("/planner");
        } else {
          sessionStorage.removeItem("PlannerList");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={CustomizeSearchDetails}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 10 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[{ width: "40%" }, { width: "60%" }]}
              >
                <GridLayoutItem colSpan={2}>
                  <Typography.h4>
                    {isHotel === true ? "Hotel Search" : "Customize Search"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem style={{ padding: 10 }}>
                  <img
                    src="https://uandiholidays.net/Admin/UploadFiles/Advertising/WhatsAppImage2023-12-02at1.09.55PM_2-12-2023-13438.jpeg"
                    alt=""
                    height={"300px"}
                    width={"100%"}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ padding: 10 }}>
                  <GridLayout
                    style={{ marginRight: 10 }}
                    gap={{ rows: 30, cols: 10 }}
                    cols={[{ width: "50%" }, { width: "50%" }]}
                  >
                    <GridLayoutItem>
                      <Field
                        id={"SectorID"}
                        name={"SectorID"}
                        label={"Destination"}
                        wrapperStyle={{ margin: "0" }}
                        disabled={true}
                        component={FormSelectionField}
                        options={SectorList?.map((sector: ISector) => {
                          return {
                            value: sector?.ID,
                            label: sector?.SectorName,
                          };
                        })}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      {/* <Field
                        id={"CityID"}
                        name={"CityID"}
                        label={"City"}
                        wrapperStyle={{ margin: "0" }}
                        disabled={true}
                        component={FormSelectionField}
                        options={CityList?.map((city: ICity) => {
                          return {
                            value: city?.ID,
                            label: city?.CityName,
                          };
                        })}
                      /> */}
                    </GridLayoutItem>
                    <GridLayoutItem
                      colSpan={2}
                      style={{ display: "flex", gap: 10 }}
                    >
                      <Field
                        wrapperClassName="w-100"
                        name="FromDate"
                        label="Check In"
                        format="dd/MM/yyyy"
                        component={FormDatePicker}
                      />
                      <Field
                        wrapperClassName="w-100"
                        name="Nights"
                        type="number"
                        label="Nights"
                        disabled={isHotel === true ? false : true}
                        component={FormTextField}
                      />
                      <Field
                        wrapperClassName="w-100"
                        name="ToDate"
                        label="Check Out"
                        format="dd/MM/yyyy"
                        disabled={isHotel === true ? false : true}
                        component={FormDatePicker}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem colSpan={2}>
                      <FieldArray
                        formRenderProps={formRenderProps}
                        component={CustomizeRoomArray}
                        name="PassengerDetails"
                      />
                    </GridLayoutItem>
                    <GridLayoutItem
                      colSpan={2}
                      style={{
                        textAlign: "end",
                        marginTop: "20px",
                        width: "100%",
                      }}
                    >
                      <ButtonWithLoading
                        label="Search"
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </GridLayoutItem>
                  </GridLayout>
                </GridLayoutItem>
                <CheckInDatechangeWatcher formRenderProps={formRenderProps} />
                <SectorChangeWatcher formRenderProps={formRenderProps} />
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CustomizeSearch;
