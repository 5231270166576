import { Typography } from "@progress/kendo-react-common";
import React, { useRef } from "react";
import { useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import AlertBox from "../../components/common/AlertBox";

const CostIncludes: React.FC = () => {
  const gridRef = useRef<any>(null);
  const loading = useAppSelector((state) => state.readymadePackage.loading);
  const PackageDetail = useAppSelector(
    (state) => state.readymadePackage.PackageDetail
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <div className="cost-details mb-4">
        <Typography.h4>Cost Includes</Typography.h4>
        {PackageDetail?.Inclusion ? (
          <div
            dangerouslySetInnerHTML={{
              __html: PackageDetail?.Inclusion,
            }}
          />
        ) : (
          <AlertBox />
        )}
      </div>
      <div className="cost-details">
        <Typography.h4>Cost Excludes</Typography.h4>
        {PackageDetail?.Exclusion ? (
          <div
            dangerouslySetInnerHTML={{
              __html: PackageDetail?.Exclusion,
            }}
          />
        ) : (
          <AlertBox />
        )}
      </div>
    </>
  );
};

export default CostIncludes;
