import React, { CSSProperties } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Ripple } from "@progress/kendo-react-ripple";

type fillMode = "solid" | "outline" | "flat" | "link";
type Type = "submit" | "button" | "reset";
type themeColor =
  | "primary"
  | "base"
  | "secondary"
  | "tertiary"
  | "info"
  | "success"
  | "warning"
  | "error"
  | "dark"
  | "light"
  | "inverse";

interface IButtonProps {
  disabled?: boolean;
  color?: string;
  fillMode?: fillMode;
  type?: Type;
  width?: string;
  themeColor?: themeColor;
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  style?: CSSProperties | undefined;
}

const RippleButton: React.FC<IButtonProps> = ({
  disabled = false,
  fillMode = "solid",
  themeColor = "primary",
  type = "button",
  style,
  className = "",
  onClick,
  children,
}) => {
  return (
    <Ripple>
      <Button
        type={type}
        fillMode={fillMode}
        style={style}
        themeColor={themeColor}
        disabled={disabled}
        className={className}
        onClick={onClick}
      >
        {children}
      </Button>
    </Ripple>
  );
};

export default RippleButton;
