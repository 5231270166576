import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import ShadowCard from "../../components/common/ShadowCard";
import { getAllActiveCities } from "../city/services/city.services";
import { getAllActiveCountries } from "../country/services/country.services";
import { getAllActiveNationalities } from "../nationality/services/nationality.services";
import moment from "moment";
import { clearSightseenSearchDetails, setSightseenSearchDetails } from "./sightseenSearchSlice";
import { getAllSightseenSearchs } from "./services/sightseenSearch.services";
import FormDatePicker from "../../components/formFields/FormDateField";
import { getAllActiveVehicleTypes } from "../vehicleType/services/vehicleType.services";
import { IVehicleType } from "../vehicleType/vehicleTypeModel";
import { ICountry } from "../country/countryModel";
import { ICity } from "../city/cityModel";
import { INationality } from "../nationality/nationalityModel";
import { NOOFADULTS, NOOFCHILD } from "../../_contstants/common";

const SightseenSearchForm: React.FC = () => {
  const navigate = useNavigate();
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.sightseenSearch.loading);
  const CityList = useAppSelector((state) => state.city.CityList);
  const CountryList = useAppSelector((state) => state.country.CountryList);
  const SightseenSearchDetail = useAppSelector(
    (state) => state.sightseenSearch.SightseenSearchDetail
  );
  const VehicleTypeList = useAppSelector(
    (state) => state.vehicleType.VehicleTypeList
  );
  const NationalityList = useAppSelector(
    (state) => state.nationality.NationalityList
  );

  useEffect(() => {
    dispatch(getAllActiveCities());
    dispatch(getAllActiveCountries());
    dispatch(getAllActiveVehicleTypes());
    dispatch(getAllActiveNationalities());

    return () => {
      dispatch(clearSightseenSearchDetails());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    const payload = {
      VehicleTypeID: values.VehicleTypeID ? +values.VehicleTypeID : null,
      CountryID: values.CountryID ? +values.CountryID : null,
      CityID: values.CityID ? +values.CityID : null,
      NoOfAdult: values.NoOfAdult ? values.NoOfAdult : "0",
      NoOfChild: values.NoOfChild ? values.NoOfChild : "0",
      NationalityID: values.NationalityID ? +values.NationalityID : null,
      SightseenDate: values.SightseenDate
        ? moment(values.SightseenDate).toDate()
        : "",
    };
    dispatch(setSightseenSearchDetails(payload));

    const insertPayload = {
      VehicleTypeID: values.VehicleTypeID ? +values.VehicleTypeID : null,
      CountryID: values.CountryID ? +values.CountryID : null,
      CityID: values.CityID ? +values.CityID : null,
      NoOfAdult: values.NoOfAdult ? +values.NoOfAdult : 0,
      NoOfChild: values.NoOfChild ? +values.NoOfChild : 0,
      NationalityID: values.NationalityID ? +values.NationalityID : null,
      SightseenDate: values.SightseenDate
        ? moment(values.SightseenDate).format("YYYY-MM-DD")
        : "",
    };
    const response = await dispatch(getAllSightseenSearchs(insertPayload));
    if (response?.meta?.requestStatus === "fulfilled") {
      navigate("/sightseensearchlist", {
        state: payload,
      });
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={SightseenSearchDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>{"Sightseen Search"}</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`SightseenDate`}
                    label="Tour Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id={"VehicleTypeID"}
                    name={"VehicleTypeID"}
                    label={"Vehicle Type"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={VehicleTypeList?.map((vehicle: IVehicleType) => {
                      return {
                        value: vehicle?.ID,
                        label: vehicle?.VehicleType,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id={"NoOfAdult"}
                    name={"NoOfAdult"}
                    label={"Adult"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={NOOFADULTS.map((adult: any) => {
                      return {
                        value: adult.value,
                        label: adult.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id={"NoOfChild"}
                    name={"NoOfChild"}
                    label={"Child"}
                    component={FormSelectionField}
                    options={NOOFCHILD.map((child: any) => {
                      return {
                        value: child.value,
                        label: child.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id={"CityID"}
                    name={"CityID"}
                    label={"City"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={CityList?.map((city: ICity) => {
                      return {
                        value: city?.ID,
                        label: city?.CityName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id={"CountryID"}
                    name={"CountryID"}
                    label={"Country"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={CountryList?.map((country: ICountry) => {
                      return {
                        value: country?.ID,
                        label: country?.CountryName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id={"NationalityID"}
                    name={"NationalityID"}
                    label={"Nationality"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={NationalityList?.map(
                      (nationality: INationality) => {
                        return {
                          value: nationality?.ID,
                          label: nationality?.Nationality,
                        };
                      }
                    )}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ position: "relative" }}>
                  <ButtonWithLoading
                    style={{ position: "absolute", top: 34, right: 0 }}
                    label={"Search"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default SightseenSearchForm;
