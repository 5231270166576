import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CardBody,
  CardImage,
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import ShadowCard from "../../components/common/ShadowCard";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormRadioGroup from "../../components/formFields/FormRadioGroup";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { Typography } from "@progress/kendo-react-common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getAllTransferSearchs } from "../transfersearch/services/transferSearch.services";
import { getAllActiveSectors } from "../sector/services/sector.services";
import moment from "moment";
import { LoadingPanel } from "../../components/layout/Loading";
import CarImage from "../../assets/Images/car.png";
import { getTransferLocationBySectorID } from "../transferlocation/services/transferLocation.services";
import { ITransferLocation } from "../transferlocation/transferLocationModel";
import RippleButton from "../../components/common/RippleButton";
import { insertPlannerItem } from "../planner/plannerSlice";
import { clearTransferSearchList } from "../transfersearch/transferSearchSlice";

interface IOption {
  label: string;
  value: string | number;
}

export const TRANSFERTYPE: Array<IOption> = [
  { label: "One Way", value: 1 },
  { label: "Return", value: 2 },
  // { label: "Multi way", value: 3 },
];

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const SectorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const islocationRef = React.useRef(true);
  const SectorID = sessionStorage.getItem("SectorID");
  React.useEffect(() => {
    if (SectorID) {
      dispatch(getTransferLocationBySectorID(+SectorID));
    }
    if (!islocationRef.current) {
      formRenderProps.onChange("FromLocationID", {
        value: null,
      });
      formRenderProps.onChange("ToLocationID", {
        value: null,
      });
    } else {
      islocationRef.current = false;
    }
  }, [SectorID]);
  return null;
};

const TransferDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const gridRef = React.useRef<any>(null);
  const location = useLocation();
  const FromDate = sessionStorage.getItem("FromDate");
  const ToDate = sessionStorage.getItem("ToDate");
  const DayNo = location.state?.DayNo;
  const SectorID = sessionStorage.getItem("SectorID");
  const PassengerDetails = JSON.parse(
    sessionStorage.getItem("PassengerDetails") as any
  );
  console.log("PassengerDetails", PassengerDetails);

  const loading = useAppSelector((state) => state.toursAndTransfers.loading);
  const TransferDetail = useAppSelector(
    (state) => state.toursAndTransfers.TransferDetail
  );
  const TransferLocationList = useAppSelector(
    (state) => state.transferLocation.TransferLocationList
  );
  const TransferSearchList = useAppSelector(
    (state) => state.transferSearch.TransferSearchList
  );

  React.useEffect(() => {
    dispatch(getAllActiveSectors());

    return () => {
      dispatch(clearTransferSearchList());
    };
  }, []);

  const handleAddtoPlanner = () => {
    const selectedTransfer = TransferSearchList.map((e: any) => ({
      DayNo: DayNo,
      FromLocationID: e?.FromLocationID,
      FromLocationName: e?.FromLocation,
      ID: e?.ID,
      Rate: e?.Rate,
      ToLocationID: e?.ToLocationID,
      ToLocationName: e?.ToLocation,
      TransferType: e?.TripType,
    }));
    dispatch(
      insertPlannerItem({
        dataItem: selectedTransfer,
        arrayName: "TransferList",
        plannerRowID: DayNo,
      })
    );
    navigate("/planner");
  };

  const totalAdults = PassengerDetails.reduce(
    (acc: any, obj: any) => acc + (obj?.Adult || 0),
    0
  );
  const totalCNB = PassengerDetails.reduce(
    (acc: any, obj: any) => acc + (obj?.CNB || 0),
    0
  );
  const totalCWB = PassengerDetails.reduce(
    (acc: any, obj: any) => acc + (obj?.CWB || 0),
    0
  );
  const totalChild = +totalCNB + +totalCWB;

  const handleSubmit = async (values: any) => {
    const insertPayload = {
      SectorID: SectorID ? +SectorID : null,
      FromLocationID: values.FromLocationID ? +values.FromLocationID : null,
      ToLocationID: values.ToLocationID ? +values.ToLocationID : null,
      NoOfAdult: totalAdults ? +totalAdults : 0,
      NoOfChild: totalChild,
      TripType: values.TripType ? +values.TripType : null,
      FromDate: FromDate ? moment(FromDate).format("YYYY-MM-DD") : "",
      ToDate: ToDate ? moment(ToDate).format("YYYY-MM-DD") : "",
    };
    // console.log("insertPayload", insertPayload);
    await dispatch(getAllTransferSearchs(insertPayload));
  };

  return (
    <>
      <GridLayout gap={{ rows: 0, cols: 10 }} cols={[{ width: "100%" }]}>
        <GridLayoutItem>
          <ShadowCard>
            <GridLayout cols={[{ width: "100%" }]}>
              <GridLayoutItem>
                <Form
                  onSubmit={handleSubmit}
                  initialValues={TransferDetail}
                  render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{ width: "100%" }}>
                      <GridLayout
                        style={{ marginRight: 40 }}
                        gap={{ rows: 10, cols: 10 }}
                        cols={[
                          { width: "14%" },
                          { width: "14%" },
                          { width: "14%" },
                          { width: "24%" },
                          { width: "24%" },
                          { width: "10%" },
                        ]}
                      >
                        <GridLayoutItem
                          colSpan={3}
                          className="radiogroup-alighment"
                          style={{ display: "flex", alignItems: "end" }}
                        >
                          <Field
                            className="radiogroup"
                            id={"TripType"}
                            name={"TripType"}
                            // label={"Gender"}
                            layout={"horizontal"}
                            component={FormRadioGroup}
                            data={TRANSFERTYPE}
                          />
                        </GridLayoutItem>
                        <GridLayoutItem style={{ width: "100%" }}>
                          <Field
                            id={"FromLocationID"}
                            name={"FromLocationID"}
                            label={"From"}
                            component={FormSelectionField}
                            options={TransferLocationList.map(
                              (location: ITransferLocation) => {
                                return {
                                  value: location.ID,
                                  label: location.LocationName,
                                };
                              }
                            )}
                          />
                        </GridLayoutItem>
                        <GridLayoutItem style={{ width: "100%" }}>
                          <Field
                            id={"ToLocationID"}
                            name={"ToLocationID"}
                            label={"To"}
                            component={FormSelectionField}
                            options={TransferLocationList.map(
                              (location: ITransferLocation) => {
                                return {
                                  value: location.ID,
                                  label: location.LocationName,
                                };
                              }
                            )}
                          />
                        </GridLayoutItem>
                        <GridLayoutItem></GridLayoutItem>
                        <GridLayoutItem
                          colSpan={6}
                          style={{
                            textAlign: "center",
                            marginTop: 20,
                          }}
                        >
                          <ButtonWithLoading
                            label={"Search"}
                            type="submit"
                            disabled={!formRenderProps.allowSubmit || loading}
                            loading={loading}
                          />
                        </GridLayoutItem>
                      </GridLayout>
                      <SectorChangeWatcher formRenderProps={formRenderProps} />
                    </FormElement>
                  )}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
        </GridLayoutItem>
        {TransferSearchList && TransferSearchList.length > 0 && (
          <GridLayoutItem
            style={{
              marginTop: 20,
              display: "flex",
            }}
          >
            <Typography.h4>Transfer Details</Typography.h4>
          </GridLayoutItem>
        )}
        {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          <GridLayoutItem>
            {/* {data?.map((item: any, index: number) => { */}
            {TransferSearchList &&
              TransferSearchList.length > 0 &&
              TransferSearchList.map((transferSearch: any, index: number) => {
                return (
                  <div style={{ margin: "10px 0" }} key={index}>
                    <ShadowCard
                      orientation="horizontal"
                      style={{
                        padding: 15,
                      }}
                    >
                      <CardImage
                        src={
                          transferSearch?.TransferImage
                            ? transferSearch?.TransferImage
                            : CarImage
                        }
                        alt={"Vehicle Image"}
                        style={{
                          height: "130px",
                          width: "200px",
                          background: "whitesmoke",
                          borderRadius: "5px",
                        }}
                      />
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <CardBody
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "0 0 0 15px",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                            }}
                          >
                            <table
                              className="TrasnferCardTable"
                              cellSpacing={0}
                            >
                              <tr>
                                <th>Pickup</th>
                                <th>Drop</th>
                                <th>Vehicle</th>
                                <th>Seat</th>
                                <th>No of Pax</th>
                                <th>Cost</th>
                              </tr>
                              <tr>
                                <td>{transferSearch?.FromLocation}</td>
                                <td>{transferSearch?.ToLocation}</td>
                                <td>{transferSearch?.VehicleType}</td>
                                <td>{transferSearch?.SeatCount}</td>
                                <td>{transferSearch?.Pax}</td>
                                <td>{transferSearch?.Rate}</td>
                              </tr>
                            </table>
                          </div>
                        </CardBody>
                      </div>
                    </ShadowCard>
                  </div>
                );
              })}
          </GridLayoutItem>
        )}
        {TransferSearchList?.length > 0 && (
          <GridLayoutItem
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "end",
            }}
          >
            <div>
              <RippleButton
                style={{ marginTop: 5 }}
                onClick={() => handleAddtoPlanner()}
                disabled={TransferSearchList?.length < 1}
              >
                Add to Planner
              </RippleButton>
            </div>
          </GridLayoutItem>
        )}
      </GridLayout>
    </>
  );
};

export default TransferDetails;
