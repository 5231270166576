import React, { useEffect } from "react";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
  GridCellProps,
} from "@progress/kendo-react-grid";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { FaPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import moment from "moment";
import CreatePlannerQuotation from "./CreatePlannerQuotation";
import { Button } from "@progress/kendo-react-buttons";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import {
  clearPlannerDeleteDetails,
  deleteItemFromAllPlannerRow,
  deletePlannerItem,
  getPlannerData,
  setPlannerDeleteDetails,
} from "./plannerSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { getTourQuotationByID } from "./services/planner.services";

const Planner: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const TourQuotationID = location.state?.TourQuotationID;
  const PlannerList = useAppSelector((state) => state.planner.PlannerList);

  useEffect(() => {
    dispatch(getPlannerData());
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (TourQuotationID) {
        const response = await dispatch(getTourQuotationByID(TourQuotationID));
        if (response?.meta?.requestStatus === "fulfilled") {
          sessionStorage.setItem(
            "PlannerList",
            JSON.stringify(response.payload?.TourQuotationList)
          );
        } else {
          sessionStorage.removeItem("PlannerList");
        }
      }
    };

    fetchData().finally(() => dispatch(getPlannerData()));
  }, [TourQuotationID]);

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const HotelCell = (props: GridCellProps) => {
    const dispatch = useAppDispatch();
    const handleOpenDeleteDialog = (
      ID?: any,
      arrayName?: string,
      plannerRowID?: number
    ) => {
      dispatch(openDialog("deleteItem"));
      dispatch(
        setPlannerDeleteDetails({ itemID: ID, arrayName, plannerRowID })
      );
    };

    return (
      <td
        style={{
          textAlign: "center",
          padding: "30px 8px 25px 8px",
          position: "relative",
        }}
      >
        <FaPlus
          style={{
            height: 16,
            width: 16,
            position: "absolute",
            top: 10,
            right: 10,
            cursor: "pointer",
          }}
          onClick={() =>
            navigate("/customizesearch", {
              state: {
                isHotel: true,
                DayNo: props.dataItem?.DayNo,
              },
            })
          }
        />
        {props.dataItem?.HotelList?.map((hotel: any) => {
          return (
            <div
              style={{
                margin: "5px 0",
                position: "relative",
                paddingRight: 20,
              }}
            >
              <span style={{ paddingRight: 10 }}>{hotel.HotelName}</span>
              <MdDelete
                style={{
                  height: 18,
                  width: 18,
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleOpenDeleteDialog(hotel?.ID, "HotelList", hotel?.DayNo)
                }
              />
            </div>
          );
        })}
      </td>
    );
  };

  const TransferCell = (props: GridCellProps) => {
    const handleOpenDeleteDialog = (
      ID?: any,
      arrayName?: string,
      plannerRowID?: number
    ) => {
      dispatch(openDialog("deleteItem"));
      dispatch(
        setPlannerDeleteDetails({ itemID: ID, arrayName, plannerRowID })
      );
    };

    return (
      <td
        style={{
          textAlign: "center",
          padding: "30px 8px 25px 8px",
          position: "relative",
        }}
      >
        <FaPlus
          style={{
            height: 16,
            width: 16,
            position: "absolute",
            top: 10,
            right: 10,
            cursor: "pointer",
          }}
          onClick={() =>
            navigate("/toursandtransfers", {
              state: {
                selectedTab: 0,
                DayNo: props.dataItem?.DayNo,
              },
            })
          }
        />
        {props.dataItem?.TransferList?.map((transfer: any) => {
          return (
            <div
              style={{
                margin: "5px 0",
                position: "relative",
                paddingRight: 20,
              }}
            >
              <span style={{ paddingRight: 10 }}>
                {transfer?.FromLocationName} to {transfer?.ToLocationName}
              </span>
              <MdDelete
                style={{
                  height: 18,
                  width: 18,
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleOpenDeleteDialog(
                    transfer?.ID,
                    "TransferList",
                    transfer?.DayNo
                  )
                }
              />
            </div>
          );
        })}
      </td>
    );
  };

  const TourCell = (props: GridCellProps) => {
    const dispatch = useAppDispatch();
    const handleOpenDeleteDialog = (
      ID?: any,
      arrayName?: string,
      plannerRowID?: number
    ) => {
      dispatch(openDialog("deleteItem"));
      dispatch(
        setPlannerDeleteDetails({ itemID: ID, arrayName, plannerRowID })
      );
    };

    return (
      <td
        style={{
          textAlign: "center",
          padding: "30px 8px 25px 8px",
          position: "relative",
        }}
      >
        <FaPlus
          style={{
            height: 16,
            width: 16,
            position: "absolute",
            top: 10,
            right: 10,
            cursor: "pointer",
          }}
          onClick={() =>
            navigate("/toursandtransfers", {
              state: {
                selectedTab: 1,
                DayNo: props.dataItem?.DayNo,
              },
            })
          }
        />
        {props.dataItem?.SightseenList?.map((tour: any) => {
          return (
            <div
              style={{
                margin: "5px 0",
                position: "relative",
                paddingRight: 20,
              }}
            >
              <span style={{ paddingRight: 10 }}>{tour?.SightseenName}</span>
              <MdDelete
                style={{
                  height: 18,
                  width: 18,
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleOpenDeleteDialog(tour?.ID, "SightseenList", tour?.DayNo)
                }
              />
            </div>
          );
        })}
      </td>
    );
  };

  const MealCell = (props: GridCellProps) => {
    const dispatch = useAppDispatch();
    const handleOpenDeleteDialog = (
      ID?: any,
      arrayName?: string,
      plannerRowID?: number
    ) => {
      dispatch(openDialog("deleteItem"));
      dispatch(
        setPlannerDeleteDetails({ itemID: ID, arrayName, plannerRowID })
      );
    };

    return (
      <td
        style={{
          textAlign: "center",
          padding: "30px 8px 25px 8px",
          position: "relative",
        }}
      >
        <FaPlus
          style={{
            height: 16,
            width: 16,
            position: "absolute",
            top: 10,
            right: 10,
            cursor: "pointer",
          }}
          onClick={() =>
            navigate("/toursandtransfers", {
              state: {
                selectedTab: 2,
                DayNo: props.dataItem?.DayNo,
              },
            })
          }
        />
        {props.dataItem?.MealList?.map((meal: any) => {
          return (
            <div
              style={{
                margin: "5px 0",
                position: "relative",
                paddingRight: 20,
              }}
            >
              <span style={{ paddingRight: 10 }}>{meal?.MealName}</span>
              <MdDelete
                style={{
                  height: 18,
                  width: 18,
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleOpenDeleteDialog(meal?.ID, "MealList", meal?.DayNo)
                }
              />
            </div>
          );
        })}
      </td>
    );
  };
  return (
    <div>
      <CreatePlannerQuotation />
      <DeletePlannerDetailsDialog />
      <KendoGrid
        style={{ maxHeight: "calc(100vh - 152px)" }}
        data={PlannerList}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          field="Date"
          title="Date"
          cell={(props) => {
            return (
              <td>
                {props.dataItem.Date
                  ? moment(props.dataItem.Date).format("DD/MM/YYYY")
                  : ""}
              </td>
            );
          }}
        />
        <Column
          field="DayNo"
          title="Hotel"
          cell={HotelCell}
          filterable={false}
          locked={true}
        />
        <Column
          field="DayNo"
          title="Transfer"
          cell={TransferCell}
          filterable={false}
          locked={true}
        />
        <Column
          field="DayNo"
          title="Tour"
          cell={TourCell}
          filterable={false}
          locked={true}
        />
        <Column
          field="DayNo"
          title="Meal"
          cell={MealCell}
          filterable={false}
          locked={true}
        />
      </KendoGrid>
      <div
        className="mt-3"
        style={{ display: "flex", gap: 10, justifyContent: "end" }}
      >
        <Button
          themeColor={"primary"}
          fillMode={"solid"}
          onClick={() =>
            navigate("/confirmplanner", {
              state: {
                TourQuotationID: TourQuotationID,
              },
            })
          }
        >
          Proceed To Next
        </Button>
        <Button
          themeColor={"primary"}
          fillMode={"solid"}
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

const DeletePlannerDetailsDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const PlannerDeleteDetails = useAppSelector(
    (state) => state.planner.PlannerDeleteDetails
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const handleDeleteItem = (
    ID: number | null,
    arrayName: string,
    plannerRowID: number | null
  ) => {
    if (ID && arrayName === "HotelList") {
      dispatch(
        deleteItemFromAllPlannerRow({
          itemID: ID,
          arrayName,
        })
      );
      dispatch(closeDialog());
      dispatch(clearPlannerDeleteDetails());
      dispatch(getPlannerData());
    } else if (ID) {
      dispatch(
        deletePlannerItem({
          itemID: ID,
          arrayName,
          plannerRowID,
        })
      );
      dispatch(closeDialog());
      dispatch(clearPlannerDeleteDetails());
      dispatch(getPlannerData());
    }
  };

  return (
    <>
      {dialogName === "deleteItem" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Planner Item"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this item?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() =>
                    handleDeleteItem(
                      PlannerDeleteDetails?.itemID,
                      PlannerDeleteDetails?.arrayName,
                      PlannerDeleteDetails?.plannerRowID
                    )
                  }
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default Planner;
