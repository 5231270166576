import React, { useEffect } from "react";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { setHotelRateDeleteIndex } from "./hotelSlice";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormTextField from "../../components/formFields/FormTextField";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { useLocation } from "react-router-dom";
import moment from "moment";

const HotelRatesArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const location = useLocation();
  const HotelID = location.state?.HotelID;
  const RateDeleteIndex = useAppSelector(
    (state) => state.hotel.rateDeleteIndex
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(closeDialog());
    };
  }, []);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("hotelRateDeleteDialog"));
    dispatch(setHotelRateDeleteIndex(index));
  };

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setHotelRateDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        FromDate: "",
        ToDate: "",
        Rate: null,
      },
    });
  }, [fieldArrayRenderProps]);

  useEffect(() => {
    if (!HotelID) {
      pushElementInarray();
    }
  }, [HotelID]);

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      <GridLayoutItem style={{ marginBottom: 10 }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Rates
        </Button>
      </GridLayoutItem>
      {dialogName === "hotelRateDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Rate"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(RateDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((HotelRates: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card className="fieldarray-card">
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "30.66%" },
                  { width: "30.66%" },
                  { width: "30.66%" },
                  { width: "8%" },
                ]}
              >
                <GridLayoutItem>
                  <Field
                    name={`HotelRates.${index}.FromDate`}
                    label="From Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`HotelRates.${index}.ToDate`}
                    label="To Date"
                    format="dd/MM/yyyy"
                    minDate={moment(
                      fieldArrayRenderProps.formRenderProps.valueGetter(
                        `HotelRates.${index}.FromDate`
                      )
                    ).toDate()}
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`HotelRates.${index}.Rate`}
                    label="Rate"
                    type="number"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    textAlign: "right",
                    position: "relative",
                  }}
                >
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    themeColor={"error"}
                    size={"small"}
                    fillMode={"solid"}
                    type="button"
                    style={{
                      position: "absolute",
                      top: 38,
                      right: 0,
                      height: 24,
                      width: 24,
                    }}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </IconButton>
                </GridLayoutItem>
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
    </GridLayout>
  );
};

export default HotelRatesArray;
