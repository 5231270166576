import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import {
  ITermsAndCondition,
  ITermsAndConditionDetails,
} from "../termsAndConditionModel";

export const getAllTermsAndConditions = createAsyncThunk(
  "TermsAndConditions/FindAllTermsAndConditions",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TermsAndConditions/FindAllTermsAndConditions`,
        {
          TermsAndConditions: "",
        }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data as ITermsAndCondition[];
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Terms & Conditions:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createTermsAndConditions = createAsyncThunk(
  "TermsAndConditions/InsertTermsAndConditions",
  async (TermsAndConditionsData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TermsAndConditions/InsertTermsAndConditions`,
        TermsAndConditionsData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Terms And Conditions:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateTermsAndConditions = createAsyncThunk(
  "TermsAndConditions/UpdateTermsAndConditions",
  async (TermsAndConditionsData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TermsAndConditions/UpdateTermsAndConditions`,
        TermsAndConditionsData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Terms And Conditions:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteTermsAndConditions = createAsyncThunk(
  "TermsAndConditions/DeleteTermsAndConditions",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TermsAndConditions/DeleteTermsAndConditions`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Terms And Conditions:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTermsAndConditionsByID = createAsyncThunk(
  "TermsAndConditions/FindByIDTermsAndConditions",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TermsAndConditions/FindByIDTermsAndConditions`,
        { ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        TermsAndConditions: result.TermsAndConditions,
        Description: result.Description,
        IsActive: result.IsActive,
      } as ITermsAndConditionDetails;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Terms And Conditions:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveTermsAndConditions = createAsyncThunk(
  "TermsAndConditions/ActiveInActiveTermsAndConditions",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TermsAndConditions/ActiveInActiveTermsAndConditions`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Terms And Conditions:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveTermsAndConditions = createAsyncThunk(
  "TermsAndConditions/FindAllActiveTermsAndConditions",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/TermsAndConditions/FindAllActiveTermsAndConditions`
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching TermsAndConditions:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
