import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast } from "../../../components/toast/Toasts";

export const getAllActiveTourSightseen = createAsyncThunk(
  "Tour/FindAllActiveTourSightseen",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/FindAllActiveTourSightseen`,
        formData
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Tour Sightseens:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);


