import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  // GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
// import { FiEdit } from "react-icons/fi";
// import { Switch } from "@progress/kendo-react-inputs";
import AlertBox from "../../components/common/AlertBox";
import { LoadingPanel } from "../../components/layout/Loading";
// import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import {
  closeDialog,
  //  openDialog
} from "../../components/dialog/dialogSlice";
// import { MdDelete } from "react-icons/md";
import {
  clearTransferSearchID,
  // setTransferSearchID,
} from "./transferSearchSlice";
import {
  deleteQuotation,
  getAllTransferQuotationDetails,
} from "./services/transferSearch.services";

const QuotationList: React.FC = () => {
  return (
    <>
      <DeleteQuotationDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
            
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Quotation List
          </Typography.h4>
        </GridLayoutItem>
        <GridLayoutItem>
          <QuotationGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const QuotationGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();

  const QuotationDetailsList = useAppSelector(
    (state: RootState) => state.transferSearch.QuotationDetailsList
  );
  const loading = useAppSelector(
    (state: RootState) => state.transferSearch.loading
  );

  useEffect(() => {
    dispatch(getAllTransferQuotationDetails());
  }, []);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? QuotationDetailsList.length
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  // const MyEditCommandCell = (props: GridCellProps) => {
  //   //   const handleEdit = (ID: number) => {
  //   //     dispatch(openDialog("cityForm"));
  //   //     dispatch(getCityByID(ID));
  //   //     dispatch(setTransferSearchID(ID));
  //   //   };
  //   const handleOpenDeleteDialog = (ID: number) => {
  //     dispatch(openDialog("deleteQuotation"));
  //     dispatch(setTransferSearchID(ID));
  //   };

  //   return (
  //     <td
  //       style={{
  //         ...props.style,
  //         textAlign: "center",
  //         display: "flex",
  //         justifyContent: "space-evenly",
  //       }}
  //     >
  //       <IconButton
  //         type="button"
  //         fillMode="flat"
  //         size="small"
  //         title="Edit"
  //         // onClick={() => handleEdit(props.dataItem.ID)}
  //       >
  //         <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
  //       </IconButton>
  //       <IconButton
  //         type="button"
  //         fillMode="flat"
  //         size="small"
  //         title="Delete"
  //         onClick={() => handleOpenDeleteDialog(props.dataItem.ID)}
  //       >
  //         <MdDelete
  //           className="absolute-position"
  //           style={{ fontSize: "24px" }}
  //         />
  //       </IconButton>
  //     </td>
  //   );
  // };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {QuotationDetailsList && QuotationDetailsList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(QuotationDetailsList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : QuotationDetailsList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={QuotationDetailsList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column field="ID" title="Quotation No" />
          <Column field="QuotationDate" title="Quotation Date" />
          <Column
            field="FirstName"
            title="Customer Name"
            cell={(props) => (
              <td>
                <div>
                  {props?.dataItem?.Initial} {props?.dataItem?.FirstName}{" "}
                  {props?.dataItem?.LastName}
                </div>
              </td>
            )}
          />
          <Column field="MobileNo" title="Mobile" />
          <Column field="GrandTotal" title="Total Cost" />
          {/* <Column
            field="ID"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
          /> */}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteQuotationDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const TransferSearchID = useAppSelector(
    (state: RootState) => state.transferSearch.TransferSearchID
  );
  const dialogName = useAppSelector(
    (state: RootState) => state.dialog.dialogName
  );

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      // API path change is yet to done
      const response = await dispatch(deleteQuotation(ID));
      if (response.payload.Data.Status === 200) {
        dispatch(getAllTransferQuotationDetails());
        dispatch(closeDialog());
        dispatch(clearTransferSearchID());
      } else {
        dispatch(getAllTransferQuotationDetails());
        dispatch(closeDialog());
        dispatch(clearTransferSearchID());
      }
    }
  };
  return (
    <>
      {dialogName === "deleteQuotation" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Quotation?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Quotation?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(TransferSearchID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default QuotationList;
