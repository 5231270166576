import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import Card from "antd/es/card/Card";
import React, { useEffect } from "react";
import RippleButton from "../../components/common/RippleButton";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import {
  getAllReadymadeTourPackages,
  getTourPackageDetails,
} from "./services/readymadePackage.services";
import { LoadingPanel } from "../../components/layout/Loading";
import { ISector } from "../sector/sectorModel";
import { getAllActiveSectors } from "../sector/services/sector.services";
import { setReadymadePackageDetail } from "./readymadePackageSlice";

// const data = [
//   {
//     img: "https://uandiholidays.net/Admin/UploadFiles/Packagedetails/strDPSXRex.282864.png",
//     title: "Kuta Package",
//     price: "USD 194",
//     type: "Regular",
//     nights: "4N",
//     days: "5D",
//   },
//   {
//     img: "https://uandiholidays.net/Admin/UploadFiles/Packagedetails/Westin1.png",
//     title: "Nusa Dua Package",
//     price: "USD 343",
//     type: "Regular",
//     nights: "2N",
//     days: "3D",
//   },
// ];

export const NOOFNIGHTS = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
];

const ReadymadePackage: React.FC = () => {
  const navigate = useNavigate();
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const SectorList = useAppSelector((state) => state.sector.SectorList);
  const ReadymadePackageList = useAppSelector(
    (state) => state.readymadePackage.ReadymadePackageList
  );
  const ReadymadePackageDetails = useAppSelector(
    (state) => state.readymadePackage.ReadymadePackageDetails
  );
  const loading = useAppSelector((state) => state.readymadePackage.loading);

  useEffect(() => {
    dispatch(getAllActiveSectors());
  }, []);

  const handleSubmit = async (values: any) => {
    const payload = {
      NoOfNights: values?.NoOfNights || 0,
      SectorID: values?.SectorID || 0,
    };
    await dispatch(getAllReadymadeTourPackages(payload));
    dispatch(setReadymadePackageDetail(payload));
  };

  const viewPackageDetails = async (values: any) => {
    if (values?.ID) {
      const response = await dispatch(getTourPackageDetails(values?.ID));
      if (response.meta?.requestStatus === "fulfilled") {
        navigate("/package", {
          state: {
            TourID: values?.ID,
            NoOfNights: values?.NoOfNights,
          },
        });
        sessionStorage.setItem("SectorID", values?.SectorID);
      }
    }
  };

  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Readymade Packages
          </Typography.h4>
        </GridLayoutItem>
        <GridLayoutItem>
          <Form
            onSubmit={handleSubmit}
            initialValues={ReadymadePackageDetails}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <div
                  style={{
                    background: "lightgray",
                    padding: "0 20px 20px 20px",
                    marginBottom: 30,
                  }}
                >
                  <GridLayout
                    style={{
                      marginRight: 40,
                      // padding: 20,
                    }}
                    gap={{ rows: 10, cols: 10 }}
                    cols={[
                      { width: "10%" },
                      { width: "30%" },
                      { width: "30%" },
                      { width: "20%" },
                      { width: "10%" },
                    ]}
                  >
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        id={"SectorID"}
                        name={"SectorID"}
                        label={"Sector"}
                        component={FormSelectionField}
                        options={SectorList?.map((sector: ISector) => {
                          return {
                            value: sector?.ID,
                            label: sector?.SectorName,
                          };
                        })}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        name={"NoOfNights"}
                        label={"Nights"}
                        component={FormSelectionField}
                        options={NOOFNIGHTS?.map((night: any) => {
                          return {
                            value: night?.value,
                            label: night?.label,
                          };
                        })}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "end",
                      }}
                    >
                      {/* <div> */}
                      <ButtonWithLoading
                        label="Search"
                        type="submit"
                        disabled={!formRenderProps.allowSubmit}
                        loading={loading}
                      />
                      {/* </div> */}
                    </GridLayoutItem>
                  </GridLayout>
                </div>
              </FormElement>
            )}
          />
        </GridLayoutItem>
        <GridLayoutItem
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          {/* <ReadymadeCardComponent /> */}
          <>
            {loading ? (
              <LoadingPanel gridRef={gridRef} />
            ) : (
              ReadymadePackageList &&
              ReadymadePackageList?.length > 0 &&
              ReadymadePackageList?.map((item: any) => {
                return (
                  <div style={{ padding: 10 }}>
                    <Card
                      hoverable
                      style={{
                        width: "270px",
                        textAlign: "center",
                      }}
                      cover={
                        <img
                          height={"150px"}
                          width={"100%"}
                          src={
                            item?.TourImage
                              ? item?.TourImage
                              : "https://igpa.uillinois.edu/wp-content/uploads/2023/11/no-image.png"
                          }
                          alt="No Image"
                        />
                      }
                    >
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        <span
                          style={{
                            padding: 4,
                            fontWeight: "600",
                            position: "absolute",
                            top: -64,
                            right: -25,
                            borderRadius: "5px 0 0 5px",
                            background: "rgb(255 255 255 / 80%)",
                          }}
                        >
                          {item?.NoOfNights}N / {item?.NoOfDays}D
                        </span>
                        <Typography.h5>{item?.TourName}</Typography.h5>
                        <div className="d-flex justify-content-between">
                          <div className="mb-2 mt-2">
                            <span style={{ fontWeight: 600 }}>
                              INR{" "}
                              {new Intl.NumberFormat("en-IN").format(
                                item?.PPRate
                              )}{" "}
                            </span>
                            <span style={{ fontSize: "10px" }}>Onwards</span>
                          </div>
                          <RippleButton
                            // className="viewdetails-button"
                            style={{
                              marginTop: 5,
                              boxShadow:
                                "2px 2px 5px gray, inset 2px 2px 4px orange",
                            }}
                            onClick={() => viewPackageDetails(item)}
                          >
                            View Details
                          </RippleButton>
                        </div>
                      </div>
                    </Card>
                  </div>
                );
              })
            )}
            {/* {} */}
          </>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default ReadymadePackage;
