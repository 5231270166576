import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormImageUpload from "../../components/formFields/FormImageUpload";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import FormCheckbox from "../../components/formFields/FormCheckbox";
import { createUser, getUserByID, updateUser } from "./services/user.services";
import { clearUserDetails } from "./userSlice";
import ShadowCard from "../../components/common/ShadowCard";

const CreateUser: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const UserID = location.state?.UserID;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.user.loading);
  const UserDetails = useAppSelector((state) => state.user.UserDetails);

  useEffect(() => {
    if (UserID) {
      dispatch(getUserByID(UserID));
    }
    return () => {
      dispatch(clearUserDetails());
    };
  }, [UserID]);

  const handleSubmit = async (values: any) => {
    if (UserID) {
      const editPayload = {
        ID: values.ID ? values.ID : null,
        FirstName: values.FirstName ? values.FirstName : "",
        LastName: values.LastName ? values.LastName : "",
        MobileNo: values.MobileNo ? values.MobileNo?.toString() : "",
        AlternateMobileNo: values.AlternateMobileNo
          ? values.AlternateMobileNo?.toString()
          : "",
        EmailID: values.EmailID ? values.EmailID : "",
        AlternateEmailID: values.AlternateEmailID
          ? values.AlternateEmailID
          : "",
        Password: values.Password ? values.Password : "",
        IsActive: values.IsActive ? values.IsActive : true,
        IsNationality: values.IsNationality ? values.IsNationality : false,
        IsCountry: values.IsCountry ? values.IsCountry : false,
        IsVehicleType: values.IsVehicleType ? values.IsVehicleType : false,
        IsState: values.IsState ? values.IsState : false,
        IsTermsAndConditions: values.IsTermsAndConditions
          ? values.IsTermsAndConditions
          : false,
        UserTypeID: 1,
      };
      const response = await dispatch(updateUser(editPayload));
      if (response?.meta?.requestStatus === "fulfilled") {
        navigate("/user");
      }
    } else {
      const insertPayload = {
        FirstName: values.FirstName ? values.FirstName : "",
        LastName: values.LastName ? values.LastName : "",
        MobileNo: values.MobileNo ? values.MobileNo?.toString() : "",
        AlternateMobileNo: values.AlternateMobileNo
          ? values.AlternateMobileNo?.toString()
          : "",
        EmailID: values.EmailID ? values.EmailID : "",
        AlternateEmailID: values.AlternateEmailID
          ? values.AlternateEmailID
          : "",
        Password: values.Password ? values.Password : "",
        IsActive: values.IsActive ? values.IsActive : true,
        IsNationality: values.IsNationality ? values.IsNationality : false,
        IsCountry: values.IsCountry ? values.IsCountry : false,
        IsVehicleType: values.IsVehicleType ? values.IsVehicleType : false,
        IsState: values.IsState ? values.IsState : false,
        IsTermsAndConditions: values.IsTermsAndConditions
          ? values.IsTermsAndConditions
          : false,
        UserTypeID: 1,
      };
      const response = await dispatch(createUser(insertPayload));
      if (response?.meta?.requestStatus === "fulfilled") {
        navigate("/user");
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={UserDetails}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {UserID ? "Update User" : "Create User"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="FirstName"
                    maxLength="100"
                    label="First Name"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="LastName"
                    maxLength="100"
                    label="Last Name"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="MobileNo"
                    type="number"
                    max="16"
                    label="Mobile No"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="AlternateMobileNo"
                    type="number"
                    max="16"
                    label="Alternate Mobile No "
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="EmailID"
                    type="text"
                    label="Email"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="AlternateEmailID"
                    type="text"
                    label="Alternate Email"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="UserImage"
                    type="file"
                    accept="image/*"
                    label="User Image"
                    component={FormImageUpload}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ marginTop: 8, padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "20%" },
                  { width: "20%" },
                  { width: "20%" },
                  { width: "20%" },
                  { width: "20%" },
                ]}
              >
                <GridLayoutItem>
                  <Field
                    name="IsNationality"
                    maxLength="100"
                    label="Nationality"
                    component={FormCheckbox}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="IsCountry"
                    maxLength="100"
                    label="Country"
                    component={FormCheckbox}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="IsVehicleType"
                    maxLength="100"
                    label="Vehicle Type"
                    component={FormCheckbox}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="IsState"
                    maxLength="100"
                    label="State"
                    component={FormCheckbox}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="IsTermsAndConditions"
                    maxLength="100"
                    label="Terms & Conditions"
                    component={FormCheckbox}
                  />
                </GridLayoutItem>
              </GridLayout>
              <GridLayoutItem
                colSpan={3}
                style={{
                  textAlign: "end",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <Button
                  type="button"
                  fillMode={"outline"}
                  themeColor={"primary"}
                  style={{ marginRight: 4 }}
                  onClick={() => navigate("/user")}
                >
                  Cancel
                </Button>
                <ButtonWithLoading
                  label={UserID ? "Update" : "Create"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || loading}
                  loading={loading}
                />
              </GridLayoutItem>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateUser;
