import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IVehicleSharingPax,
  IVehicleSharingPaxState,
} from "./vehicleSharingPaxModel";
import { getAllActiveVehicleSharingPaxs } from "./services/vehicleSharingPax.services";

const initialState: IVehicleSharingPaxState = {
  loading: false,
  error: "",
  VehicleSharingPaxList: [],
};

const vehicleSharingPaxSlice = createSlice({
  name: "vehicleSharingPax",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllActiveVehicleSharingPaxs.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveVehicleSharingPaxs.fulfilled,
      (state, action: PayloadAction<IVehicleSharingPax[]>) => {
        state.loading = false;
        state.VehicleSharingPaxList = action.payload || [];
      }
    );
    builder.addCase(
      getAllActiveVehicleSharingPaxs.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.VehicleSharingPaxList = [];
      }
    );
  },
});

export default vehicleSharingPaxSlice.reducer;
