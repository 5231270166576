import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { clearStateDetails } from "./stateSlice";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import {
  createState,
  updateState,
  getAllStates,
  getStateByID,
} from "./services/state.services";
import { getAllActiveCountries } from "../country/services/country.services";
import { closed } from "../../components/drawer/drawerSlice";

const CreateState: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const CountryList = useAppSelector((state) => state.country.CountryList);
  const StateDetail = useAppSelector((state) => state.state.StateDetail);
  const StateID = useAppSelector((state) => state.drawer.data);
  const formLoading = useAppSelector((state) => state.state.formLoading);

  useEffect(() => {
    dispatch(getAllActiveCountries());
    return () => {
      dispatch(clearStateDetails());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (StateID) {
      dispatch(getStateByID(StateID));
    }
  }, [StateID]);

  const handleSubmit = async (values: any) => {
    try {
      if (!StateID) {
        const insertPayload = {
          StateName: values.StateName,
          CountryID: values.CountryID,
          IsActive: true,
        };
        const response = await dispatch(createState(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearStateDetails());
          dispatch(getAllStates());
        }
      } else {
        const editPayload = {
          ID: StateID,
          StateName: values.StateName,
          CountryID: values.CountryID,
          IsActive: values.IsActive,
        };
        const response = await dispatch(updateState(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearStateDetails());
          dispatch(getAllStates());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={StateDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="StateName"
                label="State"
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"CountryID"}
                name={"CountryID"}
                label={"Country"}
                component={FormSelectionField}
                validator={requiredValidator}
                options={CountryList.map((country: any) => {
                  return {
                    value: country.ID,
                    label: country.CountryName,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "end",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginRight: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
              <ButtonWithLoading
                label="Save"
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateState;
