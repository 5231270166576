import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import {
  ISightseenGuestDetails,
  ISightseenSearch,
} from "../sightseenSearchModel";

export const getAllSightseenSearchs = createAsyncThunk(
  "Sightseen/SightseenSearch",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sightseen/SightseenSearch`,
        formData
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data as ISightseenSearch[];
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Sightseen Searchs:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const AddToCartSightseenSearch = createAsyncThunk(
  "Sightseen/SightseenAddToCart",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sightseen/SightseenAddToCart`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error adding to cart:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllSightseenCartDetails = createAsyncThunk(
  "Sightseen/SightseenCartDetails",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Sightseen/SightseenCartDetails`
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Sightseen Cart Details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteSightseenCart = createAsyncThunk(
  "Sightseen/SightseenRemoveFromCart",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sightseen/SightseenRemoveFromCart`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Sightseen Cart:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const AddSightseenQuotation = createAsyncThunk(
  "Sightseen/SightseenAddQuotation",
  async (GuestData: ISightseenGuestDetails) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sightseen/SightseenAddQuotation`,
        GuestData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error adding Sightseen quotation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllSightseenQuotationDetails = createAsyncThunk(
  "Sightseen/QuotationDetailFindAll",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Sightseen/QuotationDetailFindAll`
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Quotation Details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
