import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITour, ITourInitialState } from "./tourModel";
import {
  ActiveInactiveTour,
  createTour,
  createTourDateArray,
  createTourItineraryArray,
  createTourPlaceArray,
  deleteTour,
  deleteTourMeal,
  deleteTourRate,
  deleteTourSightseen,
  deleteTourTransfer,
  getAllActiveTours,
  getAllTours,
  getTourByID,
  getTourDatesByTourID,
  getTourItineraryListByTourID,
  getTourMealsByID,
  getTourPlacesByTourID,
  getTourRatesByTourID,
  getTourSightseensByTourID,
  getTourTransfersByTourID,
  insertTourFixedTransfer,
  insertTourMeal,
  insertTourRate,
  insertTourSightseen,
  updateTour,
  updateTourFixedTransfer,
  updateTourMeal,
  updateTourRate,
  updateTourSightseen,
  uploadTourImage,
} from "./services/tour.services";

const initialState: ITourInitialState = {
  loading: false,
  error: "",
  TourList: [],
  TourCostList: [],
  TourTransferList: [],
  TourSightseenList: [],
  TourMealList: [],
  TourID: null,
  TourCostID: null,
  TourTransferID: null,
  TourSightseenID: null,
  TourMealID: null,
  ActiveStep: 0,
  TourDateDetails: [],
  TourPlaceDetails: [],
  TourItineraryList: [],
  TourCostDetails: {
    ID: null,
    TourID: null,
    StartDate: "",
    EndDate: "",
    CurrencyID: null,
    PPRate: null,
    CWBRate: null,
    CNBRate: null,
    PackageID: null,
  },
  TourTransferDetails: {
    ID: null,
    TourID: null,
    DayNo: null,
    TransferType: null,
    FromLocationID: null,
    ToLocationID: null,
  },
  TourSightseenDetails: {
    ID: null,
    TourID: null,
    DayNo: null,
    CityID: null,
    SightseenTiming: null,
    SightseenID: null,
  },
  TourMealDetails: {
    ID: null,
    TourID: null,
    DayNo: null,
    LunchORDinner: null,
    MealTypeID: null,
    MealID: null,
  },
  deleteIndex: -1,
  TourDetail: {
    SectorType: "",
    SectorID: null,
    SubSectorID: null,
    GSTID: "",
    TravelType: "",
    TourName: "",
    Inclusion: "",
    Exclusion: "",
    OtherInformation: "",
    SpecialityTypeID: [],
    TourCode: "",
    CompanyID: null,
    NoOfNights: null,
    NoOfDays: null,
    SpecialRemarks: "",
    PickupPlace: "",
    PickupTime: "",
    PickupRemarks: "",
    DropPlace: "",
    DropTime: "",
    DropRemarks: "",
    PickupFlightPlace: "",
    DropFlightPlace: "",
    IsActive: 1,
  },
};

const tourSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    clearTourInformation: (state) => {
      state.TourDetail = initialState.TourDetail;
    },
    clearTourDateDetails: (state) => {
      state.TourDateDetails = initialState.TourDateDetails;
    },
    clearTourPlaceDetails: (state) => {
      state.TourPlaceDetails = initialState.TourPlaceDetails;
    },
    setTourID: (state, action) => {
      state.TourID = action.payload;
    },
    clearTourID: (state) => {
      state.TourID = initialState.TourID;
    },
    setTourCostID: (state, action) => {
      state.TourCostID = action.payload;
    },
    clearTourCostID: (state) => {
      state.TourCostID = initialState.TourCostID;
    },
    setTourCostDetails: (state, action) => {
      state.TourCostDetails = action.payload;
    },
    clearTourCostDetails: (state) => {
      state.TourCostDetails = initialState.TourCostDetails;
    },
    setTourTransferID: (state, action) => {
      state.TourTransferID = action.payload;
    },
    clearTourTransferID: (state) => {
      state.TourTransferID = initialState.TourTransferID;
    },
    setTourTransferDetails: (state, action) => {
      state.TourTransferDetails = action.payload;
    },
    clearTourTransferDetails: (state) => {
      state.TourTransferDetails = initialState.TourTransferDetails;
    },
    setTourSightseenID: (state, action) => {
      state.TourSightseenID = action.payload;
    },
    clearTourSightseenID: (state) => {
      state.TourSightseenID = initialState.TourSightseenID;
    },
    setTourSightseenDetails: (state, action) => {
      state.TourSightseenDetails = action.payload;
    },
    clearTourSightseenDetails: (state) => {
      state.TourSightseenDetails = initialState.TourSightseenDetails;
    },
    setTourMealID: (state, action) => {
      state.TourMealID = action.payload;
    },
    clearTourMealID: (state) => {
      state.TourMealID = initialState.TourMealID;
    },
    setTourMealDetails: (state, action) => {
      state.TourMealDetails = action.payload;
    },
    clearTourMealDetails: (state) => {
      state.TourMealDetails = initialState.TourMealDetails;
    },

    setActiveStep: (state, action) => {
      state.ActiveStep = action.payload;
    },
    clearActiveStep: (state) => {
      state.ActiveStep = initialState.ActiveStep;
    },
    increaseActiveStep: (state) => {
      state.ActiveStep = state.ActiveStep + 1;
    },
    decreaseActiveStep: (state) => {
      state.ActiveStep = state.ActiveStep - 1;
    },
    setDeleteIndex: (state, action) => {
      state.deleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTours.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllTours.fulfilled,
      (state, action: PayloadAction<ITour[]>) => {
        state.loading = false;
        state.TourList = action.payload || [];
      }
    );
    builder.addCase(getAllTours.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TourList = [];
    });
    builder.addCase(createTour.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createTour.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createTour.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(uploadTourImage.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(uploadTourImage.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(uploadTourImage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateTour.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateTour.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateTour.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteTour.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteTour.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTour.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTourByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getTourByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.TourDetail = action.payload;
    });
    builder.addCase(getTourByID.rejected, (state, action) => {
      state.loading = false;
      state.TourDetail = initialState.TourDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTourDatesByTourID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getTourDatesByTourID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.TourDateDetails = action.payload;
    });
    builder.addCase(getTourDatesByTourID.rejected, (state, action) => {
      state.loading = false;
      state.TourDateDetails = initialState.TourDateDetails;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTourPlacesByTourID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getTourPlacesByTourID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.TourPlaceDetails = action.payload;
    });
    builder.addCase(getTourPlacesByTourID.rejected, (state, action) => {
      state.loading = false;
      state.TourPlaceDetails = initialState.TourPlaceDetails;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTourItineraryListByTourID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getTourItineraryListByTourID.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.TourItineraryList = action.payload;
      }
    );
    builder.addCase(getTourItineraryListByTourID.rejected, (state, action) => {
      state.loading = false;
      state.TourItineraryList = initialState.TourItineraryList;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveTours.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveTours.fulfilled,
      (state, action: PayloadAction<ITour[]>) => {
        state.loading = false;
        state.TourList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveTours.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TourList = [];
    });
    builder.addCase(ActiveInactiveTour.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveTour.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveTour.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTourRatesByTourID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getTourRatesByTourID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.TourCostList = action.payload;
    });
    builder.addCase(getTourRatesByTourID.rejected, (state, action) => {
      state.loading = false;
      state.TourCostList = initialState.TourCostList;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTourTransfersByTourID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getTourTransfersByTourID.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.TourTransferList = action.payload;
      }
    );
    builder.addCase(getTourTransfersByTourID.rejected, (state, action) => {
      state.loading = false;
      state.TourTransferList = initialState.TourTransferList;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTourSightseensByTourID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getTourSightseensByTourID.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.TourSightseenList = action.payload;
      }
    );
    builder.addCase(getTourSightseensByTourID.rejected, (state, action) => {
      state.loading = false;
      state.TourSightseenList = initialState.TourSightseenList;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTourMealsByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getTourMealsByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.TourMealList = action.payload;
    });
    builder.addCase(getTourMealsByID.rejected, (state, action) => {
      state.loading = false;
      state.TourMealList = initialState.TourMealList;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(insertTourRate.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(insertTourRate.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(insertTourRate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(insertTourFixedTransfer.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(insertTourFixedTransfer.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(insertTourFixedTransfer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(insertTourSightseen.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(insertTourSightseen.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(insertTourSightseen.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(insertTourMeal.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(insertTourMeal.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(insertTourMeal.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(createTourItineraryArray.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createTourItineraryArray.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createTourItineraryArray.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(createTourPlaceArray.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createTourPlaceArray.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createTourPlaceArray.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(createTourDateArray.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createTourDateArray.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createTourDateArray.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateTourRate.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateTourRate.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateTourRate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateTourFixedTransfer.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateTourFixedTransfer.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateTourFixedTransfer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateTourSightseen.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateTourSightseen.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateTourSightseen.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateTourMeal.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateTourMeal.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateTourMeal.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteTourRate.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteTourRate.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTourRate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteTourTransfer.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteTourTransfer.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTourTransfer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteTourSightseen.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteTourSightseen.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTourSightseen.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteTourMeal.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteTourMeal.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTourMeal.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearTourInformation,
  clearTourDateDetails,
  clearTourPlaceDetails,
  clearTourID,
  setTourID,
  setTourCostID,
  clearTourCostID,
  setTourCostDetails,
  clearTourCostDetails,
  setTourTransferID,
  clearTourTransferID,
  setTourTransferDetails,
  clearTourTransferDetails,
  setTourSightseenID,
  clearTourSightseenID,
  setTourSightseenDetails,
  clearTourSightseenDetails,
  setTourMealID,
  clearTourMealID,
  setTourMealDetails,
  clearTourMealDetails,
  setDeleteIndex,
  setActiveStep,
  clearActiveStep,
  increaseActiveStep,
  decreaseActiveStep,
} = tourSlice.actions;
export default tourSlice.reducer;
