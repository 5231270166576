import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMealType, IMealTypeState } from "./mealtypeModel";
import {
  createMealType,
  deleteMealType,
  getAllActiveMealTypes,
  getAllMealType,
  getMealTypeByID,
  mealTypeActiveInactive,
  updateMealType,
} from "./services/mealtype.services";

const initialState: IMealTypeState = {
  loading: false,
  error: "",
  MealTypeList: [],
  MealTypeID: null,
  MealTypeDetail: {
    MealType: "",
    IsActive: false,
  },
};

const courseSlice = createSlice({
  name: "mealType",
  initialState,
  reducers: {
    clearMealTypeDetails: (state) => {
      state.MealTypeDetail = initialState.MealTypeDetail;
    },
    setMealTypeID: (state, action) => {
      state.MealTypeID = action.payload;
    },
    clearMealTypeID: (state) => {
      state.MealTypeID = initialState.MealTypeID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllMealType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllMealType.fulfilled,
      (state, action: PayloadAction<IMealType[]>) => {
        state.loading = false;
        state.MealTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllMealType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.MealTypeList = [];
    });
    builder.addCase(getAllActiveMealTypes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveMealTypes.fulfilled,
      (state, action: PayloadAction<IMealType[]>) => {
        state.loading = false;
        state.MealTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveMealTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.MealTypeList = [];
    });
    builder.addCase(createMealType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createMealType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createMealType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateMealType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateMealType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateMealType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteMealType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteMealType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteMealType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(mealTypeActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(mealTypeActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(mealTypeActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getMealTypeByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getMealTypeByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.MealTypeDetail = action.payload;
    });
    builder.addCase(getMealTypeByID.rejected, (state, action) => {
      state.loading = false;
      state.MealTypeDetail = initialState.MealTypeDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearMealTypeID,
  setMealTypeID,
  clearMealTypeDetails,
} = courseSlice.actions;
export default courseSlice.reducer;
