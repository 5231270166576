import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IMealCategory } from "../mealCategoryModel";

export const getAllMealCategories = createAsyncThunk(
  "MealCategory/FindAllMealCategory",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MealCategory/FindAllMealCategory`,
        {
          Title: "",
        }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Meal Categories:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveMealCategories = createAsyncThunk(
  "MealCategory/FindAllActiveMealCategory",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/MealCategory/FindAllActiveMealCategory`
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Meal Categories:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createMealCategory = createAsyncThunk(
  "MealCategory/InsertMealCategory",
  async (MealCategoryData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MealCategory/InsertMealCategory`,
        MealCategoryData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating meal category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateMealCategory = createAsyncThunk(
  "MealCategory/UpdateMealCategory",
  async (MealCategoryData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MealCategory/UpdateMealCategory`,
        MealCategoryData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating meal category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteMealCategory = createAsyncThunk(
  "MealCategory/DeleteMealCategory",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MealCategory/DeleteMealCategory`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting meal Category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getMealCategoryByID = createAsyncThunk(
  "MealCategory/FindByIDMealCategory",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MealCategory/FindByIDMealCategory`,
        { ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        Title: result.Title,
        Type: result.Type ? +result.Type : null,
        IsActive: result.IsActive,
      } as IMealCategory;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching meal category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveMealCategory = createAsyncThunk(
  "MealCategory/ActiveInActiveMealCategory",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MealCategory/ActiveInActiveMealCategory`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive meal category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
