import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Error, Hint } from "@progress/kendo-react-labels";

const FormCheckboxGroup = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    id,
    valid,
    disabled,
    hint,
    options,
    wrapperClassName,
    value = [],
    onChange,
  } = fieldRenderProps;

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;

  const hindId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";

  const handleCheckboxChange = (selectedValue: any) => {
    const updatedValues = value.includes(selectedValue)
      ? value.filter((val: any) => val !== selectedValue)
      : [...value, selectedValue];

    onChange({ value: updatedValues });
  };

  return (
    <FieldWrapper className={wrapperClassName}>
      <div className={"k-form-field-wrap"}>
        {options.map((option: any) => (
          <div
            key={option.value}
            style={{ display: "inline-block", marginRight: 25 }}
          >
            <Checkbox
              ariaDescribedBy={`${hindId} ${errorId}`}
              label={option.label}
              valid={valid}
              id={`${id}_${option.value}`}
              disabled={disabled}
              checked={value.includes(option.value)}
              onChange={() => handleCheckboxChange(option.value)}
            />
          </div>
        ))}
        {showHint && <Hint id={hindId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};

export default FormCheckboxGroup;
