import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { LoadingPanel } from "../../components/layout/Loading";
import { IoInformationCircleSharp } from "react-icons/io5";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import AlertBox from "../../components/common/AlertBox";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import {
  deleteSightseenCart,
  getAllSightseenCartDetails,
} from "./services/sightseenSearch.services";
import {
  clearSightseenSearchID,
  setSightseenSearchID,
} from "./sightseenSearchSlice";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import RippleButton from "../../components/common/RippleButton";

const SightseenCart: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllSightseenCartDetails());
  }, []);
  return (
    <>
      <DeleteSightseenCartDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
            
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Your Cart
          </Typography.h4>
          <div>
            <Button
              type="button"
              fillMode={"outline"}
              themeColor={"primary"}
              style={{ marginRight: 4 }}
              onClick={() => navigate("/sightseensearchlist")}
            >
              Previous
            </Button>
            <RippleButton onClick={() => navigate("/sightseencustomerdetails")}>
              Proceed Booking
            </RippleButton>
          </div>
        </GridLayoutItem>
        <GridLayoutItem>
          <SightseenCartGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const SightseenCartGridComponent: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const SightseenCartList = useAppSelector(
    (state: RootState) => state.sightseenSearch.SightseenCartList
  );
  const loading = useAppSelector(
    (state: RootState) => state.sightseenSearch.loading
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteSightseenCart"));
      dispatch(setSightseenSearchID(ID));
    };

    return (
      <td className={`${props.className} action-td`} style={props.style}>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem.ID)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => {
    return (
      <HeaderThElement
        columnId={props.thProps?.columnId || ""}
        {...props.thProps}
        className={`table-header ${props.index === 7 ? "locked-header" : ""}`}
      >
        {props.children}
      </HeaderThElement>
    );
  };

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  return (
    <>
      {SightseenCartList && SightseenCartList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 200px)" }}
          data={SightseenCartList}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column field="SightseenName" title="Sightseen Name" width={250} />
          <Column field="SightseenType" title="Sightseen Type" width={250} />
          <Column field="VehicleType" title="Vehicle" width={250} />
          <Column
            field="Description"
            title="Description"
            width={650}
            cell={(props) => (
              <td style={{ textAlign: "justify" }}>
                {props.dataItem.Description}
              </td>
            )}
          />
          <Column field="AdultRate" title="Adult Rate" width={150} />
          <Column field="ChildRate" title="Child Rate" width={150} />
          <Column
            field="TotalRate"
            title="Total"
            cell={(props) => {
              return (
                <td>{props.dataItem.AdultRate + props.dataItem.ChildRate}</td>
              );
            }}
          />
          <Column
            field="ID"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            locked={true}
            filterable={false}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
      <div style={{ display: "flex", margin: "20px 0" }}>
        <div className="termsAndCondition" style={{ width: "50%" }}></div>
        <div style={{ width: "50%" }}>
          <table style={{ width: "100%" }} className="cartTable">
            <tr>
              <th>Total Price</th>
              <td>{SightseenCartList[0]?.TotalRate || 0}</td>
            </tr>
            <tr>
              <th>
                Transaction Charges{" "}
                <IoInformationCircleSharp style={{ fontSize: "20px" }} />
              </th>
              <td>{SightseenCartList[0]?.TransectionCharges || 0}</td>
            </tr>
            <tr>
              <th>Grand Total</th>
              <td>{SightseenCartList[0]?.GrandTotal || 0}</td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
};

const DeleteSightseenCartDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const SightseenSearchID = useAppSelector(
    (state: RootState) => state.sightseenSearch.SightseenSearchID
  );
  const dialogName = useAppSelector(
    (state: RootState) => state.dialog.dialogName
  );
  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteSightseenCart(ID));
      if (response.payload.Data.Status === 200) {
        dispatch(getAllSightseenCartDetails());
        dispatch(closeDialog());
        dispatch(clearSightseenSearchID());
      } else {
        dispatch(getAllSightseenCartDetails());
        dispatch(closeDialog());
        dispatch(clearSightseenSearchID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteSightseenCart" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete From Cart?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(SightseenSearchID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default SightseenCart;
