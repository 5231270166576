import React from "react";
// import { Tabs } from "antd";
// import type { TabsProps } from "antd";
import Itinery from "./Itinery";
import CostIncludes from "./CostIncludes";
import Package from "./Package";
import {
  GridLayout,
  GridLayoutItem,
  TabStrip,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { useNavigate } from "react-router-dom";
import IconButton from "../../components/common/IconButton";
import { MdEmail } from "react-icons/md";
import { FaDownload } from "react-icons/fa";
import { useAppSelector } from "../../app/hooks";

// const onChange = (key: string) => {
//   console.log(key);
// };

// const items: TabsProps["items"] = [
//   {
//     key: "1",
//     label: "Package",
//     children: <Package />,
//   },
//   {
//     key: "2",
//     label: "Cost Includes",
//     children: <CostIncludes />,
//   },
//   {
//     key: "3",
//     label: "Itinerary",
//     children: <Itinery />,
//   },
// ];

const PackageDetails: React.FC = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState(0);
  const handleSelect = (e: any) => {
    setSelected(e.selected);
  };

  const PackageDetail = useAppSelector(
    (state) => state.readymadePackage.PackageDetail
  );

  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            padding: "4px 20px 20px 0",
          }}
        >
          <Button
            style={{ marginTop: 10 }}
            themeColor={"primary"}
            fillMode={"solid"}
            onClick={() => navigate(-1)}
          >
            {`← Back`}
          </Button>
          <div style={{ textAlign: "center" }}>
            <Typography.h2 style={{ marginBottom: 0 }}>
              {PackageDetail?.TourName}
            </Typography.h2>
            <p>
              {" "}
              {PackageDetail?.NoOfNights} Nights / {PackageDetail?.NoOfDays}{" "}
              Days
            </p>
          </div>
          <div>
            <IconButton
              type="button"
              fillMode="flat"
              size="small"
              title="Email"
              //   onClick={() => handleEmail(props.dataItem.ID)}
            >
              <MdEmail
                className="absolute-position"
                style={{ fontSize: "20px" }}
              />
            </IconButton>
            <IconButton
              type="button"
              fillMode="flat"
              size="small"
              title="Download"
              //   onClick={() => handleDownload(props.dataItem.ID)}
            >
              <FaDownload
                className="absolute-position"
                style={{ fontSize: "20px" }}
              />
            </IconButton>
          </div>
        </GridLayoutItem>
        <GridLayoutItem>
          {/* <Tabs onChange={onChange} type="card" items={items} centered /> */}
          <TabStrip selected={selected} onSelect={handleSelect}>
            <TabStripTab title="Package" contentClassName="tabContainer">
              <Package />
            </TabStripTab>
            <TabStripTab title="Cost Includes" contentClassName="tabContainer">
              <CostIncludes />
            </TabStripTab>
            <TabStripTab title="Itinerary" contentClassName="tabContainer">
              <Itinery />
            </TabStripTab>
          </TabStrip>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default PackageDetails;
