import * as React from "react";
import {
    ComboBox,
    ComboBoxFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { FilterDescriptor, filterBy } from "@progress/kendo-data-query";
import { Error, Hint, FloatingLabel } from "@progress/kendo-react-labels";
const delay = 300;

const FormSelectionField = (fieldRenderProps: FieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        value,
        onChange,
        options,
        textField = "label",
        wrapperStyle,
        name,
        ...others
    } = fieldRenderProps;
    const editorRef = React.useRef<any>(null);
    const showValidationMessage: string | false | null =
        touched && validationMessage;
    const showHint: boolean = !showValidationMessage && hint;
    const hintId: string = showHint ? `${id}_hint` : "";
    const errorId: string = showValidationMessage ? `${id}_error` : "";
    const labelId: string = label ? `${id}_label` : "";

    const [data, setData] = React.useState(options);
    const [loading, setLoading] = React.useState(false);
    // eslint-disable-next-line no-undef
    const timeout = React.useRef<NodeJS.Timeout | null>(null);

    React.useEffect(() => {
        setData(options);
    }, [options]);

    const filterData = (filter: FilterDescriptor) => {
        const data = options?.slice();
        return filterBy(data, filter);
    };

    const filterChange = (event: ComboBoxFilterChangeEvent) => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(() => {
            setData(filterData(event.filter));
            setLoading(false);
        }, delay);

        setLoading(true);
    };

    const handleSelect = (event: any) => {
        const selectedValue = event.target.value;
        onChange(selectedValue);
    };

    let initialValue = options?.find((option: any) => option.value === value);

    return (
        <>
            <FieldWrapper style={wrapperStyle}>
                <FloatingLabel
                    editorValue={value}
                    editorId={id}
                    editorValid={valid}
                    editorDisabled={disabled}
                    label={label}
                    labelClassName="k-form-label"
                >
                    <ComboBox
                        ariaLabelledBy={labelId}
                        ariaDescribedBy={`${hintId} ${errorId}`}
                        ref={editorRef}
                        valid={valid}
                        id={id}
                        disabled={disabled}
                        {...others}
                        data={data}
                        name={name}
                        value={initialValue || null}
                        filterable={true}
                        onFilterChange={filterChange}
                        loading={loading}
                        textField={textField}
                        defaultValue={value}
                        onChange={handleSelect}
                    />
                    {showHint && <Hint id={hintId}>{hint}</Hint>}
                    {showValidationMessage && (
                        <Error id={errorId}>{validationMessage}</Error>
                    )}
                </FloatingLabel>
            </FieldWrapper>
        </>
    );
};

export default FormSelectionField;
