import { configureStore } from "@reduxjs/toolkit";
import { getDefaultMiddleware } from "@reduxjs/toolkit";
import dialogSlice from "../components/dialog/dialogSlice";
import loginReducer from "../features/login/loginSlice";
import countrySlice from "../features/country/countrySlice";
import userSlice from "../features/user/userSlice";
import stateSlice from "../features/state/stateSlice";
import citySlice from "../features/city/citySlice";
import vehicleTypeSlice from "../features/vehicleType/vehicleTypeSlice";
import nationalitySlice from "../features/nationality/nationalitySlice";
import sectorSlice from "../features/sector/sectorSlice";
import termsAndConditionSlice from "../features/termsandcondition/termsAndConditionSlice";
import mealtypeSlice from "../features/mealtype/mealtypeSlice";
import userTypeSlice from "../features/userType/userTypeSlice";
import transferLocationSlice from "../features/transferlocation/transferLocationSlice";
import transferSlice from "../features/transfer/transferSlice";
import transferSearchSlice from "../features/transfersearch/transferSearchSlice";
import sightseenTypeSlice from "../features/sightseentype/sightseenTypeSlice";
import sightseenSlice from "../features/sightseen/sightseenSlice";
import regionSlice from "../features/region/regionSlice";
import sightseenSearchSlice from "../features/sightseensearch/sightseenSearchSlice";
import tourSlice from "../features/tour/tourSlice";
import companySlice from "../features/company/companySlice";
import specialityTypeSlice from "../features/specialitytype/specialityTypeSlice";
import roomTypeSlice from "../features/roomtype/roomTypeSlice";
import gstSlice from "../features/gst/gstSlice";
import currencySlice from "../features/currency/currencySlice";
import vehicleSharingPaxSlice from "../features/vehiclesharingpax/vehicleSharingPaxSlice";
import hotelSlice from "../features/hotel/hotelSlice";
import drawerSlice from "../components/drawer/drawerSlice";
import roomCategorySlice from "../features/roomcategory/roomCategorySlice";
import mealsSlice from "../features/meals/mealsSlice";
import mealCategorySlice from "../features/mealcategory/mealCategorySlice";
import customizeSearchSlice from "../features/customizesearch/customizeSearchSlice";
import readymadepackageSlice from "../features/readymadepackage/readymadePackageSlice";
import toursAndTransfersSlice from "../features/toursandtransfers/toursAndTransfersSlice";
import plannerSlice from "../features/planner/plannerSlice";

const customizedMiddleware = () =>
  getDefaultMiddleware({
    serializableCheck: false,
  });

const store = configureStore({
  reducer: {
    login: loginReducer,
    dialog: dialogSlice,
    drawer: drawerSlice,
    user: userSlice,
    userType: userTypeSlice,
    country: countrySlice,
    state: stateSlice,
    city: citySlice,
    region: regionSlice,
    vehicleType: vehicleTypeSlice,
    nationality: nationalitySlice,
    sector: sectorSlice,
    termsandcondition: termsAndConditionSlice,
    mealType: mealtypeSlice,
    transferLocation: transferLocationSlice,
    transfer: transferSlice,
    transferSearch: transferSearchSlice,
    sightseenType: sightseenTypeSlice,
    sightseen: sightseenSlice,
    sightseenSearch: sightseenSearchSlice,
    tour: tourSlice,
    company: companySlice,
    specialityType: specialityTypeSlice,
    roomType: roomTypeSlice,
    gst: gstSlice,
    currency: currencySlice,
    vehicleSharingPax: vehicleSharingPaxSlice,
    hotel: hotelSlice,
    roomCategory: roomCategorySlice,
    meals: mealsSlice,
    mealCategory: mealCategorySlice,
    customizeSearch: customizeSearchSlice,
    readymadePackage: readymadepackageSlice,
    toursAndTransfers: toursAndTransfersSlice,
    planner: plannerSlice,
  },
  middleware: customizedMiddleware,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
