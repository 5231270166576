import React, { useEffect } from "react";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import FormTextField from "../../components/formFields/FormTextField";
import { MdDelete } from "react-icons/md";
import IconButton from "../../components/common/IconButton";
import { setHotelContactDeleteIndex } from "./hotelSlice";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { BOOLEAN } from "../../_contstants/common";

const HotelContactArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const contactDeleteIndex = useAppSelector((state) => state.hotel.deleteIndex);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(closeDialog());
    };
  }, []);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("hotelContactDeleteDialog"));
    dispatch(setHotelContactDeleteIndex(index));
  };

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setHotelContactDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        PersonName: "",
        MobileNo: "",
        EmailID: "",
        AlternateMobileNo: "",
        AlternateEmailID: "",
        IsOwner: false,
      },
    });
  }, [fieldArrayRenderProps]);

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {fieldArrayRenderProps.value && fieldArrayRenderProps.value.length === 0
        ? pushElementInarray()
        : null}
      <GridLayoutItem style={{ marginBottom: 10 }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Contact
        </Button>
      </GridLayoutItem>
      {dialogName === "hotelContactDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Contact"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(contactDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((HotelContacts: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card className="fieldarray-card">
              <GridLayout
                style={{ marginRight: 60 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "15%" },
                  { width: "15%" },
                  { width: "16%" },
                  { width: "15%" },
                  { width: "16%" },
                  { width: "15%" },
                  { width: "8%" },
                ]}
              >
                <GridLayoutItem>
                  <Field
                    name={`HotelContacts.${index}.PersonName`}
                    label="Person Name"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`HotelContacts.${index}.MobileNo`}
                    label="Mobile No"
                    type="number"
                    max="16"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`HotelContacts.${index}.AlternateMobileNo`}
                    label="Alternate Mo. No"
                    type="number"
                    max="16"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`HotelContacts.${index}.EmailID`}
                    label="Email ID"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`HotelContacts.${index}.AlternateEmailID`}
                    label="Alternate Email ID"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`HotelContacts.${index}.IsOwner`}
                    label="Is Owner"
                    component={FormSelectionField}
                    options={BOOLEAN?.map((owner: any) => {
                      return {
                        value: owner?.value,
                        label: owner?.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    textAlign: "right",
                    position: "relative",
                  }}
                >
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    themeColor={"error"}
                    size={"small"}
                    fillMode={"solid"}
                    type="button"
                    style={{
                      position: "absolute",
                      top: 38,
                      right: 0,
                      height: 24,
                      width: 24,
                    }}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </IconButton>
                </GridLayoutItem>
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
    </GridLayout>
  );
};

export default HotelContactArray;
