import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IToursAndTransfersState,
  ITourTransferTourList,
} from "./toursAndTransfersModel";
import { getAllActiveTourSightseen } from "./services/toursAndTransfers.services";

const initialState: IToursAndTransfersState = {
  loading: false,
  error: "",
  ToursAndTransfersID: null,
  ToursAndTransfersList: [],
  ToursAndTransfersDetail: {
    IsActive: false,
  },
  TourDetail: {
    SectorID: null,
    SightseenTiming: 2,
  },
  TourList: [],
  TransferDetail: {
    TripType: 1,
    FromLocationID: null,
    ToLocationID: null,
  },
  MealDetail: {
    MealType: 2,
    SectorID: null,
    MealData: [],
  },
};

const toursAndTransferSlice = createSlice({
  name: "toursAndTransfers",
  initialState,
  reducers: {
    // clearMealTypeDetails: (state) => {
    //   state.MealTypeDetail = initialState.MealTypeDetail;
    // },
    // setMealTypeID: (state, action) => {
    //   state.MealTypeID = action.payload;
    // },
    // clearMealTypeID: (state) => {
    //   state.MealTypeID = initialState.MealTypeID;
    // },
    clearTourList: (state) => {
      state.TourList = initialState.TourList;
    },
    setTransferDetails: (state, action) => {
      state.TransferDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllActiveTourSightseen.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveTourSightseen.fulfilled,
      (state, action: PayloadAction<ITourTransferTourList[]>) => {
        state.loading = false;
        state.TourList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveTourSightseen.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TourList = [];
    });
  },
});

export const { clearTourList, setTransferDetails } =
  toursAndTransferSlice.actions;
export default toursAndTransferSlice.reducer;
