import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IQuotationDetailsList,
  ITransferCartList,
  ITransferSearch,
  ITransferSearchState,
} from "./transferSerchModel";
import {
  AddToCartTransferSearch,
  AddTransferQuotation,
  deleteQuotation,
  deleteTransferCart,
  getAllTransferQuotationDetails,
  getAllTransferCartDetails,
  getAllTransferSearchs,
} from "./services/transferSearch.services";
// import { ITransfer, ITransferState } from "./transferModel";
// import {
//   createTransfer,
//   deleteTransfer,
//   getAllActiveTransfers,
//   getAllTransfers,
//   getTransferByID,
//   transferActiveInactive,
//   updateTransfer,
//   uploadTransferImage,
// } from "./services/transfer.services";

const initialState: ITransferSearchState = {
  loading: false,
  error: "",
  TransferSearchID: null,
  TransferSearchList: [],
  TransferCartList: [],
  AddtoCartDetails: {
    TransferID: null,
    SectorID: null,
    FromLocationID: null,
    ToLocationID: null,
    CityID: null,
    VehicleTypeID: null,
    TermsAndConditionsID: null,
    Pax: null,
    Rate: null,
    IsActive: true,
    TripType: null,
    Remarks: "",
  },
  TransferDetail: {
    FromLocationID: null,
    SectorID: null,
    ToLocationID: null,
    FromDate: "",
    ToDate: "",
    NoOfAdult: "1",
    NoOfChild: "0",
    TripType: null,
  },
  GuestDetails: {
    Initial: "",
    FirstName: "",
    LastName: "",
    MobileNo: "",
    AlternateMobileNo: "",
    BookingConfirmedBy: "",
    MailedQuotation: "",
    Remarks: "",
    IsActive: true,
  },
  QuotationDetailsList: [],
};

const transferSlice = createSlice({
  name: "transferSearch",
  initialState,
  reducers: {
    // clearTransferDetails: (state) => {
    //   state.TransferDetail = initialState.TransferDetail;
    // },
    // setTransferID: (state, action) => {
    //   state.TransferID = action.payload;
    // },
    setTransferSearchID: (state, action) => {
      state.TransferSearchID = action.payload;
    },
    clearTransferSearchID: (state) => {
      state.TransferSearchID = initialState.TransferSearchID;
    },
    setTransferSearchDetails: (state, action) => {
      state.TransferDetail = action.payload;
    },
    clearTransferSearchDetails: (state) => {
      state.TransferDetail = initialState.TransferDetail;
    },
    clearTransferSearchList: (state) => {
      state.TransferSearchList = [];
    },
    setAddToCartDetails: (state, action) => {
      state.AddtoCartDetails = action.payload;
    },
    clearAddToCartDetails: (state) => {
      state.AddtoCartDetails = initialState.AddtoCartDetails;
    },
    clearGuestDetails: (state) => {
      state.GuestDetails = initialState.GuestDetails;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTransferSearchs.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllTransferSearchs.fulfilled,
      (state, action: PayloadAction<ITransferSearch[]>) => {
        state.loading = false;
        state.TransferSearchList = action.payload || [];
      }
    );
    builder.addCase(getAllTransferSearchs.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TransferSearchList = [];
    });
    builder.addCase(AddToCartTransferSearch.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(AddToCartTransferSearch.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(AddToCartTransferSearch.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllTransferCartDetails.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllTransferCartDetails.fulfilled,
      (state, action: PayloadAction<ITransferCartList[]>) => {
        state.loading = false;
        state.TransferCartList = action.payload || [];
      }
    );
    builder.addCase(getAllTransferCartDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TransferCartList = [];
    });
    builder.addCase(AddTransferQuotation.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(AddTransferQuotation.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(AddTransferQuotation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteTransferCart.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteTransferCart.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTransferCart.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllTransferQuotationDetails.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllTransferQuotationDetails.fulfilled,
      (state, action: PayloadAction<IQuotationDetailsList[]>) => {
        state.loading = false;
        state.QuotationDetailsList = action.payload || [];
      }
    );
    builder.addCase(
      getAllTransferQuotationDetails.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.QuotationDetailsList = [];
      }
    );
    builder.addCase(deleteQuotation.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteQuotation.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteQuotation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  // clearTransferID,
  // setTransferID,
  // clearTransferDetails,
  // setDeleteIndex,
  setAddToCartDetails,
  clearAddToCartDetails,
  setTransferSearchDetails,
  clearTransferSearchDetails,
  clearTransferSearchList,
  setTransferSearchID,
  clearTransferSearchID,
  clearGuestDetails,
} = transferSlice.actions;
export default transferSlice.reducer;
