import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
// import FormCheckbox from "../../components/formFields/FormCheckbox";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  createMealType,
  getAllMealType,
  getMealTypeByID,
  updateMealType,
} from "./services/mealtype.services";
import { LoadingPanel } from "../../components/layout/Loading";
import AppDialog from "../../components/dialog/Dialog";
import { closeDialog } from "../../components/dialog/dialogSlice";
import { clearMealTypeDetails } from "./mealtypeSlice";

const CreateMealType: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const MealTypeDetail = useAppSelector(
    (state) => state.mealType.MealTypeDetail
  );
  const MealTypeID = useAppSelector((state) => state.mealType.MealTypeID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const { loading } = useAppSelector((state) => state.mealType);

  useEffect(() => {
    if (MealTypeID) {
      dispatch(getMealTypeByID(MealTypeID));
    }
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      if (MealTypeID === 0) {
        const payload = {
          MealType: values.MealType,
          IsActive: true,
        };
        const response = await dispatch(createMealType(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closeDialog());
          dispatch(clearMealTypeDetails());
          dispatch(getAllMealType());
        }
      } else {
        const payload = {
          ID: MealTypeID,
          MealType: values.MealType,
          IsActive: values.IsActive,
        };
        const response = await dispatch(updateMealType(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closeDialog());
          dispatch(clearMealTypeDetails());
          dispatch(getAllMealType());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const dialogCloseHandle = () => {
    dispatch(closeDialog());
    dispatch(clearMealTypeDetails());
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {dialogName === "mealTypeForm" && (
        <AppDialog>
          <div>
            <Typography.h4 style={{ margin: "0px" }}>
              {MealTypeID === 0 ? "Create Meal Type" : "Update Meal Type"}
            </Typography.h4>
            <Form
              onSubmit={handleSubmit}
              initialValues={MealTypeDetail}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement style={{ width: "600px" }}>
                  <GridLayout
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                    gap={{ rows: 10 }}
                    cols={[{ width: "100%" }]}
                  >
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        name="MealType"
                        label="Meal Type"
                        component={FormTextField}
                        validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem
                      colSpan={3}
                      style={{
                        textAlign: "end",
                        marginTop: "10px",
                        width: "100%",
                      }}
                    >
                      <Button
                        type="button"
                        fillMode={"outline"}
                        themeColor={"primary"}
                        style={{ marginRight: 4 }}
                        onClick={dialogCloseHandle}
                      >
                        Cancel
                      </Button>
                      <ButtonWithLoading
                        label="Save"
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </GridLayoutItem>
                  </GridLayout>
                </FormElement>
              )}
            />
          </div>
        </AppDialog>
      )}
    </>
  );
};

export default CreateMealType;
