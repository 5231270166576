import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import { useLocation } from "react-router-dom";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import {
  deleteTourTransfer,
  getTourByID,
  getTourTransfersByTourID,
  insertTourFixedTransfer,
  updateTourFixedTransfer,
} from "../services/tour.services";
import {
  setTourTransferDetails,
  setTourTransferID,
  increaseActiveStep,
  decreaseActiveStep,
  clearTourTransferDetails,
  clearTourTransferID,
} from "../tourSlice";
import RippleButton from "../../../components/common/RippleButton";
import AppDialog, {
  DialogCloseButton,
} from "../../../components/dialog/Dialog";
import { Button } from "@progress/kendo-react-buttons";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import IconButton from "../../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import ShadowCard from "../../../components/common/ShadowCard";
import { generateDaysArray } from "../../../_helper/commonHelpers";
import { TRANSFERTYPE } from "../../../_contstants/common";
import { getTransferLocationBySectorID } from "../../transferlocation/services/transferLocation.services";
import { ITransferLocation } from "../../transferlocation/transferLocationModel";

const TourFixedTransfers: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const TourID = location.state?.TourID;
  const TourDetail = useAppSelector((state) => state.tour.TourDetail);

  useEffect(() => {
    dispatch(getTourTransfersByTourID(TourID));
    dispatch(getTourByID(TourID));
  }, [TourID]);

  useEffect(() => {
    if (TourDetail?.SectorID) {
      dispatch(getTransferLocationBySectorID(TourDetail.SectorID));
    }
  }, [TourDetail?.SectorID]);

  useEffect(() => {
    return () => {
      dispatch(clearTourTransferDetails());
    };
  }, []);

  return (
    <>
      <DeleteTourTransferDialog />
      <TourTransferForm />
      <TourTransferGridComponent />
    </>
  );
};

const TourTransferForm: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const TourID = location.state?.TourID;
  const NoOfDays = location.state?.NoOfDays;
  const loading = useAppSelector((state) => state.tour.loading);
  const TourTransferDetails = useAppSelector(
    (state) => state.tour.TourTransferDetails
  );
  const TransferLocationList = useAppSelector(
    (state) => state.transferLocation.TransferLocationList
  );

  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [TourTransferDetails]);

  const handleSubmit = async (values: any) => {
    try {
      if (!TourTransferDetails?.ID) {
        const insertPayload = {
          ID: values.ID,
          TourID: TourID,
          DayNo: values.DayNo ? +values.DayNo : null,
          TransferType: values.TransferType ? +values.TransferType : null,
          FromLocationID: values.FromLocationID ? +values.FromLocationID : null,
          ToLocationID: values.ToLocationID ? +values.ToLocationID : null,
        };
        const response = await dispatch(insertTourFixedTransfer(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(getTourTransfersByTourID(TourID));
          setFormKey(formKey + 1);
          dispatch(clearTourTransferDetails());
        }
      } else {
        const editPayload = {
          ID: values.ID,
          TourID: TourID,
          DayNo: values.DayNo ? +values.DayNo : null,
          TransferType: values.TransferType ? +values.TransferType : null,
          FromLocationID: values.FromLocationID ? +values.FromLocationID : null,
          ToLocationID: values.ToLocationID ? +values.ToLocationID : null,
        };
        const response = await dispatch(updateTourFixedTransfer(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(getTourTransfersByTourID(TourID));
          setFormKey(formKey + 1);
          dispatch(clearTourTransferDetails());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      key={formKey}
      initialValues={TourTransferDetails}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <ShadowCard style={{ padding: 10 }}>
            <GridLayout
              style={{ marginRight: 30 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
              ]}
            >
              <GridLayoutItem colSpan={4}>
                <Typography.h4 style={{ margin: 0 }}>
                  {"Add Tour Fixed Transfer"}
                </Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`DayNo`}
                  label="Day"
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={generateDaysArray(+NoOfDays)}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`TransferType`}
                  label="Transfer Type"
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={TRANSFERTYPE}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  id={"FromLocationID"}
                  name={"FromLocationID"}
                  label={"From Location"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={TransferLocationList.map(
                    (location: ITransferLocation) => {
                      return {
                        value: location.ID,
                        label: location.LocationName,
                      };
                    }
                  )}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  id={"ToLocationID"}
                  name={"ToLocationID"}
                  label={"To Location"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={TransferLocationList.map(
                    (location: ITransferLocation) => {
                      return {
                        value: location.ID,
                        label: location.LocationName,
                      };
                    }
                  )}
                />
              </GridLayoutItem>
              <GridLayoutItem
                colSpan={4}
                style={{ textAlign: "end", marginTop: 10 }}
              >
                <RippleButton
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => dispatch(decreaseActiveStep())}
                >
                  Previous
                </RippleButton>
                <RippleButton
                  fillMode="solid"
                  themeColor="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => dispatch(increaseActiveStep())}
                >
                  Next
                </RippleButton>
                <RippleButton
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setFormKey(formKey + 1);
                    dispatch(clearTourTransferDetails());
                  }}
                >
                  Cancel
                </RippleButton>
                <ButtonWithLoading
                  label={TourTransferDetails?.ID ? "Update" : "Add"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || loading}
                  loading={loading}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
        </FormElement>
      )}
    />
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const TourTransferGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const TourTransferList = useAppSelector(
    (state) => state.tour.TourTransferList
  );
  const loading = useAppSelector((state) => state.tour.loading);

  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? TourTransferList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (item: any) => {
      const payload = {
        ID: item.ID,
        TourID: item.TourID,
        DayNo: item.DayNo ? +item.DayNo : null,
        TransferType: item.TransferType ? +item.TransferType : null,
        FromLocationID: item.FromLocationID ? +item.FromLocationID : null,
        ToLocationID: item.ToLocationID ? +item.ToLocationID : null,
      };
      dispatch(setTourTransferDetails(payload));
    };
    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteTourTransfer"));
      dispatch(setTourTransferID(ID));
    };

    return (
      <td
        className={props.className}
        style={{
          ...props.style,
          textAlign: "center",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEdit(props.dataItem)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem.ID)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 4 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {TourTransferList && TourTransferList.length ? (
        <KendoGrid
          style={{ marginTop: 20 }}
          data={TourTransferList}
          skip={page.skip}
          take={page.take}
          total={TourTransferList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column field="DayNo" title="Day No" />
          <Column
            field="TransferType"
            title="Transfer Type"
            cell={(props: GridCellProps) => (
              <td>
                {props.dataItem?.TransferType === 1
                  ? "One Way"
                  : props.dataItem?.TransferType === 2
                  ? "Two Way"
                  : ""}
              </td>
            )}
          />
          <Column field="FromLocation" title="From Location" />
          <Column field="ToLocation" title="To Location" />
          <Column
            field="ID"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
            locked={true}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteTourTransferDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const TourID = location.state?.TourID;
  const TourTransferID = useAppSelector((state) => state.tour.TourTransferID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteTourTransfer(ID));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(getTourTransfersByTourID(TourID));
        dispatch(closeDialog());
        dispatch(clearTourTransferID());
      } else {
        dispatch(getTourTransfersByTourID(TourID));
        dispatch(closeDialog());
        dispatch(clearTourTransferID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteTourTransfer" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Tour Transfer"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Tour Transfer?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(TourTransferID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default TourFixedTransfers;
