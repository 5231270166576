import React, { useRef } from "react";
import { Typography } from "@progress/kendo-react-common";
// import Accordion from "react-bootstrap/Accordion";
import { Collapse } from "antd";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import AlertBox from "../../components/common/AlertBox";

// const data = [
//   {
//     title: "Arrival Transfer",
//     description:
//       "Ngurah Rai International Airport to Kuta Hotel on Private Basis",
//   },
//   {
//     title: "Half Day Benoa Beach Tour including Banana Boat on private basis",
//     description:
//       "An ideal Bali holiday is incomplete without water sports & what better than the famous Tanjung Benoa beach. This beach best known for its aquatic adventure is located in the Benoa peninsula far away from the hustle & bustle. North of Nusa Dua this beach offers a wide range of water sports from Para-Sailing to Banana boat ride & so on. Please check with our representatives for all the water sports information. Many shacks & restaurants lined up along the beach make sure that your tummy is full & you enjoy the most.",
//   },
// ];

const Itinery: React.FC = () => {
  const gridRef = useRef<any>(null);
  const loading = useAppSelector((state) => state.readymadePackage.loading);
  const PackageDetail = useAppSelector(
    (state) => state.readymadePackage.PackageDetail
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <div>
        <Typography.h4>Day wise Itinerary</Typography.h4>
        {/* <Collapse
            accordion
            items={items}
            defaultActiveKey={["1"]}
            expandIcon={({ isActive }) => (isActive ? <FaMinus /> : <FaPlus />)}
          /> */}
        {PackageDetail?.ItineraryList.length > 0 ? (
          PackageDetail?.ItineraryList?.map((item: any, index: number) => {
            return (
              <Collapse
                collapsible="header"
                defaultActiveKey={["1"]}
                expandIcon={({ isActive }) =>
                  isActive ? <FaMinus /> : <FaPlus />
                }
                style={{ marginBottom: 5 }}
                items={[
                  {
                    key: `${index + 1}`,
                    label: `Day - ${item?.DayNo} ${"       "} ${item?.Title}`,
                    children: (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item?.Description,
                        }}
                      />
                    ),
                  },
                ]}
              />
            );
          })
        ) : (
          <AlertBox />
        )}
        {/* <p className="itinery-list" style={{ fontWeight: 500 }}>
                Day - {index + 1}
                <span style={{ marginLeft: 100 }}>{item?.title}</span>
              </p>
              <p
                className="itinery-list"
                style={{ borderTop: "none", textAlign: "justify" }}
              >
                {item?.description}
      </p>  */}
        {/* <Accordion defaultActiveKey="0">
          {data.map((item: any, index: number) => {
            return (
              <div style={{ marginBottom: 10 }}>

                <Accordion.Item eventKey={`${index + 1}`}>
                  <Accordion.Header className="accordian_header">
                    Day - {index + 1}
                    <span style={{ marginLeft: 30 }}>{item?.title}</span>
                  </Accordion.Header>
                  <Accordion.Body>{item?.description}</Accordion.Body>
                </Accordion.Item>
              </div>
            );
          })}
        </Accordion> */}
      </div>
    </>
  );
};

export default Itinery;
