import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMeals, IMealsState } from "./mealsModel";
import {
  ActiveInactiveMeals,
  createMeals,
  deleteMeals,
  getAllActiveMeals,
  getAllMeals,
  getMealRateByID,
  getMealsByID,
  updateMeals,
} from "./services/meals.services";

const initialState: IMealsState = {
  loading: false,
  formLoading: false,
  error: "",
  MealsList: [],
  MealsID: null,
  MealsDetail: {
    MealCategoryID: null,
    MealName: "",
    Rate: null,
    Description: "",
    IsActive: false,
  },
  MealRateList: [],
};

const mealsSlice = createSlice({
  name: "meals",
  initialState,
  reducers: {
    clearMealsDetails: (state) => {
      state.MealsDetail = initialState.MealsDetail;
    },
    clearMealRateList: (state) => {
      state.MealRateList = initialState.MealRateList;
    },
    setMealsID: (state, action) => {
      state.MealsID = action.payload;
    },
    clearMealsID: (state) => {
      state.MealsID = initialState.MealsID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllMeals.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllMeals.fulfilled,
      (state, action: PayloadAction<IMeals[]>) => {
        state.loading = false;
        state.MealsList = action.payload || [];
      }
    );
    builder.addCase(getAllMeals.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.MealsList = [];
    });
    builder.addCase(getAllActiveMeals.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveMeals.fulfilled,
      (state, action: PayloadAction<IMeals[]>) => {
        state.loading = false;
        state.MealsList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveMeals.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.MealsList = [];
    });
    builder.addCase(createMeals.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createMeals.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createMeals.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateMeals.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateMeals.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateMeals.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteMeals.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteMeals.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteMeals.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(ActiveInactiveMeals.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveMeals.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveMeals.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getMealsByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getMealsByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.MealsDetail = action.payload;
    });
    builder.addCase(getMealsByID.rejected, (state, action) => {
      state.formLoading = false;
      state.MealsDetail = initialState.MealsDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getMealRateByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getMealRateByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.MealRateList = action.payload || [];
    });
    builder.addCase(getMealRateByID.rejected, (state, action) => {
      state.formLoading = false;
      state.MealRateList = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearMealsID,
  setMealsID,
  clearMealsDetails,
  clearMealRateList,
} = mealsSlice.actions;
export default mealsSlice.reducer;
