import React from "react";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Error, Hint, Label } from "@progress/kendo-react-labels";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
    MultiViewCalendar,
    MultiViewCalendarProps,
} from "@progress/kendo-react-dateinputs";

const CustomCalendar: React.FC = (props: MultiViewCalendarProps) => {
    return <MultiViewCalendar {...props} views={1} />;
};

const FormDatePicker = (fieldRenderProps: FieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        wrapperStyle,
        wrapperClassName,
        hintDirection,
        ...others
    } = fieldRenderProps;

    const showValidationMessage: string | false | null =
        touched && validationMessage;
    const showHint: boolean = !showValidationMessage && hint;
    const hintId: string = showHint ? `${id}_hint` : "";
    const errorId: string = showValidationMessage ? `${id}_error` : "";
    const labelId: string = label ? `${id}_label` : "";

    return (
        <FieldWrapper style={wrapperStyle} className={wrapperClassName}>
            <Label
                id={labelId}
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
                className="k-form-label"
            >
                {label}
            </Label>
            <div className={"k-form-field-wrap"}>
                <DatePicker
                    calendar={CustomCalendar}
                    ariaLabelledBy={labelId}
                    ariaDescribedBy={`${hintId} ${errorId}`}
                    valid={valid}
                    id={id}
                    min={others.minDate}
                    max={others.maxDate}
                    disabled={disabled}
                    {...others}
                />
                {showHint && (
                    <Hint id={hintId} direction={hintDirection}>
                        {hint}
                    </Hint>
                )}
                {showValidationMessage && (
                    <Error id={errorId}>{validationMessage}</Error>
                )}
            </div>
        </FieldWrapper>
    );
};

export default FormDatePicker;
