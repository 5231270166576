import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  createCurrency,
  getAllCurrencies,
  getCurrencyByID,
  updateCurrency,
} from "./services/currency.services";
import { clearCurrencyDetails } from "./currencySlice";
import { closed } from "../../components/drawer/drawerSlice";

const CreateCurrency: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const CurrencyDetail = useAppSelector(
    (state) => state.currency.CurrencyDetail
  );
  const CurrencyID = useAppSelector((state) => state.drawer.data);
  const formLoading = useAppSelector((state) => state.currency.formLoading);

  useEffect(() => {
    return () => {
      dispatch(clearCurrencyDetails());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (CurrencyID) {
      dispatch(getCurrencyByID(CurrencyID));
    }
  }, [CurrencyID]);

  const handleSubmit = async (values: any) => {
    try {
      if (!CurrencyID) {
        const insertPayload = {
          CurrencyName: values.CurrencyName,
          CurrencyConversion: values.CurrencyConversion
            ? +values.CurrencyConversion
            : null,
          IsActive: true,
        };
        const response = await dispatch(createCurrency(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearCurrencyDetails());
          dispatch(getAllCurrencies());
        }
      } else {
        const editPayload = {
          ID: CurrencyID,
          CurrencyName: values.CurrencyName,
          CurrencyConversion: values.CurrencyConversion
            ? +values.CurrencyConversion
            : null,
          IsActive: values.IsActive,
        };
        const response = await dispatch(updateCurrency(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearCurrencyDetails());
          dispatch(getAllCurrencies());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={CurrencyDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="CurrencyName"
                label="Currency"
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="CurrencyConversion"
                label="Currency Conversion"
                type="number"
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "end",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginRight: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
              <ButtonWithLoading
                label={CurrencyID ? "Update" : "Create"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateCurrency;
