import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IMeals } from "../mealsModel";

export const getAllMeals = createAsyncThunk("Meals/FindAllMeals", async () => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/Meals/FindAllMeals`,
      {
        MealName: "",
      }
    );
    // SuccessToast(response.data?.Details || "Success");
    return response.data?.Data;
  } catch (error: any) {
    ErrorToast(error?.response?.data?.Details || "Something went wrong");
    console.error("Error Fetching Meals:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const getAllActiveMeals = createAsyncThunk(
  "Meals/FindAllActiveMeals",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Meals/FindAllActiveMeals`
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Meals:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createMeals = createAsyncThunk(
  "Meals/InsertMeals",
  async (MealsData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Meals/InsertMeals`,
        MealsData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating meals:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateMeals = createAsyncThunk(
  "Meals/UpdateMeals",
  async (MealsData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Meals/UpdateMeals`,
        MealsData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating meals:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteMeals = createAsyncThunk(
  "Meals/DeleteMeals",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Meals/DeleteMeals`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting meals:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getMealsByID = createAsyncThunk(
  "Meals/FindByIDMeals",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Meals/FindByIDMeals`,
        { ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        MealCategoryID: result.MealCategoryID ? +result.MealCategoryID : null,
        Rate: result.Rate ? +result.Rate : null,
        MealName: result.MealName,
        Description: result.Description,
        IsActive: result.IsActive,
      } as IMeals;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching meals:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveMeals = createAsyncThunk(
  "Meals/ActiveInActiveMeals",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Meals/ActiveInActiveMeals`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive meals:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getMealRateByID = createAsyncThunk(
  "Meals/MealRateByID",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Meals/MealRateByID`,
        formData
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Meal Rates:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);