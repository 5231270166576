import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IUserType } from "../userTypeModel";

export const getAllUserTypes = createAsyncThunk(
  "UserType/FindAllUserType",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/UserType/FindAllUserType`,
        {
          UserType: "",
        }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching User Types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createUserType = createAsyncThunk(
  "UserType/InsertUserType",
  async (CityData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/UserType/InsertUserType`,
        CityData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating User Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateUserType = createAsyncThunk(
  "UserType/UpdateUserType",
  async (CityData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/UserType/UpdateUserType`,
        CityData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating User Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteUserType = createAsyncThunk(
  "UserType/DeleteUserType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/UserType/DeleteUserType`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting User Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getUserTypeByID = createAsyncThunk(
  "UserType/FindByIDUserType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/UserType/FindByIDUserType`,
        { ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
      } as IUserType;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching User Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveUserTypes = createAsyncThunk(
  "UserType/FindAllActiveUserType",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/UserType/FindAllActiveUserType`
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching User Types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveUserType = createAsyncThunk(
  "UserType/ActiveInActiveUserType",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/UserType/ActiveInActiveUserType`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive User Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
