import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import RippleButton from "../../components/common/RippleButton";
import ShadowCard from "../../components/common/ShadowCard";
import {
  CardBody,
  CardImage,
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { MdShoppingCart } from "react-icons/md";
import moment from "moment";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Typography } from "@progress/kendo-react-common";
import FormDatePicker from "../../components/formFields/FormDateField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { IVehicleType } from "../vehicleType/vehicleTypeModel";
import { ICity } from "../city/cityModel";
import { ICountry } from "../country/countryModel";
import { INationality } from "../nationality/nationalityModel";
import { getAllActiveCities } from "../city/services/city.services";
import { getAllActiveCountries } from "../country/services/country.services";
import { getAllActiveVehicleTypes } from "../vehicleType/services/vehicleType.services";
import { getAllActiveNationalities } from "../nationality/services/nationality.services";
import {
  clearAddToCartSightseenDetails,
  setAddToCartSightseenDetails,
  setSightseenSearchDetails,
} from "./sightseenSearchSlice";
import {
  AddToCartSightseenSearch,
  getAllSightseenSearchs,
} from "./services/sightseenSearch.services";
import { LoadingPanel } from "../../components/layout/Loading";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog from "../../components/dialog/Dialog";
// import FormTextField from "../../components/formFields/FormTextField";
import { NOOFADULTS, NOOFCHILD } from "../../_contstants/common";
import FormTextArea from "../../components/formFields/FormTextArea";

const SightseenSearchList: React.FC = () => {
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [formKey, setFormKey] = React.useState(1);
  const [showTransferSearch, setShowTransferSearch] =
    React.useState<boolean>(false);
  const [initialValues, setInitialValues] = React.useState<any>({});
  const SightseenSearchList = useAppSelector(
    (state) => state.sightseenSearch.SightseenSearchList
  );
  const SightseenSearchDetail = useAppSelector(
    (state) => state.sightseenSearch.SightseenSearchDetail
  );

  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const loading = useAppSelector((state) => state.sightseenSearch.loading);
  const CityList = useAppSelector((state) => state.city.CityList);
  const CountryList = useAppSelector((state) => state.country.CountryList);
  const VehicleTypeList = useAppSelector(
    (state) => state.vehicleType.VehicleTypeList
  );
  const NationalityList = useAppSelector(
    (state) => state.nationality.NationalityList
  );
  const AddtoCartSightseenDetails = useAppSelector(
    (state) => state.sightseenSearch.AddtoCartSightseenDetails
  );

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [SightseenSearchDetail]);

  useEffect(() => {
    dispatch(getAllActiveCities());
    dispatch(getAllActiveCountries());
    dispatch(getAllActiveVehicleTypes());
    dispatch(getAllActiveNationalities());
  }, []);

  useEffect(() => {
    setInitialValues({
      VehicleTypeID:
        SightseenSearchDetail?.VehicleTypeID && SightseenSearchDetail.CityID
          ? SightseenSearchDetail?.VehicleTypeID
          : location.state?.VehicleTypeID,
      CountryID:
        SightseenSearchDetail?.CountryID && SightseenSearchDetail.CityID
          ? SightseenSearchDetail?.CountryID
          : location.state?.CountryID,
      CityID:
        SightseenSearchDetail?.CityID && SightseenSearchDetail.CityID
          ? SightseenSearchDetail?.CityID
          : location.state?.CityID,
      NoOfAdult:
        SightseenSearchDetail?.NoOfAdult && SightseenSearchDetail.CityID
          ? SightseenSearchDetail?.NoOfAdult.toString()
          : location.state?.NoOfAdult
          ? location.state?.NoOfAdult.toString()
          : "0",
      NoOfChild:
        SightseenSearchDetail?.NoOfChild && SightseenSearchDetail.CityID
          ? SightseenSearchDetail?.NoOfChild.toString()
          : location.state?.NoOfChild
          ? location.state?.NoOfChild.toString()
          : "0",
      NationalityID:
        SightseenSearchDetail?.NationalityID && SightseenSearchDetail.CityID
          ? SightseenSearchDetail?.NationalityID
          : location.state?.NationalityID,
      SightseenDate:
        SightseenSearchDetail?.SightseenDate && SightseenSearchDetail.CityID
          ? moment(SightseenSearchDetail?.SightseenDate).toDate()
          : location.state?.SightseenDate
          ? moment(location.state?.SightseenDate).toDate()
          : "",
    });

    if (SightseenSearchDetail.CityID) {
      const sightseenSearchData = {
        VehicleTypeID: SightseenSearchDetail?.VehicleTypeID,
        CountryID: SightseenSearchDetail?.CountryID,
        CityID: SightseenSearchDetail?.CityID,
        NoOfAdult: SightseenSearchDetail?.NoOfAdult
          ? +SightseenSearchDetail?.NoOfAdult
          : 0,
        NoOfChild: SightseenSearchDetail?.NoOfChild
          ? +SightseenSearchDetail?.NoOfChild
          : 0,
        NationalityID: SightseenSearchDetail?.NationalityID
          ? SightseenSearchDetail?.NationalityID
          : null,
        SightseenDate: SightseenSearchDetail?.SightseenDate
          ? moment(SightseenSearchDetail?.SightseenDate).format("YYYY-MM-DD")
          : "",
      };
      dispatch(getAllSightseenSearchs(sightseenSearchData));
    } else {
      const sightseenSearchData = {
        VehicleTypeID: location.state?.VehicleTypeID,
        CountryID: location.state?.CountryID,
        CityID: location.state?.CityID,
        NoOfAdult: location.state?.NoOfAdult ? +location.state?.NoOfAdult : 0,
        NoOfChild: location.state?.NoOfChild ? +location.state?.NoOfChild : 0,
        NationalityID: location.state?.NationalityID,
        SightseenDate: location.state?.SightseenDate
          ? moment(location.state?.SightseenDate).format("YYYY-MM-DD")
          : "",
      };
      dispatch(getAllSightseenSearchs(sightseenSearchData));
    }
  }, [SightseenSearchDetail]);

  const handleCardShowTransferSearch = () => {
    setShowTransferSearch(!showTransferSearch);
  };

  const handleCart = (values: any) => {
    const addToCartDetails = {
      SightseenID: values?.ID,
      AdultRate: values?.AdultRate,
      ChildRate: values?.ChildRate,
      TotalRate: values?.TotalRate,
    };
    dispatch(openDialog("addToCartSightseenSearch"));
    dispatch(setAddToCartSightseenDetails(addToCartDetails));
  };

  const onDialogClose = () => {
    dispatch(closeDialog());
    dispatch(clearAddToCartSightseenDetails());
  };
  const handleAddToCart = async (values: any) => {
    const payload = {
      SightseenID: AddtoCartSightseenDetails?.SightseenID,
      AdultRate: AddtoCartSightseenDetails?.AdultRate,
      ChildRate: AddtoCartSightseenDetails?.ChildRate,
      TotalRate: AddtoCartSightseenDetails?.TotalRate,
      Remarks: values?.Remarks,
    };
    const response = await dispatch(AddToCartSightseenSearch(payload));
    if (response.meta?.requestStatus === "fulfilled") {
      dispatch(closeDialog());
      dispatch(clearAddToCartSightseenDetails());
    }
  };

  const handleSubmit = async (values: any) => {
    const payload = {
      VehicleTypeID: values.VehicleTypeID ? +values.VehicleTypeID : null,
      CountryID: values.CountryID ? +values.CountryID : null,
      CityID: values.CityID ? +values.CityID : null,
      NoOfAdult: values.NoOfAdult ? values.NoOfAdult.toString() : "0",
      NoOfChild: values.NoOfChild ? values.NoOfChild.toString() : "0",
      NationalityID: values.NationalityID ? +values.NationalityID : null,
      SightseenDate: values.SightseenDate
        ? moment(values.SightseenDate).toDate()
        : "",
    };
    dispatch(setSightseenSearchDetails(payload));
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <div>
      <div>
        <p style={{ textAlign: "right", position: "relative" }}>
          <RippleButton
            type="button"
            fillMode="outline"
            themeColor="primary"
            style={{ marginRight: 4 }}
            onClick={() => handleCardShowTransferSearch()}
          >
            Modify Search
          </RippleButton>
        </p>
      </div>
      {showTransferSearch && (
        <div>
          <Form
            key={formKey}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement>
                <ShadowCard>
                  <GridLayout
                    style={{ marginRight: 30 }}
                    gap={{ rows: 0, cols: 10 }}
                    cols={[
                      { width: "25%" },
                      { width: "25%" },
                      { width: "25%" },
                      { width: "25%" },
                    ]}
                  >
                    <GridLayoutItem colSpan={4}>
                      <Typography.h4>{"Sightseen Search"}</Typography.h4>
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`SightseenDate`}
                        label="Tour Date"
                        format="dd/MM/yyyy"
                        component={FormDatePicker}
                        validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        id={"VehicleTypeID"}
                        name={"VehicleTypeID"}
                        label={"Vehicle Type"}
                        component={FormSelectionField}
                        validator={requiredValidator}
                        options={VehicleTypeList?.map(
                          (vehicle: IVehicleType) => {
                            return {
                              value: vehicle?.ID,
                              label: vehicle?.VehicleType,
                            };
                          }
                        )}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        id={"NoOfAdult"}
                        name={"NoOfAdult"}
                        label={"Adult"}
                        component={FormSelectionField}
                        validator={requiredValidator}
                        options={NOOFADULTS.map((adult: any) => {
                          return {
                            value: adult.value,
                            label: adult.label,
                          };
                        })}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        id={"NoOfChild"}
                        name={"NoOfChild"}
                        label={"Child"}
                        component={FormSelectionField}
                        options={NOOFCHILD.map((child: any) => {
                          return {
                            value: child.value,
                            label: child.label,
                          };
                        })}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        id={"CityID"}
                        name={"CityID"}
                        label={"City"}
                        component={FormSelectionField}
                        validator={requiredValidator}
                        options={CityList?.map((city: ICity) => {
                          return {
                            value: city?.ID,
                            label: city?.CityName,
                          };
                        })}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        id={"CountryID"}
                        name={"CountryID"}
                        label={"Country"}
                        component={FormSelectionField}
                        validator={requiredValidator}
                        options={CountryList?.map((country: ICountry) => {
                          return {
                            value: country?.ID,
                            label: country?.CountryName,
                          };
                        })}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        id={"NationalityID"}
                        name={"NationalityID"}
                        label={"Nationality"}
                        component={FormSelectionField}
                        validator={requiredValidator}
                        options={NationalityList?.map(
                          (nationality: INationality) => {
                            return {
                              value: nationality?.ID,
                              label: nationality?.Nationality,
                            };
                          }
                        )}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem style={{ position: "relative" }}>
                      <div style={{ position: "absolute", top: 34, right: 0 }}>
                        <Button
                          type="button"
                          fillMode={"outline"}
                          themeColor={"primary"}
                          style={{ marginRight: 4 }}
                          onClick={handleCardShowTransferSearch}
                        >
                          Cancel
                        </Button>
                        <ButtonWithLoading
                          label={"Search"}
                          type="submit"
                          disabled={!formRenderProps.allowSubmit || loading}
                          loading={loading}
                        />
                      </div>
                    </GridLayoutItem>
                  </GridLayout>
                </ShadowCard>
              </FormElement>
            )}
          />
        </div>
      )}
      {dialogName === "addToCartSightseenSearch" && (
        <AppDialog>
          <Form
            onSubmit={handleAddToCart}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "280px" }}>
                <Typography.h5 style={{ margin: 0, textAlign: "center" }}>
                  {"Add to Cart"}
                </Typography.h5>
                <GridLayout>
                  <GridLayoutItem>
                    <Field
                      name={`Remarks`}
                      label="Remarks"
                      component={FormTextArea}
                      row={4}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      height: "50px",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                      gap: "5px",
                    }}
                  >
                    <Button
                      fillMode={"outline"}
                      themeColor={"primary"}
                      type="submit"
                      onClick={onDialogClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      fillMode={"solid"}
                      themeColor={"primary"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit}
                    >
                      Add To Cart
                    </Button>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            )}
          />
        </AppDialog>
      )}
      {SightseenSearchList &&
        SightseenSearchList.length > 0 &&
        SightseenSearchList.map((sightseensearch) => (
          <div style={{ margin: "10px 0" }}>
            <ShadowCard
              orientation="horizontal"
              style={{
                padding: "5px",
              }}
            >
              <CardImage
                src="https://www.gitaa.in/img/NoRecordFound.png"
                style={{
                  height: "100px",
                  width: "100px",
                  background: "whitesmoke",
                  borderRadius: "5px",
                }}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CardBody
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "90%",
                    }}
                  >
                    <table className="CardTable" cellSpacing={0}>
                      <tr>
                        <th>Sightseen Name</th>
                        <th>Sightseen Type</th>
                        <th>Vehicle Type</th>
                        <th>Adult Rate</th>
                        <th>Child Rate</th>
                      </tr>
                      <tr>
                        <td>{sightseensearch?.SightseenName}</td>
                        <td>{sightseensearch?.SightseenType}</td>
                        <td>{sightseensearch?.VehicleType}</td>
                        <td>{sightseensearch?.AdultRate}</td>
                        <td>{sightseensearch?.ChildRate}</td>
                      </tr>
                    </table>
                  </div>
                  <div style={{ width: "70px" }}>
                    <ButtonWithLoading
                      label={
                        <>
                          <p>Add Cart</p>
                          <MdShoppingCart style={{ fontSize: "20px" }} />
                        </>
                      }
                      onClick={() => handleCart(sightseensearch)}
                    />
                  </div>
                </CardBody>
              </div>
            </ShadowCard>
          </div>
        ))}
      <div style={{ textAlign: "right", margin: "20px 10px" }}>
        <Button
          type="button"
          fillMode={"outline"}
          themeColor={"primary"}
          style={{ marginRight: 4 }}
          onClick={() => navigate("/sightseensearch")}
        >
          Previous
        </Button>
        <RippleButton onClick={() => navigate("/sightseencart")}>
          Proceed Booking
        </RippleButton>
      </div>
    </div>
  );
};

export default SightseenSearchList;
