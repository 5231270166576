import React, { useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog } from "../../components/dialog/dialogSlice";
import { LoadingPanel } from "../../components/layout/Loading";
import AppDialog from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import moment from "moment";
import {
  insertTourQuotation,
  updateTourQuotation,
} from "./services/planner.services";
import { useLocation, useNavigate } from "react-router-dom";

const CreatePlannerQuotation: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const gridRef = useRef<any>(null);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const loading = useAppSelector((state) => state.planner.loading);
  const PlannerList = useAppSelector((state) => state.planner.PlannerList);
  const GuestQuotationDetails = useAppSelector(
    (state) => state.planner.GuestQuotationDetails
  );
  const TourQuotationID = location.state?.TourQuotationID;

  const handleSubmit = async (values: any) => {
    try {
      if (TourQuotationID) {
        const payload = {
          ID: TourQuotationID,
          FirstName: values.FirstName ? values.FirstName : "",
          LastName: values.LastName ? values.LastName : "",
          MobileNo: values.MobileNo ? values.MobileNo : "",
          EmailID: values.EmailID ? values.EmailID : "",
          QuotationName: values.QuotationName ? values.QuotationName : "",
          CreatedBy: values.CreatedBy ? values.CreatedBy : "",
          TourQuotationList: PlannerList
            ? PlannerList.map((quotation: any) => {
                return {
                  DayNo: quotation.DayNo ? +quotation.DayNo : null,
                  HotelList: quotation.HotelList
                    ? quotation.HotelList.map((hotel: any) => {
                        const rate =
                          +hotel?.PPRate + +hotel?.CNBRate + +hotel?.CWBRate;
                        return {
                          HotelID: hotel.ID ? +hotel.ID : null,
                          Date: quotation.Date
                            ? moment(quotation.Date).format("YYYY-MM-DD")
                            : "",
                          DayNo: hotel.DayNo ? +hotel.DayNo : null,
                          Rate: rate ? +rate : null,
                        };
                      })
                    : [],
                  TransferList: quotation.TransferList
                    ? quotation.TransferList.map((transfer: any) => {
                        return {
                          TransferID: transfer.ID ? +transfer.ID : null,
                          Date: quotation.Date
                            ? moment(quotation.Date).format("YYYY-MM-DD")
                            : "",
                          DayNo: transfer.DayNo ? +transfer.DayNo : null,
                          Rate: transfer.Rate ? +transfer.Rate : null,
                        };
                      })
                    : [],
                  SightseenList: quotation.SightseenList
                    ? quotation.SightseenList.map((sightseen: any) => {
                        return {
                          SightseenID: sightseen.ID ? +sightseen.ID : null,
                          Date: quotation.Date
                            ? moment(quotation.Date).format("YYYY-MM-DD")
                            : "",
                          DayNo: sightseen.DayNo ? +sightseen.DayNo : null,
                          Rate: sightseen.Rate ? +sightseen.Rate : null,
                        };
                      })
                    : [],
                  MealList: quotation.MealList
                    ? quotation.MealList.map((meal: any) => {
                        return {
                          MealID: meal.ID ? +meal.ID : null,
                          Date: quotation.Date
                            ? moment(quotation.Date).format("YYYY-MM-DD")
                            : "",
                          DayNo: meal.DayNo ? +meal.DayNo : null,
                          Rate: meal.Rate ? +meal.Rate : null,
                        };
                      })
                    : [],
                };
              })
            : [],
        };
        const response = await dispatch(updateTourQuotation(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closeDialog());
          navigate("/plannerquotation");
        }
      } else {
        const payload = {
          FirstName: values.FirstName ? values.FirstName : "",
          LastName: values.LastName ? values.LastName : "",
          MobileNo: values.MobileNo ? values.MobileNo : "",
          EmailID: values.EmailID ? values.EmailID : "",
          QuotationName: values.QuotationName ? values.QuotationName : "",
          CreatedBy: values.CreatedBy ? values.CreatedBy : "",
          TourQuotationList: PlannerList
            ? PlannerList.map((quotation: any) => {
                return {
                  DayNo: quotation.DayNo ? +quotation.DayNo : null,
                  HotelList: quotation.HotelList
                    ? quotation.HotelList.map((hotel: any) => {
                        const rate =
                          +hotel?.PPRate + +hotel?.CNBRate + +hotel?.CWBRate;
                        return {
                          HotelID: hotel.ID ? +hotel.ID : null,
                          Date: quotation.Date
                            ? moment(quotation.Date).format("YYYY-MM-DD")
                            : "",
                          DayNo: hotel.DayNo ? +hotel.DayNo : null,
                          Rate: rate ? +rate : null,
                        };
                      })
                    : [],
                  TransferList: quotation.TransferList
                    ? quotation.TransferList.map((transfer: any) => {
                        return {
                          TransferID: transfer.ID ? +transfer.ID : null,
                          Date: quotation.Date
                            ? moment(quotation.Date).format("YYYY-MM-DD")
                            : "",
                          DayNo: transfer.DayNo ? +transfer.DayNo : null,
                          Rate: transfer.Rate ? +transfer.Rate : null,
                        };
                      })
                    : [],
                  SightseenList: quotation.SightseenList
                    ? quotation.SightseenList.map((sightseen: any) => {
                        return {
                          SightseenID: sightseen.ID ? +sightseen.ID : null,
                          Date: quotation.Date
                            ? moment(quotation.Date).format("YYYY-MM-DD")
                            : "",
                          DayNo: sightseen.DayNo ? +sightseen.DayNo : null,
                          Rate: sightseen.Rate ? +sightseen.Rate : null,
                        };
                      })
                    : [],
                  MealList: quotation.MealList
                    ? quotation.MealList.map((meal: any) => {
                        return {
                          MealID: meal.ID ? +meal.ID : null,
                          Date: quotation.Date
                            ? moment(quotation.Date).format("YYYY-MM-DD")
                            : "",
                          DayNo: meal.DayNo ? +meal.DayNo : null,
                          Rate: meal.Rate ? +meal.Rate : null,
                        };
                      })
                    : [],
                };
              })
            : [],
        };
        const response = await dispatch(insertTourQuotation(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closeDialog());
          navigate("/plannerquotation");
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const dialogCloseHandle = () => {
    dispatch(closeDialog());
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {dialogName === "plannerQuotationForm" && (
        <AppDialog>
          <div>
            <Typography.h4 style={{ margin: "0px" }}>
              Make Quotation
            </Typography.h4>
            <Form
              onSubmit={handleSubmit}
              initialValues={GuestQuotationDetails}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement style={{ width: "400px" }}>
                  <GridLayout
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                    gap={{ rows: 10 }}
                    cols={[{ width: "100%" }]}
                  >
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        name="FirstName"
                        label="First Name"
                        component={FormTextField}
                        validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        name="LastName"
                        label="Last Name"
                        component={FormTextField}
                        validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        name="MobileNo"
                        label="Mobile No"
                        type="number"
                        max={10}
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        name="EmailID"
                        label="Email ID"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        name="QuotationName"
                        label="Quotation Name"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        name="CreatedBy"
                        label="Book By"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem
                      colSpan={2}
                      style={{
                        textAlign: "end",
                        marginTop: "20px",
                        width: "100%",
                      }}
                    >
                      <Button
                        type="button"
                        fillMode={"outline"}
                        themeColor={"primary"}
                        style={{ marginRight: 4 }}
                        onClick={dialogCloseHandle}
                      >
                        Cancel
                      </Button>
                      <ButtonWithLoading
                        label="Save"
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </GridLayoutItem>
                  </GridLayout>
                </FormElement>
              )}
            />
          </div>
        </AppDialog>
      )}
    </>
  );
};

export default CreatePlannerQuotation;
