import { Card } from "@progress/kendo-react-layout";
import React from "react";

interface DashBoardCardProps {
  title: string;
  count: any;
  background?: string;
  icon: React.ReactNode;
  onClick?: () => void;
}

const DashBoardCard: React.FC<DashBoardCardProps> = ({
  title,
  count = 0,
  background = "linear-gradient(to left, rgb(217, 162, 60), rgb(255 202 81), rgb(254 210 102))",
  icon,
  onClick,
}) => {
  return (
    <Card
      style={{
        border: "none",
        padding: 0,
        borderRadius: 20,
        boxShadow: "5px 5px 5px gray",
      }}
    >
      <div
        className="dashboard-box"
        onClick={onClick}
        style={{
          background: background,
        }}
      >
        <div
          style={{
            position: "absolute",
            height: 140,
            width: 140,
            top: 79,
            right: 50,
            borderRadius: "50%",
            boxShadow: " inset 0 0 20px rgba(255,255,255,0.3)",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            height: 170,
            width: 170,
            top: -15,
            right: -33,
            borderRadius: "50%",
            boxShadow: " inset 0 0 30px rgba(255,255,255,0.5)",
          }}
        ></div>
        <div className="d-flex flex-column align-items-center">
          <h2>{count}</h2>
          <span>{title}</span>
        </div>
        <div className="d-flex align-items-center">
          {icon}
        </div>
      </div>
    </Card>
  );
};

export default DashBoardCard;
