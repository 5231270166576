import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ISightseenType } from "../sightseenTypeModel";

export const getAllSightseenTypes = createAsyncThunk(
  "SightseenType/FindAllSightseenType",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SightseenType/FindAllSightseenType`,
        {
          SightseenType: "",
        }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Sightseen Types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createSightseenType = createAsyncThunk(
  "SightseenType/InsertSightseenType",
  async (SightseenTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SightseenType/InsertSightseenType`,
        SightseenTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating sightseen type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateSightseenType = createAsyncThunk(
  "SightseenType/UpdateSightseenType",
  async (SightseenTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SightseenType/UpdateSightseenType`,
        SightseenTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating sightseen type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteSightseenType = createAsyncThunk(
  "SightseenType/DeleteSightseenType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SightseenType/DeleteSightseenType`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting sightseen type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSightseenTypeByID = createAsyncThunk(
  "SightseenType/FindByIDSightseenType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SightseenType/FindByIDSightseenType`,
        { ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        SightseenType: result.SightseenType,
        IsActive: result.IsActive,
      } as ISightseenType;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching sightseen type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveSightseenTypes = createAsyncThunk(
  "SightseenType/FindAllActiveSightseenType",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/SightseenType/FindAllActiveSightseenType`
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching sightseen types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveSightseenType = createAsyncThunk(
  "SightseenType/ActiveInActiveSightseenType",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SightseenType/ActiveInActiveSightseenType`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive sightseen type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
