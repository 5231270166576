import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import RippleButton from "../../../components/common/RippleButton";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { LoadingPanel } from "../../../components/layout/Loading";
import {
  createTourItineraryArray,
  getTourItineraryListByTourID,
} from "../services/tour.services";
import {
  clearTourPlaceDetails,
  decreaseActiveStep,
  increaseActiveStep,
} from "../tourSlice";
import { closeDialog } from "../../../components/dialog/dialogSlice";
import { getAllActiveHotels } from "../../hotel/services/hotel.services";
import ShadowCard from "../../../components/common/ShadowCard";
import TourItineraryArray from "./TourItineraryArray";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const ItineraryChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const NoOfDays = location.state?.NoOfDays;
  const TourItineraryList = useAppSelector(
    (state: RootState) => state.tour.TourItineraryList
  );

  useEffect(() => {
    if (TourItineraryList.length <= 0) {
      let itineraryArray = [];
      for (let i = 0; i < NoOfDays; i++) {
        itineraryArray.push({
          Title: "",
          Description: "",
        });
      }
      const itineraryCopy = JSON.parse(JSON.stringify(itineraryArray));
      formRenderProps.onChange("TourItineraryList", {
        value: itineraryCopy || [],
      });
    } else if (TourItineraryList.length > NoOfDays) {
      const itineraryCopy = JSON.parse(JSON.stringify(TourItineraryList));
      const diff = TourItineraryList.length - NoOfDays;
      itineraryCopy.splice(itineraryCopy.length - diff, diff);
      formRenderProps.onChange("TourItineraryList", {
        value: itineraryCopy || [],
      });
    } else if (TourItineraryList.length < NoOfDays) {
      const diff = NoOfDays - TourItineraryList.length;
      const itineraryCopy = [...TourItineraryList];
      itineraryCopy.push(
        ...Array(diff).fill({
          Title: "",
          Description: "",
        })
      );
      const newItineraryArray = JSON.parse(JSON.stringify(itineraryCopy));
      formRenderProps.onChange("TourItineraryList", {
        value: newItineraryArray || [],
      });
    } else {
      const itineraryCopy = JSON.parse(JSON.stringify(TourItineraryList));
      formRenderProps.onChange("TourItineraryList", {
        value: itineraryCopy || [],
      });
    }
  }, [TourItineraryList]);

  return null;
};

const TourItinerary: React.FC = () => {
  const location = useLocation();
  const TourID = location.state?.TourID;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state: RootState) => state.tour.loading);

  useEffect(() => {
    dispatch(getAllActiveHotels());
    if (TourID) {
      dispatch(getTourItineraryListByTourID(TourID));
    }

    return () => {
      dispatch(closeDialog());
      dispatch(clearTourPlaceDetails());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    if (TourID) {
      const insertPayload = {
        TourItineraryList: values.TourItineraryList
          ? values.TourItineraryList.map((itinerary: any, index: number) => {
              return {
                DayNo: +index + 1,
                Title: itinerary.Title ? itinerary.Title : "",
                Description: itinerary.Description ? itinerary.Description : "",
              };
            })
          : [],
        TourID: TourID,
      };
      const response = await dispatch(createTourItineraryArray(insertPayload));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(increaseActiveStep());
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ItineraryChangeWatcher formRenderProps={formRenderProps} />
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem style={{ marginTop: 10 }} colSpan={3}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={TourItineraryArray}
                    name="TourItineraryList"
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <RippleButton
                    type="button"
                    fillMode="outline"
                    themeColor="primary"
                    style={{ marginRight: 4 }}
                    onClick={() => dispatch(decreaseActiveStep())}
                  >
                    Previous
                  </RippleButton>
                  <ButtonWithLoading
                    label={"Save & Next"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default TourItinerary;
