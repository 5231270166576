import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React, { useEffect, useState } from "react";
import ShadowCard from "../../components/common/ShadowCard";
import FormRadioGroup from "../../components/formFields/FormRadioGroup";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { Typography } from "@progress/kendo-react-common";
import MealDetailsArray from "./MealDetailsArray";
import moment from "moment";
import { Button } from "@progress/kendo-react-buttons";
import { ISector } from "../sector/sectorModel";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getAllActiveSectors } from "../sector/services/sector.services";
import { getMealRateByID } from "../meals/services/meals.services";
import { clearMealRateList } from "../meals/mealsSlice";
import { MdCurrencyRupee } from "react-icons/md";
import RippleButton from "../../components/common/RippleButton";
import { useLocation, useNavigate } from "react-router-dom";
import { Checkbox } from "antd";
import { insertPlannerItem } from "../planner/plannerSlice";

interface IOption {
  label: string;
  value: string | number;
}

export const MEALTYPE_OPTIONS: Array<IOption> = [
  { label: "Both", value: 1 },
  { label: "Lunch", value: 2 },
  { label: "Dinner", value: 3 },
];

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const SectorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const SectorID = JSON.parse(sessionStorage.getItem("SectorID") as any);

  if (SectorID) {
    useEffect(() => {
      formRenderProps.onChange("SectorID", {
        value: SectorID,
      });
    }, [SectorID]);
  }

  return null;
};

const MealChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const MealType = formRenderProps.valueGetter("MealType");
  const MealData = formRenderProps.valueGetter("MealData");
  const PassengerDetails = JSON.parse(
    sessionStorage.getItem("PassengerDetails") as any
  );

  if (MealType !== 1 && MealData && MealData[0]?.MoreOption) {
    useEffect(() => {
      const mealPayload = {
        MealID:
          MealData && MealData[0]?.MoreOption
            ? MealData[0]?.MoreOption.toString()
            : "",
        LunchORDinner: MealData?.map((item: any) => item?.MealTime).join(","),
        PassengerDetails: PassengerDetails,
      };
      dispatch(getMealRateByID(mealPayload));
    }, [MealData?.map((item: any) => item.MoreOption).join("-")]);
  } else if (
    MealType === 1 &&
    MealData &&
    MealData[0]?.MoreOption &&
    MealData[1]?.MoreOption
  ) {
    useEffect(() => {
      const mealPayload = {
        MealID: MealData?.map((item: any) => item.MoreOption).join(","),
        LunchORDinner: MealData?.map((item: any) => item?.MealTime).join(","),
        PassengerDetails: PassengerDetails,
      };
      dispatch(getMealRateByID(mealPayload));
    }, [MealData?.map((item: any) => item.MoreOption).join("-")]);
  }

  return null;
};

const MealDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const DayNo = location.state?.DayNo;
  const [checkedMeals, setCheckedMeals] = useState<boolean>(false);
  const SectorList = useAppSelector((state) => state.sector.SectorList);
  const MealDetail = useAppSelector(
    (state) => state.toursAndTransfers.MealDetail
  );
  const MealRateList = useAppSelector((state) => state.meals.MealRateList);
  console.log("MealRateList", MealRateList);
  const [mealDetailShow, setMealDetailShow] = React.useState("false");
  let today = moment().format("DD-MM-YYYY");

  useEffect(() => {
    dispatch(getAllActiveSectors());
  }, []);

  const handleSearch = (formRenderProps: FormRenderProps) => {
    const MealType = formRenderProps.valueGetter("MealType");
    if (MealType === 1) {
      setMealDetailShow("true");
      formRenderProps.onChange("MealData", {
        value: [{ MealTime: 1 }, { MealTime: 2 }],
      });
      dispatch(clearMealRateList());
    } else {
      setMealDetailShow("true");
      formRenderProps.onChange("MealData", {
        value: [{ MealTime: MealType === 2 ? 1 : 2 }],
      });
      dispatch(clearMealRateList());
    }
  };

  const onChange = () => {
    setCheckedMeals(!checkedMeals);
  };

  const handleAddtoPlanner = () => {
    const selectedMeal = MealRateList?.map((e: any) => ({
      DayNo: DayNo,
      ID: e?.ID,
      LunchORDinner: e?.MealType,
      MealID: e?.ID,
      MealName: e?.MealName,
      MealType: e?.Title,
      MealTypeID: e?.MealCategoryID,
      Rate: e?.Rate,
    }));
    dispatch(
      insertPlannerItem({
        dataItem: selectedMeal,
        arrayName: "MealList",
        plannerRowID: DayNo,
      })
    );
    navigate("/planner");
  };

  return (
    <>
      <Form
        //   onSubmit={handleSubmit}
        initialValues={MealDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement style={{ width: "100%" }}>
            <GridLayout gap={{ rows: 0, cols: 10 }} cols={[{ width: "100%" }]}>
              <GridLayoutItem>
                <ShadowCard>
                  <GridLayout cols={[{ width: "100%" }]}>
                    <GridLayoutItem>
                      <GridLayout
                        style={{ marginRight: 10 }}
                        gap={{ rows: 10, cols: 10 }}
                        cols={[
                          { width: "70%" },
                          { width: "20%" },
                          { width: "10%" },
                        ]}
                      >
                        <GridLayoutItem
                          className="radiogroup-alighment"
                          style={{ display: "flex", alignItems: "end" }}
                        >
                          <Field
                            className="radiogroup"
                            id={"MealType"}
                            name={"MealType"}
                            // label={"Gender"}
                            layout={"horizontal"}
                            component={FormRadioGroup}
                            data={MEALTYPE_OPTIONS}
                          />
                        </GridLayoutItem>
                        <GridLayoutItem style={{ width: "100%" }}>
                          <Field
                            id={"SectorID"}
                            name={"SectorID"}
                            label={"Destination"}
                            wrapperStyle={{ margin: "0" }}
                            disabled={true}
                            component={FormSelectionField}
                            options={SectorList?.map((sector: ISector) => {
                              return {
                                value: sector?.ID,
                                label: sector?.SectorName,
                              };
                            })}
                          />
                        </GridLayoutItem>
                        <GridLayoutItem></GridLayoutItem>
                        <GridLayoutItem
                          colSpan={3}
                          style={{
                            textAlign: "center",
                            marginTop: 20,
                          }}
                        >
                          <div>
                            <Button
                              type="button"
                              themeColor={"primary"}
                              onClick={() => handleSearch(formRenderProps)}
                            >
                              Search
                            </Button>
                          </div>
                        </GridLayoutItem>
                      </GridLayout>
                    </GridLayoutItem>
                  </GridLayout>
                  <MealChangeWatcher formRenderProps={formRenderProps} />
                  <SectorChangeWatcher formRenderProps={formRenderProps} />
                </ShadowCard>
              </GridLayoutItem>
              {mealDetailShow === "true" && (
                <GridLayoutItem
                  style={{
                    marginTop: 20,
                  }}
                >
                  <Typography.h4>Meal Details</Typography.h4>
                </GridLayoutItem>
              )}
              {mealDetailShow === "true" && (
                <GridLayoutItem>
                  <ShadowCard style={{ padding: 12, marginTop: 10 }}>
                    <GridLayout
                      style={{ marginRight: 30 }}
                      gap={{ rows: 10, cols: 10 }}
                      cols={[
                        { width: "5%" },
                        { width: "15%" },
                        { width: "65%" },
                        { width: "15%" },
                      ]}
                    >
                      <GridLayoutItem
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Checkbox onChange={() => onChange()}></Checkbox>
                      </GridLayoutItem>
                      <GridLayoutItem
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span>{today}</span>
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <FieldArray
                          formRenderProps={formRenderProps}
                          component={MealDetailsArray}
                          name="MealData"
                        />
                      </GridLayoutItem>
                      {MealRateList && MealRateList?.length > 0 && (
                        <GridLayoutItem
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "end",
                            justifyContent: "space-evenly",
                          }}
                        >
                          {MealRateList?.map((item: any) => (
                            <span style={{ height: 84, position: "relative" }}>
                              <b
                                style={{
                                  position: "absolute",
                                  right: 0,
                                  top: 50,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <MdCurrencyRupee />
                                {item?.Rate}
                              </b>
                            </span>
                          ))}
                        </GridLayoutItem>
                      )}
                    </GridLayout>
                  </ShadowCard>
                </GridLayoutItem>
              )}
              {mealDetailShow === "true" && (
                <GridLayoutItem
                  style={{
                    marginTop: 20,
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <div>
                    <RippleButton
                      style={{ marginTop: 5 }}
                      onClick={() => handleAddtoPlanner()}
                      disabled={checkedMeals !== true}
                    >
                      Add to Planner
                    </RippleButton>
                  </div>
                </GridLayoutItem>
              )}
            </GridLayout>
          </FormElement>
        )}
      />
    </>
  );
};

export default MealDetails;
