import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast } from "../../../components/toast/Toasts";
import { IPackageDetail } from "../readymadePackageModel";

export const getAllReadymadeTourPackages = createAsyncThunk(
  "Tour/FindAllActiveTourPackages",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/FindAllActiveTourPackages`,
        formdata
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Tour Packages:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourPackageDetails = createAsyncThunk(
  "Tour/TourpackagesDeatailsBYTourID?tourID",
  async (TourID: number) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Tour/TourpackagesDeatailsBYTourID?tourID=${TourID}`
      );
      return response.data?.Data as IPackageDetail;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour package details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
