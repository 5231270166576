import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  getAllFixedORCustomizedPackageByTour,
  getAllPlannerQuotation,
  getTourQuotationByID,
} from "./services/planner.services";
import { IPlanner, IPlannerState } from "./plannerModel";

const initialState: IPlannerState = {
  loading: false,
  error: "",
  PlannerList: [],
  PlannerRowDetails: {},
  PlannerDeleteDetails: [],
  QuotationDetails: {
    TourQuotationList: [],
  },
  GuestQuotationDetails: {
    FirstName: "",
    LastName: "",
    MobileNo: "",
    EmailID: "",
    QuoationName: "",
    CreatedBy: null,
  },
  QuotationList: [],
};

const plannerSlice = createSlice({
  name: "planner",
  initialState,
  reducers: {
    // clearPlannerDetail: (state) => {
    //   state.PlannerDetail = initialState.PlannerDetail;
    // },
    clearPlannerQuotationDetail: (state) => {
      state.QuotationDetails = initialState.QuotationDetails;
    },
    setPlannerRowDetails: (state, action) => {
      state.PlannerRowDetails = action.payload;
    },
    clearPlannerRowDetails: (state) => {
      state.PlannerRowDetails = initialState.PlannerRowDetails;
    },
    setPlannerDeleteDetails: (state, action) => {
      state.PlannerDeleteDetails = action.payload;
    },
    clearPlannerDeleteDetails: (state) => {
      state.PlannerDeleteDetails = initialState.PlannerDeleteDetails;
    },
    getPlannerData(state) {
      const plannerDetailsString = sessionStorage.getItem("PlannerList");
      if (plannerDetailsString !== null) {
        state.PlannerList = JSON.parse(plannerDetailsString);
      }
    },
    insertItemToAllPlannerRow(state, action) {
      const { dataItem, arrayName } = action.payload;
      state.PlannerList.forEach((planner, index) => {
        state.PlannerList[index] = {
          ...planner,
          [arrayName]: [...planner[arrayName], dataItem],
        };
      });
      sessionStorage.setItem("PlannerList", JSON.stringify(state.PlannerList));
    },
    deleteItemFromAllPlannerRow(state, action) {
      const { itemID, arrayName } = action.payload;
      state.PlannerList.forEach((planner, index) => {
        state.PlannerList[index] = {
          ...planner,
          [arrayName]: planner[arrayName].filter(
            (item: any) => item.ID !== itemID
          ),
        };
      });
      sessionStorage.setItem("PlannerList", JSON.stringify(state.PlannerList));
    },
    insertPlannerItem(state, action) {
      const { dataItem, arrayName, plannerRowID } = action.payload;
      state.PlannerList = state.PlannerList.map((item) => {
        if (item.DayNo === plannerRowID) {
          if (Array.isArray(item[arrayName])) {
            return {
              ...item,
              [arrayName]: [...item[arrayName], ...dataItem],
            };
          } else {
            return {
              ...item,
              [arrayName]: [...dataItem],
            };
          }
        }
        return item;
      });
      sessionStorage.setItem("PlannerList", JSON.stringify(state.PlannerList));
    },

    deletePlannerItem(state, action) {
      const { itemID, arrayName, plannerRowID } = action.payload;
      state.PlannerList = state.PlannerList.map((item) => {
        if (item?.DayNo === plannerRowID) {
          return {
            ...item,
            [arrayName]: item[arrayName].filter(
              (subItem: any) => subItem.ID !== itemID
            ),
          };
        }
        return item;
      });
      sessionStorage.setItem("PlannerList", JSON.stringify(state.PlannerList));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllFixedORCustomizedPackageByTour.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getAllFixedORCustomizedPackageByTour.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(
      getAllFixedORCustomizedPackageByTour.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      }
    );
    builder.addCase(getAllPlannerQuotation.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllPlannerQuotation.fulfilled,
      (state, action: PayloadAction<IPlanner[]>) => {
        state.loading = false;
        state.QuotationList = action.payload || [];
      }
    );
    builder.addCase(getAllPlannerQuotation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.QuotationList = [];
    });

    builder.addCase(getTourQuotationByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getTourQuotationByID.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.PlannerList = action.payload?.TourQuotationList;
        state.GuestQuotationDetails = action.payload;
      }
    );
    builder.addCase(getTourQuotationByID.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PlannerList = initialState.PlannerList;
      state.GuestQuotationDetails = initialState.GuestQuotationDetails;
    });
  },
});

export const {
  clearPlannerQuotationDetail,
  setPlannerRowDetails,
  clearPlannerRowDetails,
  setPlannerDeleteDetails,
  clearPlannerDeleteDetails,
  getPlannerData,
  insertItemToAllPlannerRow,
  deleteItemFromAllPlannerRow,
  deletePlannerItem,
  insertPlannerItem,
} = plannerSlice.actions;
export default plannerSlice.reducer;
