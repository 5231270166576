import { createSlice } from "@reduxjs/toolkit";
import { ICustomizeSearchState } from "./customizeSearchModel";

const initialState: ICustomizeSearchState = {
  loading: false,
  error: "",
  deleteIndex: -1,
  CustomizeSearchDetails: {
    SectorID: null,
    CityID: null,
    FromDate: "",
    ToDate: "",
    Nights: null,
    TourID: null,
    PackageID: null,
    PassengerDetails: [],
  },
};

const customizeSearchSlice = createSlice({
  name: "customizeSearch",
  initialState,
  reducers: {
    clearCustomizeSearchDetails: (state) => {
      state.CustomizeSearchDetails = initialState.CustomizeSearchDetails;
    },
    setCustomizeSearchDeleteIndex: (state, action) => {
      state.deleteIndex = action.payload;
    },
  },
  //   extraReducers: (builder) => {
  //   },
});

export const {
  clearCustomizeSearchDetails,
  setCustomizeSearchDeleteIndex,
} = customizeSearchSlice.actions;
export default customizeSearchSlice.reducer;
