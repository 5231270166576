import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ITransferLocation,
  ITransferLocationInitialState,
} from "./transferLocationModel";
import {
  ActiveInactiveTransferLocation,
  createTransferLocation,
  deleteTransferLocation,
  getAllActiveTransferLocation,
  getAllTransferLocation,
  getTransferLocationByID,
  getTransferLocationBySectorID,
  getTransferLocationsByCityID,
  updateTransferLocation,
} from "./services/transferLocation.services";

const initialState: ITransferLocationInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  TransferLocationList: [],
  CityTransferLocationList: [],
  TransferLocationID: null,
  TransferLocationDetail: {
    SectorID: 0,
    LocationName: "",
    IsActive: false,
  },
};

const transferLocationSlice = createSlice({
  name: "transferLocation",
  initialState,
  reducers: {
    clearTransferLocationDetails: (state) => {
      state.TransferLocationDetail = initialState.TransferLocationDetail;
    },
    setTransferLocationID: (state, action) => {
      state.TransferLocationID = action.payload;
    },
    clearTransferLocationID: (state) => {
      state.TransferLocationID = initialState.TransferLocationID;
    },
    clearCityTransferLocationList: (state) => {
      state.CityTransferLocationList = initialState.CityTransferLocationList;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTransferLocation.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllTransferLocation.fulfilled,
      (state, action: PayloadAction<ITransferLocation[]>) => {
        state.loading = false;
        state.TransferLocationList = action.payload || [];
      }
    );
    builder.addCase(getAllTransferLocation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TransferLocationList = [];
    });
    builder.addCase(createTransferLocation.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createTransferLocation.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createTransferLocation.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateTransferLocation.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateTransferLocation.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateTransferLocation.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteTransferLocation.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteTransferLocation.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTransferLocation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTransferLocationByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getTransferLocationByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.TransferLocationDetail = action.payload;
    });
    builder.addCase(getTransferLocationByID.rejected, (state, action) => {
      state.formLoading = false;
      state.TransferLocationDetail = initialState.TransferLocationDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveTransferLocation.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveTransferLocation.fulfilled,
      (state, action: PayloadAction<ITransferLocation[]>) => {
        state.loading = false;
        state.TransferLocationList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveTransferLocation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TransferLocationList = [];
    });
    builder.addCase(ActiveInactiveTransferLocation.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveTransferLocation.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(
      ActiveInactiveTransferLocation.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      }
    );

    builder.addCase(getTransferLocationBySectorID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getTransferLocationBySectorID.fulfilled,
      (state, action: PayloadAction<ITransferLocation[]>) => {
        state.loading = false;
        state.TransferLocationList = action.payload;
      }
    );
    builder.addCase(getTransferLocationBySectorID.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TransferLocationList = [];
    });
    builder.addCase(getTransferLocationsByCityID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getTransferLocationsByCityID.fulfilled,
      (state, action: PayloadAction<ITransferLocation[]>) => {
        state.loading = false;
        state.CityTransferLocationList = action.payload;
      }
    );
    builder.addCase(getTransferLocationsByCityID.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CityTransferLocationList = [];
    });
  },
});

export const {
  clearTransferLocationDetails,
  setTransferLocationID,
  clearTransferLocationID,
  clearCityTransferLocationList,
} = transferLocationSlice.actions;
export default transferLocationSlice.reducer;
