import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IRoomType } from "../roomTypeModel";

export const getAllRoomTypes = createAsyncThunk(
  "RoomType/FindAllRoomType",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RoomType/FindAllRoomType`,
        {
          RoomType: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Room Types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createRoomType = createAsyncThunk(
  "RoomType/InsertRoomType",
  async (RoomTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RoomType/InsertRoomType`,
        RoomTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating room type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateRoomType = createAsyncThunk(
  "RoomType/UpdateRoomType",
  async (RoomTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RoomType/UpdateRoomType`,
        RoomTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating room type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteRoomType = createAsyncThunk(
  "RoomType/DeleteRoomType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RoomType/DeleteRoomType`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting room type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getRoomTypeByID = createAsyncThunk(
  "RoomType/FindByIDRoomType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RoomType/FindByIDRoomType`,
        { ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        RoomType: result.RoomType,
        Prefix: result.Prefix,
        IsActive: result.IsActive,
      } as IRoomType;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching room type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveRoomTypes = createAsyncThunk(
  "RoomType/FindAllActiveRoomType",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/RoomType/FindAllActiveRoomType`
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching room types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveRoomType = createAsyncThunk(
  "RoomType/ActiveInActiveRoomType",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RoomType/ActiveInActiveRoomType`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive room type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
