export const GENDER = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

export const NAMEPREFIX = [
  { label: "Mr.", value: "Mr." },
  { label: "Mrs.", value: "Mrs." },
];

export const STATUSARRAY = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 2 },
];

export const SECTORTYPE = [
  { label: "DOMESTIC", value: "DOMESTIC" },
  { label: "INTERNATIONAL", value: "INTERNATIONAL" },
];

export const TRAVELTYPE = [
  { label: "TOUR", value: "TOUR" },
  { label: "PACKAGE", value: "PACKAGE" },
];

export const JOURNEYTYPE = [
  { label: "DEPARTURE", value: "DEPARTURE" },
  { label: "RETURN", value: "RETURN" },
];

export const RATETYPE = [
  { label: "PER PERSON", value: "PER PERSON" },
  { label: "PER COUPLE", value: "PER COUPLE" },
];

export const PACKAGETYPE = [
  { label: "PACKAGE 1", value: 1 },
  { label: "PACKAGE 2", value: 2 },
  { label: "PACKAGE 3", value: 3 },
];

export const TRANSFERTYPE = [
  { label: "One Way", value: 1 },
  { label: "Two Way", value: 2 },
];

export const SIGHTSEENTYPE = [
  { label: "Half Day", value: 1 },
  { label: "Full Day", value: 2 },
];

export const NOOFCHILD = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
];

export const NOOFADULTS = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
];

export const MEALTYPE = [
  { label: "LUNCH", value: 1 },
  { label: "DINNER", value: 2 },
];

export const STARRATING = [
  { label: "1 STAR", value: 1 },
  { label: "2 STAR", value: 2 },
  { label: "3 STAR", value: 3 },
  { label: "4 STAR", value: 4 },
  { label: "5 STAR", value: 5 },
];

export const BOOLEAN = [
  { label: "Yes", value: 1 },
  { label: "NO", value: 2 },
];

export type ISelectionType = {
  value: number;
  label: string;
};

export const INSERT_OPT_TYPE = 1;
export const EDIT_OPT_TYPE = 2;
export const DELETE_OPT_TYPE = 3;
export const GET_BY_GUID_OPT_TYPE = 4;
export const GET_ALL_ACTIVE_WITH_FILTER_OPT_TYPE = 5;
export const GET_ALL_OPT_TYPE = 6;
export const ACTIVE_INACTIVE_OPT_TYPE = 7;
