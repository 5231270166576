import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { FiEdit } from "react-icons/fi";
import { Switch } from "@progress/kendo-react-inputs";
import AlertBox from "../../components/common/AlertBox";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { MdDelete } from "react-icons/md";
import CreateUserType from "./CreateUserType";
import {
  ActiveInactiveUserType,
  deleteUserType,
  getAllUserTypes,
  getUserTypeByID,
} from "./services/userType.services";
import { clearUserTypeID, setUserTypeID } from "./userTypeSlice";

const UserType: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllUserTypes());
    return () => {
      dispatch(closeDialog());
    };
  }, []);

  const handleCreate = () => {
    dispatch(openDialog("userTypeForm"));
    dispatch(setUserTypeID(0));
  };

  return (
    <>
      <CreateUserType />
      <DeleteUserTypeDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
            
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            User Type List
          </Typography.h4>
          <Button
            onClick={handleCreate}
            fillMode={"solid"}
            themeColor={"primary"}
          >
            {"Create City"}
          </Button>
        </GridLayoutItem>
        <GridLayoutItem>
          <UserTypeGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const UserTypeGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();

  const UserTypeList = useAppSelector(
    (state: RootState) => state.userType.UserTypeList
  );
  const loading = useAppSelector((state: RootState) => state.userType.loading);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? UserTypeList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (ID: number) => {
      dispatch(openDialog("userTypeForm"));
      dispatch(getUserTypeByID(ID));
      dispatch(setUserTypeID(ID));
    };
    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteUserType"));
      dispatch(setUserTypeID(ID));
    };

    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEdit(props.dataItem.ID)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem.ID)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
    const [IsActive, setIsActive] = useState<boolean>(props.dataItem.IsActive);

    const handleActiveInActive = () => {
      setIsActive(!IsActive);
      const data = {
        ID: props.dataItem.ID,
        IsActive: !IsActive,
      };
      dispatch(ActiveInactiveUserType(data)).then((response: any) => {
        if (response.payload?.data?.Status === 200) {
          dispatch(getAllUserTypes());
        } else {
          dispatch(getAllUserTypes());
        }
      });
    };

    return (
      <td style={{ textAlign: "center" }}>
        <Switch
          name="IsActive"
          checked={IsActive}
          onChange={handleActiveInActive}
          size="small"
        />
      </td>
    );
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {UserTypeList && UserTypeList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(UserTypeList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : UserTypeList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={UserTypeList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column field="UserType" title="User Type " />
          <Column
            field="ID"
            title="Status"
            cell={MyActiveInActtiveCommandCell}
            width={"90px"}
            filterable={false}
            locked={true}
          />
          <Column
            field="ID"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteUserTypeDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const UserTypeID = useAppSelector(
    (state: RootState) => state.userType.UserTypeID
  );
  const dialogName = useAppSelector(
    (state: RootState) => state.dialog.dialogName
  );

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteUserType(ID));
      if (response.payload.Data.Status === 200) {
        dispatch(getAllUserTypes());
        dispatch(closeDialog());
        dispatch(clearUserTypeID());
      } else {
        dispatch(getAllUserTypes());
        dispatch(closeDialog());
        dispatch(clearUserTypeID());
      }
    }
  };
  return (
    <>
      {dialogName === "deleteUserType" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete User Type"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this User Type?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(UserTypeID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default UserType;
