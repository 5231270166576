import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  emailValidator,
  requiredValidator,
} from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { AddSightseenQuotation } from "./services/sightseenSearch.services";
import ShadowCard from "../../components/common/ShadowCard";
import { NAMEPREFIX } from "../../_contstants/common";
import FormTextArea from "../../components/formFields/FormTextArea";

const SightseenCustomerDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();

  const loading = useAppSelector((state) => state.sightseenSearch.loading);

  const handleSubmit = async (values: any) => {
    const payload = {
      Initial: values.Initial,
      FirstName: values.FirstName ? values.FirstName : "",
      LastName: values.LastName ? values.LastName : "",
      MobileNo: values.MobileNo ? values.MobileNo.toString() : "",
      AlternateMobileNo: values.AlternateMobileNo
        ? values.AlternateMobileNo.toString()
        : "",
      BookingConfirmedBy: values.BookingConfirmedBy
        ? values.BookingConfirmedBy
        : "",
      MailedQuotation: values.MailedQuotation ? values.MailedQuotation : "",
      Remarks: values.Remarks ? values.Remarks : "",
      IsActive: true,
    };
    const response = await dispatch(AddSightseenQuotation(payload));
    if (response.meta?.requestStatus === "fulfilled") {
      navigate("/plannerquotation");
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: "10px" }}>
              <GridLayoutItem colSpan={3}>
                <Typography.h5>Guest Details</Typography.h5>
              </GridLayoutItem>
              <GridLayout
                style={{ display: "flex" }}
                gap={{ rows: 0, cols: 10 }}
              >
                <GridLayoutItem style={{ width: "400px" }}>
                  <Field
                    name="Initial"
                    label="Initial"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={NAMEPREFIX.map((item: any) => {
                      return {
                        value: item.value,
                        label: item.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name="FirstName"
                    label="First Name"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name="LastName"
                    label="Last Name"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
              </GridLayout>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name="MobileNo"
                    label="Mobile No"
                    type="number"
                    max="16"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name="AlternateMobileNo"
                    label="Alternate Mobile No"
                    type="number"
                    max="16"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: "10px", marginTop: "10px" }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h5>Sightseen Details</Typography.h5>
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name="BookingConfirmedBy"
                    label="Booking Confirmed By"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name="MailedQuotation"
                    label="Send Quotation to Below Mail"
                    component={FormTextField}
                    validator={emailValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }} colSpan={2}>
                  <Field
                    name="Remarks"
                    label="Remarks"
                    component={FormTextArea}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    textAlign: "end",
                    marginTop: "10px",
                    width: "100%",
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                  }}
                >
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginRight: 4 }}
                    onClick={() => navigate("/sightseencart")}
                  >
                    Cancel
                  </Button>
                  <ButtonWithLoading
                    label="Save"
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default SightseenCustomerDetails;
