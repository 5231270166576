import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ITransfer, ITransferDetails } from "../transferModel";
import moment from "moment";

export const getAllTransfers = createAsyncThunk(
  "Transfer/FindAllTransfer",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Transfer/FindAllTransfer`,
        {
          TransferName: "",
        }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data as ITransfer[];
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Transfers:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveTransfers = createAsyncThunk(
  "Transfer/FindAllActiveTransfer",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Transfer/FindAllActiveTransfer`
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data as ITransfer[];
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Transfers:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createTransfer = createAsyncThunk(
  "Transfer/InsertTransfer",
  async (TransferData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Transfer/InsertTransfer`,
        TransferData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Transfer:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateTransfer = createAsyncThunk(
  "Transfer/UpdateTransfer",
  async (TransferData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Transfer/UpdateTransfer`,
        TransferData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Transfer:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const uploadTransferImage = createAsyncThunk(
  "Transfer/UploadImage",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Transfer/TrasferImageUpdate`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error uploading Transfer:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTransferByID = createAsyncThunk(
  "Transfer/FindByIDTransfer",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Transfer/FindByIDTransfer`,
        { ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        TransferName: result.TransferName,
        SectorID: result.SectorID,
        SectorName: result.SectorName,
        CityID: result.CityID,
        CityName: result.CityName,
        VehicleTypeID: result.VehicleTypeID,
        VehicleType: result.VehicleType,
        TransferImage: result.TransferImage,
        CountryCode: result.CountryCode,
        TermsAndConditionsID: result.TermsAndConditionsID,
        TermsAndConditions: result.TermsAndConditions,
        FromLocationID: result.FromLocationID,
        ToLocationID: result.ToLocationID,
        FromLocation: result.FromLocation,
        ToLocation: result.ToLocation,
        TrasferRateList: result.TrasferRateList
          ? result.TrasferRateList.map((rate: any) => {
              return {
                FromDate: rate.FromDate ? moment(rate.FromDate).toDate() : "",
                ToDate: rate.ToDate ? moment(rate.ToDate).toDate() : "",
                CurrencyID: rate.CurrencyID ? +rate.CurrencyID : null,
                OnewayRate: rate.OnewayRate ? +rate.OnewayRate : null,
                TwowayRate: rate.TwowayRate ? +rate.TwowayRate : null,
              };
            })
          : [],
        Pax: result.Pax,
        IsActive: result.IsActive ? 1 : 2,
      } as ITransferDetails;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Transfer:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const transferActiveInactive = createAsyncThunk(
  "Transfer/ActiveInActiveTransfer",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Transfer/ActiveInActiveTransfer`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Transfer:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteTransfer = createAsyncThunk(
  "Transfer/DeleteTransfer",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Transfer/DeleteTransfer`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Transfer:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
