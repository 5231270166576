export const generateDaysArray = (days: number) => {
  let result = [];
  for (let i = 1; i <= days; i++) {
    result.push({
      label: "Day " + i,
      value: i,
    });
  }
  return result;
};

