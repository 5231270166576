import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import {
  ISightseen,
  ISightseenCancellation,
  ISightseenRate,
} from "../sightseenModel";
import moment from "moment";

export const getAllSightseens = createAsyncThunk(
  "Sightseen/FindAllSightseen",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sightseen/FindAllSightseen`,
        {
          SightseenName: "",
        }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Sightseens:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createSightseen = createAsyncThunk(
  "Sightseen/InsertSightseen",
  async (SightseenData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sightseen/InsertSightseen`,
        SightseenData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating sightseen :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateSightseen = createAsyncThunk(
  "Sightseen/UpdateSightseen",
  async (SightseenData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sightseen/UpdateSightseen`,
        SightseenData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating sightseen :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteSightseen = createAsyncThunk(
  "Sightseen/DeleteSightseen",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sightseen/DeleteSightseen`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting sightseen :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSightseenByID = createAsyncThunk(
  "Sightseen/FindByIDSightseen",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sightseen/FindByIDSightseen`,
        { ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        SightseenName: result.SightseenName,
        SightseenTypeID: result.SightseenTypeID,
        Description: result.Description,
        VehicleTypeID: result.VehicleTypeID,
        Address: result.Address,
        CountryID: result.CountryID,
        CityID: result.CityID,
        StateID: result.StateID,
        SectorID: result.SectorID,
        TermsAndConditionsID: result.TermsAndConditionsID,
        SightseenTiming: result.SightseenTiming,
        IsActive: result.IsActive ? 1 : 2,
      } as ISightseen;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching sightseen :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllRateBySightseenID = createAsyncThunk(
  "SightseenRate/FindAllRateBySightseenID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SightseenRate/FindAllRateBySightseenID`,
        {
          SightseenID: ID,
        }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Sightseen Rates:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createSightseenRate = createAsyncThunk(
  "SightseenRate/InsertSightseenRate",
  async (SightseenRateData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SightseenRate/InsertSightseenRate`,
        SightseenRateData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating sightseen rate:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateSightseenRate = createAsyncThunk(
  "SightseenRate/UpdateSightseenRate",
  async (SightseenRateData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SightseenRate/UpdateSightseenRate`,
        SightseenRateData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating sightseen rate:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteSightseenRate = createAsyncThunk(
  "SightseenRate/DeleteSightseenRate",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SightseenRate/DeleteSightseenRate`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting sightseen rate:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSightseenRateByID = createAsyncThunk(
  "SightseenRate/FindByIDSightseenRate",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SightseenRate/FindByIDSightseenRate`,
        { ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        SightseenID: result.SightseenID,
        NationalityID: result.NationalityID,
        FromDate: result.FromDate ? moment(result.FromDate).toDate() : "",
        ToDate: result.ToDate ? moment(result.ToDate).toDate() : "",
        AdultRate: result.AdultRate,
        ChildRate: result.ChildRate,
        IsActive: result.IsActive,
      } as ISightseenRate;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching sightseen :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllCancellationBySightseenID = createAsyncThunk(
  "SightseenCancellation/FindAllCancellationBySightseenID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SightseenCancellation/FindAllCancellationBySightseenID`,
        {
          SightseenID: ID,
        }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Canceling Sightseen:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createSightseenCancellation = createAsyncThunk(
  "SightseenCancellation/InsertSightseenCancellation",
  async (SightseenCancellationData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SightseenCancellation/InsertSightseenCancellation`,
        SightseenCancellationData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating sightseen cancellation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateSightseenCancellation = createAsyncThunk(
  "SightseenCancellation/UpdateSightseenCancellation",
  async (SightseenCancellationData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SightseenCancellation/UpdateSightseenCancellation`,
        SightseenCancellationData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating sightseen cancellation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteSightseenCancellation = createAsyncThunk(
  "SightseenCancellation/DeleteSightseenCancellation",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SightseenCancellation/DeleteSightseenCancellation`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting sightseen cancellation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSightseenCancellationByID = createAsyncThunk(
  "SightseenCancellation/FindByIDSightseenCancellation",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SightseenCancellation/FindByIDSightseenCancellation`,
        { ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        SightseenID: result.SightseenID,
        FromDays: result.FromDays,
        ToDays: result.ToDays,
        CancellationCharges: result.CancellationCharges,
        IsActive: result.IsActive,
      } as ISightseenCancellation;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching sightseen cancellation :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
