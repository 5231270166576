import {
  CardBody,
  CardImage,
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { MdShoppingCart } from "react-icons/md";
import { RootState } from "../../app/store";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  AddToCartTransferSearch,
  getAllTransferSearchs,
} from "./services/transferSearch.services";
import RippleButton from "../../components/common/RippleButton";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import {
  clearAddToCartDetails,
  setAddToCartDetails,
  setTransferSearchDetails,
} from "./transferSearchSlice";
import AppDialog from "../../components/dialog/Dialog";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
// import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormRadioGroup from "../../components/formFields/FormRadioGroup";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { ITransferLocation } from "../transferlocation/transferLocationModel";
import FormDatePicker from "../../components/formFields/FormDateField";
import moment from "moment";
import {
  getAllActiveTransferLocation,
  getTransferLocationBySectorID,
} from "../transferlocation/services/transferLocation.services";
import { getAllActiveSectors } from "../sector/services/sector.services";
import { TRIPTYPE } from "./TransferSearchForm";
import { ISector } from "../sector/sectorModel";
import { LoadingPanel } from "../../components/layout/Loading";
import ShadowCard from "../../components/common/ShadowCard";
import { NOOFADULTS, NOOFCHILD } from "../../_contstants/common";
import FormTextArea from "../../components/formFields/FormTextArea";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const SectorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const islocationRef = useRef(true);
  const SectorID = formRenderProps.valueGetter("SectorID");
  useEffect(() => {
    if (SectorID) {
      dispatch(getTransferLocationBySectorID(SectorID));
    }
    if (!islocationRef.current) {
      formRenderProps.onChange("FromLocationID", {
        value: null,
      });
      formRenderProps.onChange("ToLocationID", {
        value: null,
      });
    } else {
      islocationRef.current = false;
    }
  }, [SectorID]);
  return null;
};

const TransferSearchList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const gridRef = useRef<any>(null);
  const location = useLocation();

  const [formKey, setFormKey] = React.useState(1);
  const [showTransferSearch, setShowTransferSearch] =
    React.useState<boolean>(false);
  const [initialValues, setInitialValues] = React.useState<any>({});

  const TransferSearchList = useAppSelector(
    (state: RootState) => state.transferSearch.TransferSearchList
  );
  const dialogName = useAppSelector(
    (state: RootState) => state.dialog.dialogName
  );
  const AddtoCartDetails = useAppSelector(
    (state: RootState) => state.transferSearch.AddtoCartDetails
  );
  const loading = useAppSelector(
    (state: RootState) => state.transferSearch.loading
  );
  const SectorList = useAppSelector(
    (state: RootState) => state.sector.SectorList
  );
  const TransferLocationList = useAppSelector(
    (state: RootState) => state.transferLocation.TransferLocationList
  );
  const TransferDetail = useAppSelector(
    (state: RootState) => state.transferSearch.TransferDetail
  );

  const FromLocationID = TransferDetail?.FromLocationID
    ? TransferDetail?.FromLocationID
    : location.state?.FromLocationID;
  const ToLocationID = TransferDetail?.ToLocationID
    ? TransferDetail?.ToLocationID
    : location.state?.ToLocationID;
  const FromDate = TransferDetail?.FromDate
    ? moment(TransferDetail?.FromDate).format("DD MMM yyyy")
    : moment(location.state?.FromDate).format("DD MMM yyyy");
  const ToDate = TransferDetail?.ToDate
    ? moment(TransferDetail?.ToDate).format("DD MMM yyyy")
    : moment(location.state?.ToDate).format("DD MMM yyyy");

  useEffect(() => {
    dispatch(getAllActiveSectors());
    dispatch(getAllActiveTransferLocation());

    return () => {
      dispatch(clearAddToCartDetails());
    };
  }, []);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [TransferDetail]);

  useEffect(() => {
    setInitialValues({
      SectorID:
        TransferDetail?.SectorID && TransferDetail.SectorID
          ? TransferDetail?.SectorID
          : location.state?.SectorID,
      FromLocationID:
        TransferDetail?.FromLocationID && TransferDetail.SectorID
          ? TransferDetail?.FromLocationID
          : location.state?.FromLocationID,
      ToLocationID:
        TransferDetail?.ToLocationID && TransferDetail.SectorID
          ? TransferDetail?.ToLocationID
          : location.state?.ToLocationID,
      FromDate:
        TransferDetail?.FromDate && TransferDetail.SectorID
          ? moment(TransferDetail?.FromDate).toDate()
          : location.state?.FromDate
          ? moment(location.state?.FromDate).toDate()
          : "",
      ToDate:
        TransferDetail?.ToDate && TransferDetail.SectorID
          ? moment(TransferDetail?.ToDate).toDate()
          : location.state?.ToDate
          ? moment(location.state?.ToDate).toDate()
          : "",
      NoOfAdult:
        TransferDetail?.NoOfAdult && TransferDetail.SectorID
          ? TransferDetail?.NoOfAdult.toString()
          : location.state?.NoOfAdult
          ? location.state?.NoOfAdult.toString()
          : "0",
      NoOfChild:
        TransferDetail?.NoOfChild && TransferDetail.SectorID
          ? TransferDetail?.NoOfChild.toString()
          : location.state?.NoOfChild
          ? location.state?.NoOfChild.toString()
          : "0",
      TripType:
        TransferDetail?.TripType && TransferDetail.SectorID
          ? TransferDetail?.TripType
          : location.state?.TripType,
    });

    if (TransferDetail.SectorID) {
      const TransferlocationData = {
        SectorID: TransferDetail?.SectorID,
        FromLocationID: TransferDetail?.FromLocationID,
        ToLocationID: TransferDetail?.ToLocationID,
        FromDate: TransferDetail?.FromDate
          ? moment(TransferDetail?.FromDate).format("YYYY-MM-DD")
          : "",
        ToDate: TransferDetail?.ToDate
          ? moment(TransferDetail?.ToDate).format("YYYY-MM-DD")
          : "",
        NoOfAdult: TransferDetail?.NoOfAdult ? +TransferDetail?.NoOfAdult : 0,
        NoOfChild: TransferDetail?.NoOfChild ? +TransferDetail?.NoOfChild : 0,
        TripType: TransferDetail?.TripType,
      };
      dispatch(getAllTransferSearchs(TransferlocationData));
    } else {
      const TransferlocationData = {
        SectorID: location.state?.SectorID,
        FromLocationID: location.state?.FromLocationID,
        ToLocationID: location.state?.ToLocationID,
        FromDate: location.state?.FromDate
          ? moment(location.state?.FromDate).format("YYYY-MM-DD")
          : "",
        ToDate: location.state?.ToDate
          ? moment(location.state?.ToDate).format("YYYY-MM-DD")
          : "",
        NoOfAdult: location.state?.NoOfAdult ? +location.state?.NoOfAdult : 0,
        NoOfChild: location.state?.NoOfChild ? +location.state?.NoOfChild : 0,
        TripType: location.state?.TripType,
      };
      dispatch(getAllTransferSearchs(TransferlocationData));
    }
  }, [TransferDetail]);

  const FilteredFromLocation = TransferLocationList
    ? TransferLocationList?.filter((transferLocation: any) =>
        +transferLocation.ID === FromLocationID ? +FromLocationID : 0
      )
    : [];
  const FromLocation = FilteredFromLocation
    ? FilteredFromLocation[0]?.LocationName
    : "";
  const FilteredToLocation = TransferLocationList
    ? TransferLocationList.filter((transferLocation: any) =>
        +transferLocation.ID === ToLocationID ? +ToLocationID : 0
      )
    : [];
  const ToLocation = FilteredFromLocation
    ? FilteredToLocation[0]?.LocationName
    : "";

  const handleCardShowTransferSearch = () => {
    setShowTransferSearch(!showTransferSearch);
  };

  const handleCart = (values: any) => {
    const addToCartDetails = {
      TransferID: values?.ID,
      SectorID: values?.SectorID,
      FromLocationID: values?.FromLocationID,
      ToLocationID: values?.ToLocationID,
      CityID: values?.CityID,
      VehicleTypeID: values?.VehicleTypeID,
      TermsAndConditionsID: values?.TermsAndConditionsID,
      Pax: values?.Pax,
      Rate: values?.Rate,
      TripType: values?.TripType,
      IsActive: true,
    };
    dispatch(openDialog("addToCartTransferSearch"));
    dispatch(setAddToCartDetails(addToCartDetails));
  };

  const onDialogClose = () => {
    dispatch(closeDialog());
    dispatch(clearAddToCartDetails());
  };
  const handleAddToCart = async (values: any) => {
    const payload = {
      TransferID: AddtoCartDetails?.TransferID,
      SectorID: AddtoCartDetails?.SectorID,
      FromLocationID: AddtoCartDetails?.FromLocationID,
      ToLocationID: AddtoCartDetails?.ToLocationID,
      CityID: AddtoCartDetails?.CityID,
      VehicleTypeID: AddtoCartDetails?.VehicleTypeID,
      TermsAndConditionsID: AddtoCartDetails?.TermsAndConditionsID,
      Pax: AddtoCartDetails?.Pax,
      Rate: AddtoCartDetails?.Rate,
      TripType: AddtoCartDetails?.TripType,
      IsActive: true,
      Remarks: values?.Remarks,
    };
    const response = await dispatch(AddToCartTransferSearch(payload));
    if (response.meta?.requestStatus === "fulfilled") {
      dispatch(closeDialog());
      dispatch(clearAddToCartDetails());
    }
  };

  const handleSubmit = async (values: any) => {
    const payload = {
      SectorID: values.SectorID ? values.SectorID : null,
      FromLocationID: values.FromLocationID ? values.FromLocationID : null,
      ToLocationID: values.ToLocationID ? values.ToLocationID : null,
      NoOfAdult: values.NoOfAdult ? values.NoOfAdult : "0",
      NoOfChild: values.NoOfChild ? values.NoOfChild : "0",
      TripType: values.TripType ? values.TripType : null,
      FromDate: values.FromDate ? moment(values.FromDate).toDate() : "",
      ToDate: values.ToDate ? moment(values.ToDate).toDate() : "",
    };
    dispatch(setTransferSearchDetails(payload));
  };
  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <div>
      <div>
        <p style={{ textAlign: "right", position: "relative" }}>
          <span className="absolute-position">{`${FromLocation} - ${ToLocation} | ${FromDate} - ${ToDate}`}</span>
          <RippleButton
            type="button"
            fillMode="outline"
            themeColor="primary"
            style={{ marginRight: 4 }}
            onClick={() => handleCardShowTransferSearch()}
          >
            Modify Search
          </RippleButton>
        </p>
      </div>
      {showTransferSearch && (
        <div>
          <Form
            key={formKey}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement>
                <ShadowCard style={{ padding: 12 }}>
                  <GridLayout
                    style={{ marginRight: 20 }}
                    gap={{ rows: 0, cols: 10 }}
                    cols={[
                      { width: "33.33%" },
                      { width: "33.33%" },
                      { width: "33.33%" },
                    ]}
                  >
                    <GridLayoutItem colSpan={3}>
                      <Typography.h4>{"Transfer Search"}</Typography.h4>
                    </GridLayoutItem>
                    <GridLayoutItem colSpan={3}>
                      <Field
                        className="radiodiv"
                        id={"TripType"}
                        name={"TripType"}
                        // label={"Gender"}
                        layout={"horizontal"}
                        component={FormRadioGroup}
                        data={TRIPTYPE}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        id={"SectorID"}
                        name={"SectorID"}
                        label={"Destination"}
                        component={FormSelectionField}
                        validator={requiredValidator}
                        options={SectorList.map((sector: ISector) => {
                          return {
                            value: sector.ID,
                            label: sector.SectorName,
                          };
                        })}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        id={"FromLocationID"}
                        name={"FromLocationID"}
                        label={"Pickup From"}
                        component={FormSelectionField}
                        validator={requiredValidator}
                        options={TransferLocationList.map(
                          (location: ITransferLocation) => {
                            return {
                              value: location.ID,
                              label: location.LocationName,
                            };
                          }
                        )}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        id={"ToLocationID"}
                        name={"ToLocationID"}
                        label={"Drop Off"}
                        component={FormSelectionField}
                        validator={requiredValidator}
                        options={TransferLocationList.map(
                          (location: ITransferLocation) => {
                            return {
                              value: location.ID,
                              label: location.LocationName,
                            };
                          }
                        )}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`FromDate`}
                        label="From Date"
                        format="dd/MM/yyyy"
                        component={FormDatePicker}
                        validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`ToDate`}
                        label="To Date"
                        format="dd/MM/yyyy"
                        minDate={moment(
                          formRenderProps.valueGetter("FromDate")
                        ).toDate()}
                        component={FormDatePicker}
                        validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem style={{ width: "100%" }}></GridLayoutItem>
                    <GridLayoutItem
                      style={{ width: "100%", display: "flex", gap: "10px" }}
                    >
                      <Field
                        id={"NoOfAdult"}
                        name={"NoOfAdult"}
                        label={"No Of Adult"}
                        component={FormSelectionField}
                        validator={requiredValidator}
                        options={NOOFADULTS.map((adult: any) => {
                          return {
                            value: adult.value,
                            label: adult.label,
                          };
                        })}
                      />
                      <Field
                        id={"NoOfChild"}
                        name={"NoOfChild"}
                        label={"No Of Child"}
                        component={FormSelectionField}
                        options={NOOFCHILD.map((adult: any) => {
                          return {
                            value: adult.value,
                            label: adult.label,
                          };
                        })}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem
                      colSpan={2}
                      style={{
                        textAlign: "end",
                        position: "absolute",
                        bottom: 10,
                        right: 10,
                      }}
                    >
                      <Button
                        type="button"
                        fillMode={"outline"}
                        themeColor={"primary"}
                        style={{ marginRight: 4 }}
                        onClick={handleCardShowTransferSearch}
                      >
                        Cancel
                      </Button>
                      <ButtonWithLoading
                        label={"Search"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </GridLayoutItem>
                  </GridLayout>
                </ShadowCard>
                <SectorChangeWatcher formRenderProps={formRenderProps} />
              </FormElement>
            )}
          />
        </div>
      )}
      {dialogName === "addToCartTransferSearch" && (
        <AppDialog>
          <Form
            onSubmit={handleAddToCart}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "280px" }}>
                <Typography.h5 style={{ margin: 0, textAlign: "center" }}>
                  {"Add to Cart"}
                </Typography.h5>
                <GridLayout>
                  <GridLayoutItem>
                    <Field
                      name={`Remarks`}
                      label="Remarks"
                      component={FormTextArea}
                      row={4}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      height: "50px",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                      gap: "5px",
                    }}
                  >
                    <Button
                      fillMode={"outline"}
                      themeColor={"primary"}
                      type="submit"
                      onClick={onDialogClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      fillMode={"solid"}
                      themeColor={"primary"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit}
                    >
                      Add To Cart
                    </Button>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            )}
          />
        </AppDialog>
      )}
      {TransferSearchList &&
        TransferSearchList.length > 0 &&
        TransferSearchList.map((transferSearch) => (
          <div style={{ margin: "10px 0" }}>
            <ShadowCard
              orientation="horizontal"
              style={{
                padding: "5px",
              }}
            >
              <CardImage
                src={transferSearch?.TransferImage}
                alt="Vehicle Image"
                style={{
                  height: "100px",
                  width: "100px",
                  background: "whitesmoke",
                  borderRadius: "5px",
                }}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CardBody
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "90%",
                    }}
                  >
                    <table className="CardTable" cellSpacing={0}>
                      <tr>
                        <th>Pickup</th>
                        <th>Drop</th>
                        <th>Vehicle</th>
                        <th>Vehicle Seats</th>
                        <th>No of Pax</th>
                        <th>Price</th>
                      </tr>
                      <tr>
                        <td>{transferSearch?.FromLocation}</td>
                        <td>{transferSearch?.ToLocation}</td>
                        <td>{transferSearch?.VehicleType}</td>
                        <td>{transferSearch?.SeatCount}</td>
                        <td>{transferSearch?.Pax}</td>
                        <td>{transferSearch?.Rate}</td>
                      </tr>
                    </table>
                  </div>
                  <div style={{ width: "70px" }}>
                    <ButtonWithLoading
                      label={
                        <>
                          <p>Add Cart</p>
                          <MdShoppingCart style={{ fontSize: "20px" }} />
                        </>
                      }
                      onClick={() => handleCart(transferSearch)}
                    />
                  </div>
                </CardBody>
              </div>
            </ShadowCard>
          </div>
        ))}
      <div style={{ textAlign: "right", margin: "20px 10px" }}>
        <Button
          type="button"
          fillMode={"outline"}
          themeColor={"primary"}
          style={{ marginRight: 4 }}
          onClick={() => navigate("/transfersearch")}
        >
          Previous
        </Button>
        <RippleButton onClick={() => navigate("/transfercart")}>
          Proceed Booking
        </RippleButton>
      </div>
    </div>
  );
};

export default TransferSearchList;
