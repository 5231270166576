import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IVehicleType, IVehicleTypeState } from "./vehicleTypeModel";
import {
  ActiveInactiveVehicleType,
  createVehicleType,
  deleteVehicleType,
  getAllActiveVehicleTypes,
  getAllVehicleTypes,
  getVehicleTypeByID,
  updateVehicleType,
} from "./services/vehicleType.services";

const initialState: IVehicleTypeState = {
  loading: false,
  formLoading: false,
  error: "",
  VehicleTypeList: [],
  VehicleTypeID: null,
  VehicleTypeDetail: {
    VehicleType: "",
    IsActive: false,
  },
};

const vehicleTypeSlice = createSlice({
  name: "vehicleType",
  initialState,
  reducers: {
    clearVehicleTypeDetail: (state) => {
      state.VehicleTypeDetail = initialState.VehicleTypeDetail;
    },
    setVehicleTypeID: (state, action) => {
      state.VehicleTypeID = action.payload;
    },
    clearVehicleTypeID: (state) => {
      state.VehicleTypeID = initialState.VehicleTypeID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllVehicleTypes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllVehicleTypes.fulfilled,
      (state, action: PayloadAction<IVehicleType[]>) => {
        state.loading = false;
        state.VehicleTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllVehicleTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.VehicleTypeList = [];
    });
    builder.addCase(getAllActiveVehicleTypes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveVehicleTypes.fulfilled,
      (state, action: PayloadAction<IVehicleType[]>) => {
        state.loading = false;
        state.VehicleTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveVehicleTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.VehicleTypeList = [];
    });
    builder.addCase(createVehicleType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createVehicleType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createVehicleType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateVehicleType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateVehicleType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateVehicleType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteVehicleType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteVehicleType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteVehicleType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(ActiveInactiveVehicleType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveVehicleType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveVehicleType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getVehicleTypeByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getVehicleTypeByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.VehicleTypeDetail = action.payload;
    });
    builder.addCase(getVehicleTypeByID.rejected, (state, action) => {
      state.formLoading = false;
      state.VehicleTypeDetail = initialState.VehicleTypeDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearVehicleTypeID,
  setVehicleTypeID,
  clearVehicleTypeDetail,
} = vehicleTypeSlice.actions;
export default vehicleTypeSlice.reducer;
