import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button } from "@progress/kendo-react-buttons";
import FormDatePicker from "../../components/formFields/FormDateField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import moment from "moment";
import {
  createSightseenRate,
  deleteSightseenRate,
  getAllRateBySightseenID,
  updateSightseenRate,
} from "./services/sightseen.services";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { FiEdit } from "react-icons/fi";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import {
  setSightseenRateID,
  clearSightseenRateID,
  clearSightseenRateDetails,
  setSightseenRateDetails,
} from "./sightseenSlice";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { INationality } from "../nationality/nationalityModel";
import { getAllActiveNationalities } from "../nationality/services/nationality.services";
import ShadowCard from "../../components/common/ShadowCard";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";

const SightseenRate: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const location = useLocation();
  const SightseenID = location.state?.SightseenID;

  const SightseenRateDetail = useAppSelector(
    (state) => state.sightseen.SightseenRateDetail
  );
  const SightseenRateList = useAppSelector(
    (state) => state.sightseen.SightseenRateList
  );
  const NationalityList = useAppSelector(
    (state) => state.nationality.NationalityList
  );
  const rateLoading = useAppSelector((state) => state.sightseen.rateLoading);

  const [formKey, setFormKey] = React.useState(1);

  useEffect(() => {
    dispatch(getAllActiveNationalities());
  }, []);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [SightseenRateDetail]);

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (dataItem: any) => {
      const payload = {
        ID: dataItem?.ID,
        SightseenID: dataItem?.SightseenID,
        NationalityID: dataItem?.NationalityID,
        FromDate: dataItem?.FromDate
          ? moment(dataItem?.FromDate, "DD/MM/YYYY").toDate()
          : "",
        ToDate: dataItem?.ToDate
          ? moment(dataItem?.ToDate, "DD/MM/YYYY").toDate()
          : "",
        AdultRate: dataItem?.AdultRate,
        ChildRate: dataItem?.ChildRate,
      };
      dispatch(setSightseenRateDetails(payload));
    };
    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(setSightseenRateID(ID));
      dispatch(openDialog("deleteSightseenRate"));
    };

    return (
      <td
        style={{
          ...props.style,
          textAlign: "center",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEdit(props.dataItem)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem.ID)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const handleSubmit = async (values: any) => {
    if (SightseenRateDetail?.ID) {
      const updatePayload = {
        ID: SightseenRateDetail?.ID,
        SightseenID: SightseenID,
        NationalityID: values.NationalityID ? +values.NationalityID : null,
        FromDate: values.FromDate
          ? moment(values.FromDate).format("YYYY-MM-DD")
          : "",
        ToDate: values.ToDate ? moment(values.ToDate).format("YYYY-MM-DD") : "",
        AdultRate: values.AdultRate ? +values.AdultRate : null,
        ChildRate: values.ChildRate ? +values.ChildRate : null,
        IsActive: values.IsActive ? values.IsActive : true,
      };
      const response = await dispatch(updateSightseenRate(updatePayload));
      if (response.meta?.requestStatus === "fulfilled") {
        dispatch(getAllRateBySightseenID(SightseenID));
        dispatch(clearSightseenRateDetails());
        setFormKey(formKey + 1);
      }
    } else {
      const insertPayload = {
        SightseenID: SightseenID,
        NationalityID: values.NationalityID ? +values.NationalityID : null,
        FromDate: values.FromDate
          ? moment(values.FromDate).format("YYYY-MM-DD")
          : "",
        ToDate: values.ToDate ? moment(values.ToDate).format("YYYY-MM-DD") : "",
        AdultRate: values.AdultRate ? +values.AdultRate : null,
        ChildRate: values.ChildRate ? +values.ChildRate : null,
        IsActive: values.IsActive ? values.IsActive : true,
      };
      const response = await dispatch(createSightseenRate(insertPayload));
      if (response.meta?.requestStatus === "fulfilled") {
        dispatch(getAllRateBySightseenID(SightseenID));
        dispatch(clearSightseenRateDetails());
        setFormKey(formKey + 1);
      }
    }
  };

  // if (rateLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={SightseenRateDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12, marginTop: "10px" }}>
              <Typography.h5>Sightseen Rate</Typography.h5>
              <GridLayout
                style={{ marginRight: 50 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "16.66%" },
                  { width: "16.66%" },
                  { width: "16.66%" },
                  { width: "16.66%" },
                  { width: "16.66%" },
                  { width: "16.66%" },
                ]}
              >
                <GridLayoutItem>
                  <Field
                    name={"FromDate"}
                    label="From Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={"ToDate"}
                    label="To Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"NationalityID"}
                    name={"NationalityID"}
                    label={"Nationality"}
                    type={"number"}
                    component={FormSelectionField}
                    options={NationalityList.map(
                      (nationality: INationality) => {
                        return {
                          value: nationality.ID,
                          label: nationality.Nationality,
                        };
                      }
                    )}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={"AdultRate"}
                    label="Adult Rate"
                    type="number"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={"ChildRate"}
                    label="Child Rate"
                    type="number"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    textAlign: "right",
                    position: "relative",
                  }}
                >
                  <div
                    style={{ position: "absolute", right: "0px", top: "35px" }}
                  >
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginRight: 4 }}
                      onClick={() => {
                        setFormKey(formKey + 1);
                        dispatch(clearSightseenRateDetails());
                      }}
                    >
                      Cancel
                    </Button>
                    <ButtonWithLoading
                      label={`${SightseenRateDetail?.ID ? "Update" : "Add"} `}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || rateLoading}
                      loading={rateLoading}
                    />
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
      {SightseenRateList &&
        SightseenRateList.length > 0 &&
        (rateLoading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          <>
            <DeleteSightseenRateDialog />
            <KendoGrid
              style={{ height: "fit-content", marginTop: "10px" }}
              data={SightseenRateList}
              cells={{
                headerCell: HeaderCustomCell,
                data: MyDataCustomCell,
              }}
            >
              <Column field="FromDate" title="From Date" />
              <Column field="ToDate" title="To Date" />
              <Column field="Nationality" title="Nationality" />
              <Column field="AdultRate" title="Adult Rate" />
              <Column field="ChildRate" title="Child Rate" />
              <Column
                field="ID"
                title="Actions"
                cell={MyEditCommandCell}
                width={"110px"}
                filterable={false}
              />
            </KendoGrid>
          </>
        ))}
    </>
  );
};

const DeleteSightseenRateDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const SightseenID = location.state?.SightseenID;

  const SightseenRateID = useAppSelector(
    (state) => state.sightseen.SightseenRateID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteSightseenRate(ID));
      if (response.payload.Data.Status === 200) {
        dispatch(getAllRateBySightseenID(SightseenID));
        dispatch(closeDialog());
        dispatch(clearSightseenRateID());
      } else {
        dispatch(getAllRateBySightseenID(SightseenID));
        dispatch(closeDialog());
        dispatch(clearSightseenRateID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteSightseenRate" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Sightseen Rate"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Sightseen Rate?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(SightseenRateID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default SightseenRate;
