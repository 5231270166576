import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ICurrency } from "../currencyModel";

export const getAllActiveCurrencies = createAsyncThunk(
  "Currency/FindAllActiveCurrency",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Currency/FindAllActiveCurrency`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Currencies:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllCurrencies = createAsyncThunk(
  "Currency/FindAllCurrency",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Currency/FindAllCurrency`,
        {
          CurrencyName: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Currencies:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createCurrency = createAsyncThunk(
  "Currency/InsertCurrency",
  async (CurrencyData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Currency/InsertCurrency`,
        CurrencyData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating currency:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateCurrency = createAsyncThunk(
  "Currency/UpdateCurrency",
  async (CurrencyData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Currency/UpdateCurrency`,
        CurrencyData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating currency:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteCurrency = createAsyncThunk(
  "Currency/DeleteCurrency",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Currency/DeleteCurrency`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting currency:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCurrencyByID = createAsyncThunk(
  "Currency/FindByIDCurrency",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Currency/FindByIDCurrency`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        CurrencyName: result.CurrencyName,
        CurrencyConversion: result.CurrencyConversion,
        IsActive: result.IsActive,
      } as ICurrency;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching currency:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveCurrency = createAsyncThunk(
  "Currency/ActiveInActiveCurrency",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Currency/ActiveInActiveCurrency`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive currency:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
