import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../../app/axios";
import { API_URL } from "../../../app/config";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IUserDetails } from "../userModel";

export const getAllUsers = createAsyncThunk("User/FindAllUser", async () => {
  const response = await axiosApiInstance.post(`${API_URL}/User/FindAllUser`, {
    FirstName: "",
  });
  return response.data.Data.map((users: any) => {
    return {
      ID: users.ID,
      UserGUID: users.UserGUID,
      FirstName: users.FirstName,
      LastName: users.LastName,
      MobileNo: users.MobileNo,
      AlternateMobileNo: users.IDAlternateMobileNo,
      EmailID: users.EmailID,
      AlternateEmailID: users.AlternateEmailID,
      Password: users.Password,
      UserTypeID: users.UserTypeID,
      UserType: users.UserType,
      UserImage: users.UserImage,
      IsActive: users.IsActive,
    };
  });
});

export const createUser = createAsyncThunk(
  "User/InsertUser",
  async (UserData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/InsertUser`,
        UserData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating user:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateUser = createAsyncThunk(
  "User/UpdateUser",
  async (UserData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/UpdateUser`,
        UserData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating user:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteUser = createAsyncThunk(
  "User/DeleteUser",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/DeleteUser`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting user:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getUserByID = createAsyncThunk(
  "User/FindByIDUser",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindByIDUser`,
        { ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        FirstName: result.FirstName,
        LastName: result.LastName,
        MobileNo: result.MobileNo,
        AlternateMobileNo: result.AlternateMobileNo,
        EmailID: result.EmailID,
        AlternateEmailID: result.AlternateEmailID,
        Password: result.Password,
        IsActive: result.IsActive,
        UserTypeID: result.UserTypeID,
        IsNationality: result.IsNationality,
        IsCountry: result.IsCountry,
        IsVehicleType: result.IsVehicleType,
        IsTermsAndConditions: result.IsTermsAndConditions,
        IsState: result.IsState,
      } as IUserDetails;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching user:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const activeInActiveUsers = createAsyncThunk(
  "User/ActiveInActiveUser",
  async (Data: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/ActiveInActiveUser`,
        Data
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error in status change:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveUsers = createAsyncThunk(
  "User/FindAllActiveUser",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/User/FindAllActiveUser`
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Users:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

