import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ActiveInactiveSightseenType,
  createSightseenType,
  deleteSightseenType,
  getAllActiveSightseenTypes,
  getAllSightseenTypes,
  getSightseenTypeByID,
  updateSightseenType,
} from "./services/sightseenType.services";
import {
  ISightseenType,
  ISightseenTypeInitialState,
} from "./sightseenTypeModel";

const initialState: ISightseenTypeInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  SightseenTypeList: [],
  SightseenTypeID: null,
  SightseenTypeDetail: {
    SightseenType: "",
    IsActive: true,
  },
};

const sightseenTypeSlice = createSlice({
  name: "sightseenType",
  initialState,
  reducers: {
    clearSightseenTypeDetails: (state) => {
      state.SightseenTypeDetail = initialState.SightseenTypeDetail;
    },
    setSightseenTypeID: (state, action) => {
      state.SightseenTypeID = action.payload;
    },
    clearSightseenTypeID: (state) => {
      state.SightseenTypeID = initialState.SightseenTypeID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSightseenTypes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllSightseenTypes.fulfilled,
      (state, action: PayloadAction<ISightseenType[]>) => {
        state.loading = false;
        state.SightseenTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllSightseenTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SightseenTypeList = [];
    });
    builder.addCase(createSightseenType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createSightseenType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createSightseenType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateSightseenType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateSightseenType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateSightseenType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteSightseenType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteSightseenType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteSightseenType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getSightseenTypeByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getSightseenTypeByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.SightseenTypeDetail = action.payload;
    });
    builder.addCase(getSightseenTypeByID.rejected, (state, action) => {
      state.formLoading = false;
      state.SightseenTypeDetail = initialState.SightseenTypeDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveSightseenTypes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveSightseenTypes.fulfilled,
      (state, action: PayloadAction<ISightseenType[]>) => {
        state.loading = false;
        state.SightseenTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveSightseenTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SightseenTypeList = [];
    });
    builder.addCase(ActiveInactiveSightseenType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveSightseenType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveSightseenType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearSightseenTypeDetails,
  setSightseenTypeID,
  clearSightseenTypeID,
} = sightseenTypeSlice.actions;
export default sightseenTypeSlice.reducer;
