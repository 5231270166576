import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast } from "../../../components/toast/Toasts";

export const getAllActiveVehicleSharingPaxs = createAsyncThunk(
  "VehicleSharingPax/FindAllActiveVehicleSharingPax",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/VehicleSharingPax/FindAllActiveVehicleSharingPax`
      );
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Vehicle Sharing Paxs:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
