import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRegion, IRegionInitialState } from "./regionModel";
import {
  ActiveInactiveRegion,
  createRegion,
  deleteRegion,
  getAllActiveRegions,
  getAllRegions,
  getRegionByID,
  updateRegion,
} from "./services/region.services";

const initialState: IRegionInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  RegionList: [],
  RegionID: null,
  RegionDetail: {
    RegionName: "",
    IsActive: false,
  },
};

const regionSlice = createSlice({
  name: "region",
  initialState,
  reducers: {
    clearRegionDetails: (state) => {
      state.RegionDetail = initialState.RegionDetail;
    },
    setRegionID: (state, action) => {
      state.RegionID = action.payload;
    },
    clearRegionID: (state) => {
      state.RegionID = initialState.RegionID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRegions.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllRegions.fulfilled,
      (state, action: PayloadAction<IRegion[]>) => {
        state.loading = false;
        state.RegionList = action.payload || [];
      }
    );
    builder.addCase(getAllRegions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RegionList = [];
    });
    builder.addCase(createRegion.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createRegion.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createRegion.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateRegion.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateRegion.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateRegion.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteRegion.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteRegion.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteRegion.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getRegionByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getRegionByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.RegionDetail = action.payload;
    });
    builder.addCase(getRegionByID.rejected, (state, action) => {
      state.formLoading = false;
      state.RegionDetail = initialState.RegionDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveRegions.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveRegions.fulfilled,
      (state, action: PayloadAction<IRegion[]>) => {
        state.loading = false;
        state.RegionList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveRegions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RegionList = [];
    });
    builder.addCase(ActiveInactiveRegion.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveRegion.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveRegion.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearRegionDetails, clearRegionID, setRegionID } =
  regionSlice.actions;
export default regionSlice.reducer;
