import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Field,
  //   FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
// import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
// import FormCheckbox from "../../components/formFields/FormCheckbox";
// import RippleButton from "../../components/common/RippleButton";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
// import {
//   createTransfer,
//   // createTransfer,
//   getTransferByID,
//   updateTransfer,
//   uploadTransferImage,
//   // updateTransfer,
// } from "./services/transfer.services";
// import { clearTransferDetails } from "./transferSlice";
// import { getAllActiveCities } from "../city/services/city.services";
import { getAllActiveSectors } from "../sector/services/sector.services";
// import { getAllActiveVehicleTypes } from "../vehicleType/services/vehicleType.services";
// import { getAllActiveTermsAndConditions } from "../termsandcondition/services/termsAndCondition.services";
import { ISector } from "../sector/sectorModel";
// import { ICity } from "../city/cityModel";
import { ITransferLocation } from "../transferlocation/transferLocationModel";
// import { IVehicleType } from "../vehicleType/vehicleTypeModel";
// import { ITermsAndCondition } from "../termsandcondition/termsAndConditionModel";
// import TransferRateArray from "./TransferRateArray";
// import moment from "moment";
// import FormImageUpload from "../../components/formFields/FormImageUpload";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormRadioGroup from "../../components/formFields/FormRadioGroup";
// import { getAllTransferSearchs } from "./services/transferSearch.services";
import moment from "moment";
import { clearTransferSearchDetails, setTransferSearchDetails } from "./transferSearchSlice";
import ShadowCard from "../../components/common/ShadowCard";
import { getAllTransferSearchs } from "./services/transferSearch.services";
import { getTransferLocationBySectorID } from "../transferlocation/services/transferLocation.services";
import { NOOFADULTS, NOOFCHILD } from "../../_contstants/common";

interface IOption {
  label: string;
  value: string | number;
}

export const TRIPTYPE: Array<IOption> = [
  { label: "One Way", value: 1 },
  { label: "Return", value: 2 },
];

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const SectorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const islocationRef = useRef(true);
  const SectorID = formRenderProps.valueGetter("SectorID");
  useEffect(() => {
    if (SectorID) {
      dispatch(getTransferLocationBySectorID(SectorID));
    }
    if (!islocationRef.current) {
      formRenderProps.onChange("FromLocationID", {
        value: null,
      });
      formRenderProps.onChange("ToLocationID", {
        value: null,
      });
    } else {
      islocationRef.current = false;
    }
  }, [SectorID]);
  return null;
};

const CreateTransfer: React.FC = () => {
  const navigate = useNavigate();
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state: RootState) => state.transfer.loading);
  const SectorList = useAppSelector(
    (state: RootState) => state.sector.SectorList
  );
  const TransferLocationList = useAppSelector(
    (state: RootState) => state.transferLocation.TransferLocationList
  );
  const TransferDetail = useAppSelector(
    (state: RootState) => state.transferSearch.TransferDetail
  );

  useEffect(() => {
    dispatch(getAllActiveSectors());

    return () => {
      dispatch(clearTransferSearchDetails());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    const payload = {
      SectorID: values.SectorID ? values.SectorID : null,
      FromLocationID: values.FromLocationID ? values.FromLocationID : null,
      ToLocationID: values.ToLocationID ? values.ToLocationID : null,
      NoOfAdult: values.NoOfAdult ? values.NoOfAdult.toString() : "0",
      NoOfChild: values.NoOfChild ? values.NoOfChild.toString() : "0",
      TripType: values.TripType ? values.TripType : null,
      FromDate: values.FromDate ? moment(values.FromDate).toDate() : "",
      ToDate: values.ToDate ? moment(values.ToDate).toDate() : "",
    };
    dispatch(setTransferSearchDetails(payload));

    const insertPayload = {
      SectorID: values.SectorID ? values.SectorID : null,
      FromLocationID: values.FromLocationID ? values.FromLocationID : null,
      ToLocationID: values.ToLocationID ? values.ToLocationID : null,
      NoOfAdult: values.NoOfAdult ? +values.NoOfAdult : 0,
      NoOfChild: values.NoOfChild ? +values.NoOfChild : 0,
      TripType: values.TripType ? values.TripType : null,
      FromDate: values.FromDate
        ? moment(values.FromDate).format("YYYY-MM-DD")
        : "",
      ToDate: values.ToDate ? moment(values.ToDate).format("YYYY-MM-DD") : "",
    };
    const response = await dispatch(getAllTransferSearchs(insertPayload));
    if (response?.meta?.requestStatus === "fulfilled") {
      navigate("/transfersearchlist", {
        state: payload,
      });
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={TransferDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>{"Transfer Search"}</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  <Field
                    className="radiodiv"
                    id={"TripType"}
                    name={"TripType"}
                    // label={"Gender"}
                    layout={"horizontal"}
                    component={FormRadioGroup}
                    data={TRIPTYPE}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id={"SectorID"}
                    name={"SectorID"}
                    label={"Destination"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={SectorList.map((sector: ISector) => {
                      return {
                        value: sector.ID,
                        label: sector.SectorName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id={"FromLocationID"}
                    name={"FromLocationID"}
                    label={"Pickup From"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={TransferLocationList.map(
                      (location: ITransferLocation) => {
                        return {
                          value: location.ID,
                          label: location.LocationName,
                        };
                      }
                    )}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id={"ToLocationID"}
                    name={"ToLocationID"}
                    label={"Drop Off"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={TransferLocationList.map(
                      (location: ITransferLocation) => {
                        return {
                          value: location.ID,
                          label: location.LocationName,
                        };
                      }
                    )}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`FromDate`}
                    label="From Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`ToDate`}
                    label="To Date"
                    minDate={moment(
                      formRenderProps.valueGetter("FromDate")
                    ).toDate()}
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}></GridLayoutItem>
                <GridLayoutItem
                  style={{ width: "100%", display: "flex", gap: "10px" }}
                >
                  <Field
                    id={"NoOfAdult"}
                    name={"NoOfAdult"}
                    label={"No Of Adult"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={NOOFADULTS.map((adult: any) => {
                      return {
                        value: adult.value,
                        label: adult.label,
                      };
                    })}
                  />
                  <Field
                    id={"NoOfChild"}
                    name={"NoOfChild"}
                    label={"No Of Child"}
                    component={FormSelectionField}
                    options={NOOFCHILD.map((adult: any) => {
                      return {
                        value: adult.value,
                        label: adult.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={2}
                  style={{
                    textAlign: "end",
                    position: "absolute",
                    bottom: 10,
                    right: 10,
                  }}
                >
                  <ButtonWithLoading
                    label={"Search"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <SectorChangeWatcher formRenderProps={formRenderProps} />
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateTransfer;
