import React, { useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import { MdDelete } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { setCustomizeSearchDeleteIndex } from "./customizeSearchSlice";
import IconButton from "../../components/common/IconButton";
import FormSelectionField from "../../components/formFields/FormSelectionField";

export const NOOFADULTS = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
];

const CustomizeRoomArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const RoomDeleteIndex = useAppSelector(
    (state) => state.customizeSearch.deleteIndex
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  useEffect(() => {
    pushElementInarray();
  }, []);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("roomDeleteDialog"));
    dispatch(setCustomizeSearchDeleteIndex(index));
  };

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setCustomizeSearchDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        RoomNo: null,
        Adult: null,
        Child: null,
        Infant: null,
      },
    });
  }, [fieldArrayRenderProps]);

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      <GridLayoutItem style={{ marginBottom: 10 }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Room
        </Button>
      </GridLayoutItem>
      {dialogName === "roomDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Room"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(RoomDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map(
          (PassengerDetails: any, index: number) => (
            <GridLayoutItem key={index}>
              <Card className="fieldarray-card">
                <GridLayout
                  style={{ marginRight: 50 }}
                  gap={{ rows: 0, cols: 10 }}
                  cols={[
                    { width: "20%" },
                    { width: "18%" },
                    { width: "18%" },
                    { width: "18%" },
                    { width: "18%" },
                    { width: "8%" },
                  ]}
                >
                  <GridLayoutItem
                    style={{ display: "flex", alignItems: "end" }}
                  >
                    <div>
                      <div
                        style={{
                          display: "inline-block",
                          color: "white",
                          background: "red",
                          padding: "8px 15px",
                          borderRadius: "20px",
                        }}
                      >
                        Room - {index + 1}
                      </div>
                    </div>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`PassengerDetails.${index}.Adult`}
                      label="Adult"
                      component={FormSelectionField}
                      wrapperStyle={{ margin: "0" }}
                      options={NOOFADULTS?.map((adults: any) => {
                        return {
                          value: adults?.value,
                          label: adults?.label,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`PassengerDetails.${index}.CWB`}
                      label="CWB"
                      component={FormSelectionField}
                      wrapperStyle={{ margin: "0" }}
                      options={NOOFADULTS?.map((adults: any) => {
                        return {
                          value: adults?.value,
                          label: adults?.label,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`PassengerDetails.${index}.CNB`}
                      label="CNB"
                      component={FormSelectionField}
                      wrapperStyle={{ margin: "0" }}
                      options={NOOFADULTS?.map((adults: any) => {
                        return {
                          value: adults?.value,
                          label: adults?.label,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`PassengerDetails.${index}.Infant`}
                      label="Infant"
                      component={FormSelectionField}
                      wrapperStyle={{ margin: "0" }}
                      options={NOOFADULTS?.map((adults: any) => {
                        return {
                          value: adults?.value,
                          label: adults?.label,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      position: "relative",
                    }}
                  >
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(index)}
                      disabled={fieldArrayRenderProps.value.length === 1}
                      themeColor={"error"}
                      size={"small"}
                      fillMode={"solid"}
                      type="button"
                      style={{
                        height: 24,
                        width: 24,
                        position: "absolute",
                        top: 23,
                        right: 0,
                      }}
                    >
                      <MdDelete
                        className="absolute-position"
                        style={{ fontSize: "16px" }}
                      />
                    </IconButton>
                  </GridLayoutItem>
                </GridLayout>
              </Card>
            </GridLayoutItem>
          )
        )}
    </GridLayout>
  );
};

export default CustomizeRoomArray;
