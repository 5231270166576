import React, { useEffect, useRef } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button } from "@progress/kendo-react-buttons";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import {
  createSightseenCancellation,
  deleteSightseenCancellation,
  getAllCancellationBySightseenID,
  //   getSightseenCancellationByID,
  updateSightseenCancellation,
} from "./services/sightseen.services";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { FiEdit } from "react-icons/fi";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import ShadowCard from "../../components/common/ShadowCard";
import { Typography } from "@progress/kendo-react-common";
import { useLocation } from "react-router-dom";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import {
  clearCancellationChargeDetails,
  clearCancellationChargeID,
  setCancellationChargeDetails,
  setCancellationChargeID,
} from "./sightseenSlice";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";

const CancellationCharge: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const location = useLocation();
  const SightseenID = location.state?.SightseenID;

  const SightseenCancellationDetail = useAppSelector(
    (state) => state.sightseen.SightseenCancellationDetail
  );
  const SightseenCancellationList = useAppSelector(
    (state) => state.sightseen.SightseenCancellationList
  );
  const CCLoading = useAppSelector((state) => state.sightseen.CCLoading);

  const [formKey, setFormKey] = React.useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [SightseenCancellationDetail]);

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (dataItem: any) => {
      const payload = {
        ID: dataItem.ID,
        FromDays: dataItem.FromDays,
        ToDays: dataItem.ToDays,
        CancellationCharges: dataItem.CancellationCharges,
      };

      dispatch(setCancellationChargeDetails(payload));
    };
    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(setCancellationChargeID(ID));
      dispatch(openDialog("deleteCancellationCharge"));
    };

    return (
      <td
        style={{
          ...props.style,
          textAlign: "center",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEdit(props.dataItem)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem.ID)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const handleSubmit = async (values: any) => {
    if (SightseenCancellationDetail?.ID) {
      const updatePayload = {
        ID: SightseenCancellationDetail?.ID,
        SightseenID: SightseenID,
        FromDays: values.FromDays ? +values.FromDays : null,
        ToDays: values.ToDays ? +values.ToDays : null,
        CancellationCharges: values.CancellationCharges
          ? +values.CancellationCharges
          : null,
        IsActive: values.IsActive ? values.IsActive : false,
      };
      const response = await dispatch(
        updateSightseenCancellation(updatePayload)
      );
      if (response.meta?.requestStatus === "fulfilled") {
        dispatch(getAllCancellationBySightseenID(SightseenID));
        dispatch(clearCancellationChargeDetails());
        setFormKey(formKey + 1);
      }
    } else {
      const insertPayload = {
        SightseenID: SightseenID,
        FromDays: values.FromDays ? +values.FromDays : null,
        ToDays: values.ToDays ? +values.ToDays : null,
        CancellationCharges: values.CancellationCharges
          ? +values.CancellationCharges
          : null,
        IsActive: values.IsActive ? values.IsActive : true,
      };
      const response = await dispatch(
        createSightseenCancellation(insertPayload)
      );
      if (response.meta?.requestStatus === "fulfilled") {
        dispatch(getAllCancellationBySightseenID(SightseenID));
        dispatch(clearCancellationChargeDetails());
        setFormKey(formKey + 1);
      }
    }
  };

  // if (CCLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        key={formKey}
        initialValues={SightseenCancellationDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12, marginTop: "10px" }}>
              <Typography.h5>Cancellation Charges</Typography.h5>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem>
                  <Field
                    name={"FromDays"}
                    label="From Days"
                    type="number"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={"ToDays"}
                    label="To Days"
                    type="number"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={"CancellationCharges"}
                    label="Cancellation Charges (%)"
                    type="number"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    textAlign: "right",
                    position: "relative",
                  }}
                >
                  <div
                    style={{ position: "absolute", right: "0px", top: "35px" }}
                  >
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginRight: 4 }}
                      onClick={() => {
                        setFormKey(formKey + 1);
                        dispatch(clearCancellationChargeDetails());
                      }}
                    >
                      Cancel
                    </Button>
                    <ButtonWithLoading
                      label={`${
                        SightseenCancellationDetail?.ID ? "Update" : "Add"
                      } `}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || CCLoading}
                      loading={CCLoading}
                    />
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
      {SightseenCancellationList &&
        SightseenCancellationList.length > 0 &&
        (CCLoading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          <>
            <DeleteCancellationChargeDialog />
            <KendoGrid
              style={{ height: "fit-content", marginTop: "10px" }}
              data={SightseenCancellationList}
              cells={{
                headerCell: HeaderCustomCell,
                data: MyDataCustomCell,
              }}
            >
              <Column field="FromDays" title="From Days" />
              <Column field="ToDays" title="To Days" />
              <Column
                field="CancellationCharges"
                title="Cancellation Charges"
                cell={(props) => {
                  return <td>{props.dataItem.CancellationCharges}%</td>;
                }}
              />
              <Column
                field="ID"
                title="Actions"
                cell={MyEditCommandCell}
                width={"110px"}
                filterable={false}
              />
            </KendoGrid>
          </>
        ))}
    </>
  );
};

const DeleteCancellationChargeDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const SightseenID = location.state?.SightseenID;

  const CancellationChargeID = useAppSelector(
    (state) => state.sightseen.CancellationChargeID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteSightseenCancellation(ID));
      if (response.payload.Data.Status === 200) {
        dispatch(getAllCancellationBySightseenID(SightseenID));
        dispatch(closeDialog());
        dispatch(clearCancellationChargeID());
      } else {
        dispatch(getAllCancellationBySightseenID(SightseenID));
        dispatch(closeDialog());
        dispatch(clearCancellationChargeID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteCancellationCharge" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Cancellation Charge"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Cancellation Charge?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(CancellationChargeID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default CancellationCharge;
