import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDrawerState } from "./drawerModel";

const initialState: IDrawerState = {
  open: false,
  placement: "left",
  size: "default",
  name: "drawer",
  data: null,
};

const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    opened: (state, action: PayloadAction<IDrawerState>) => {
      state.open = action.payload.open;
      state.title = action.payload.title;
      state.placement = action.payload.placement;
      state.size = action.payload.size ? action.payload.size : state.size;
      state.name = action.payload.name;
      state.data = action.payload.data ? action.payload.data : null;
    },
    closed: (state) => {
      state.open = false;
      state.name = "drawer";
      state.data = null;
    },
  },
});

export default drawerSlice.reducer;
export const { opened, closed } = drawerSlice.actions;
