import { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { clearCountryDetails } from "./countrySlice";
import {
  createCountry,
  getAllCountries,
  getCountryByID,
  updateCountry,
} from "./services/country.services";
import { closed } from "../../components/drawer/drawerSlice";

const CreateCountry = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const CountryDetail = useAppSelector((state) => state.country.CountryDetail);
  const CountryID = useAppSelector((state) => state.drawer.data);
  const formLoading = useAppSelector((state) => state.country.formLoading);

  useEffect(() => {
    if (CountryID) {
      dispatch(getCountryByID(CountryID));
    }
  }, [CountryID]);

  useEffect(() => {
    return () => {
      dispatch(clearCountryDetails());
      dispatch(closed());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      if (!CountryID) {
        const insertPayload = {
          CountryName: values.CountryName,
          CountryCode: values.CountryCode,
          IsActive: true,
        };
        const response = await dispatch(createCountry(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearCountryDetails());
          dispatch(getAllCountries());
        }
      } else {
        const editPayload = {
          ID: CountryID,
          CountryName: values.CountryName,
          CountryCode: values.CountryCode,
          IsActive: values.IsActive,
        };
        const response = await dispatch(updateCountry(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearCountryDetails());
          dispatch(getAllCountries());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={CountryDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="CountryName"
                label="Country"
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="CountryCode"
                label="Country Code"
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "end",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginRight: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
              <ButtonWithLoading
                label={CountryID ? "Update" : "Create"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateCountry;
