import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITransfer, ITransferState } from "./transferModel";
import {
  createTransfer,
  deleteTransfer,
  getAllActiveTransfers,
  getAllTransfers,
  getTransferByID,
  transferActiveInactive,
  updateTransfer,
  uploadTransferImage,
} from "./services/transfer.services";

const initialState: ITransferState = {
  loading: false,
  error: "",
  TransferList: [],
  TransferID: null,
  deleteIndex: -1,
  TransferDetail: {
    TransferName: "",
    TrasferRateList: [],
    CityID: null,
    FromLocationID: null,
    Pax: null,
    SectorID: null,
    TermsAndConditionsID: null,
    ToLocationID: null,
    VehicleTypeID: null,
    ID: null,
    IsActive: 1,
  },
};

const transferSlice = createSlice({
  name: "transfer",
  initialState,
  reducers: {
    clearTransferDetails: (state) => {
      state.TransferDetail = initialState.TransferDetail;
    },
    setTransferID: (state, action) => {
      state.TransferID = action.payload;
    },
    clearTransferID: (state) => {
      state.TransferID = initialState.TransferID;
    },
    setDeleteIndex: (state, action) => {
      state.deleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTransfers.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllTransfers.fulfilled,
      (state, action: PayloadAction<ITransfer[]>) => {
        state.loading = false;
        state.TransferList = action.payload || [];
      }
    );
    builder.addCase(getAllTransfers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TransferList = [];
    });
    builder.addCase(getAllActiveTransfers.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveTransfers.fulfilled,
      (state, action: PayloadAction<ITransfer[]>) => {
        state.loading = false;
        state.TransferList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveTransfers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TransferList = [];
    });
    builder.addCase(createTransfer.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createTransfer.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createTransfer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateTransfer.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateTransfer.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateTransfer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(uploadTransferImage.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(uploadTransferImage.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(uploadTransferImage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteTransfer.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteTransfer.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTransfer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(transferActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(transferActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(transferActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTransferByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getTransferByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.TransferDetail = action.payload;
    });
    builder.addCase(getTransferByID.rejected, (state, action) => {
      state.loading = false;
      state.TransferDetail = initialState.TransferDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearTransferID,
  setTransferID,
  clearTransferDetails,
  setDeleteIndex,
} = transferSlice.actions;
export default transferSlice.reducer;
