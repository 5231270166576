import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  createTransferLocation,
  getAllTransferLocation,
  getTransferLocationByID,
  updateTransferLocation,
} from "./services/transferLocation.services";
import { clearTransferLocationDetails } from "./transferLocationSlice";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { ISector } from "../sector/sectorModel";
import { getAllActiveSectors } from "../sector/services/sector.services";
import { ICity } from "../city/cityModel";
import { getCityBySectorID } from "../city/services/city.services";
import { closed } from "../../components/drawer/drawerSlice";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const SectorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const isCItyRef = useRef(true);
  const SectorID = formRenderProps.valueGetter("SectorID");
  useEffect(() => {
    if (SectorID) {
      dispatch(getCityBySectorID(SectorID));
    }
    if (!isCItyRef.current) {
      formRenderProps.onChange("CityID", {
        value: null,
      });
    } else {
      isCItyRef.current = false;
    }
  }, [SectorID]);
  return null;
};

const CreateTransferLocation: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const TransferLocationDetail = useAppSelector(
    (state) => state.transferLocation.TransferLocationDetail
  );
  const TransferLocationID = useAppSelector((state) => state.drawer.data);
  const SectorCityList = useAppSelector((state) => state.city.SectorCityList);
  const SectorList = useAppSelector((state) => state.sector.SectorList);
  const formLoading = useAppSelector(
    (state) => state.transferLocation.formLoading
  );

  useEffect(() => {
    if (TransferLocationID) {
      dispatch(getTransferLocationByID(TransferLocationID));
    }
  }, [TransferLocationID]);

  useEffect(() => {
    dispatch(getAllActiveSectors());
    return () => {
      dispatch(clearTransferLocationDetails());
      dispatch(closed());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      if (!TransferLocationID) {
        const payload = {
          SectorID: values.SectorID,
          CityID: values.CityID,
          LocationName: values.LocationName,
          IsActive: true,
        };
        const response = await dispatch(createTransferLocation(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearTransferLocationDetails());
          dispatch(getAllTransferLocation());
        }
      } else {
        const payload = {
          ID: TransferLocationID,
          SectorID: values.SectorID,
          CityID: values.CityID,
          LocationName: values.LocationName,
          IsActive: values.IsActive,
        };
        const response = await dispatch(updateTransferLocation(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearTransferLocationDetails());
          dispatch(getAllTransferLocation());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={TransferLocationDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="LocationName"
                label="Location"
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id="SectorID"
                name="SectorID"
                label="Destination"
                component={FormSelectionField}
                validator={requiredValidator}
                options={SectorList?.map((sector: ISector) => {
                  return {
                    value: sector?.ID,
                    label: sector?.SectorName,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="CityID"
                label="City"
                component={FormSelectionField}
                validator={requiredValidator}
                options={SectorCityList?.map((city: ICity) => {
                  return {
                    value: city?.ID,
                    label: city?.CityName,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "end",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginRight: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
              <ButtonWithLoading
                label={TransferLocationID ? "Update" : "Create"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
            </GridLayoutItem>
          </GridLayout>
          <SectorChangeWatcher formRenderProps={formRenderProps} />
        </FormElement>
      )}
    />
  );
};

export default CreateTransferLocation;
