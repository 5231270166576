import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import {
  CardBody,
  CardImage,
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import React, { useEffect, useState } from "react";
import FormRadioGroup from "../../components/formFields/FormRadioGroup";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import { Typography } from "@progress/kendo-react-common";
import RippleButton from "../../components/common/RippleButton";
import { useLocation, useNavigate } from "react-router-dom";
import { Checkbox } from "antd";
import { getAllActiveTourSightseen } from "./services/toursAndTransfers.services";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { ISector } from "../sector/sectorModel";
import { getAllActiveSectors } from "../sector/services/sector.services";
import { clearTourList } from "./toursAndTransfersSlice";
import { insertPlannerItem } from "../planner/plannerSlice";

interface IOption {
  label: string;
  value: string | number;
}

export const DAYTYPE: Array<IOption> = [
  { label: "Half Day", value: 1 },
  { label: "Full Day", value: 2 },
];

// interface TourDetailsProps {
//   DayNo: number;
// }
interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const SectorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const SectorID = JSON.parse(sessionStorage.getItem("SectorID") as any);

  if (SectorID) {
    useEffect(() => {
      formRenderProps.onChange("SectorID", {
        value: SectorID,
      });
    }, [SectorID]);
  }

  return null;
};

const TourDetails: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const gridRef = React.useRef<any>(null);
  const location = useLocation();
  const DayNo = location.state?.DayNo;
  // const [openCardIndices, setOpenCardIndices] = React.useState<number[]>([]);
  const [checkedTours, setCheckedTours] = useState<number[]>([]);

  const SectorList = useAppSelector((state) => state.sector.SectorList);
  const TourList = useAppSelector((state) => state.toursAndTransfers.TourList);
  const TourDetail = useAppSelector(
    (state) => state.toursAndTransfers.TourDetail
  );
  const loading = useAppSelector((state) => state.toursAndTransfers.loading);

  useEffect(() => {
    dispatch(getAllActiveSectors());

    return () => {
      dispatch(clearTourList());
    };
  }, []);

  const onChange = (e: any, itemId: number) => {
    const isChecked = e.target?.checked;
    if (isChecked) {
      setCheckedTours((prevIds) => [...prevIds, itemId]);
    } else {
      setCheckedTours((prevIds) => prevIds.filter((id) => id !== itemId));
    }
  };

  const handleAddtoPlanner = () => {
    const selectedTours = TourList.filter((item) =>
      checkedTours.includes(item?.ID)
    ).map((e: any) => ({
      ID: e?.ID,
      DayNo: DayNo,
      SightseenTiming: e?.SightseenTiming,
      SightseenID: e?.SightseenID,
      SightseenName: e?.SightseenName,
      Rate: e?.Rate,
    }));
    dispatch(
      insertPlannerItem({
        dataItem: selectedTours,
        arrayName: "SightseenList",
        plannerRowID: DayNo,
      })
    );
    navigate("/planner");
  };

  // const handleAddMoreOptionsClick = (index: number) => {
  //   setOpenCardIndices((prevState: any) =>
  //     prevState.includes(index)
  //       ? prevState.filter((i: any) => i !== index)
  //       : [...prevState, index]
  //   );
  // };

  // const isDescriptionOpen = (index: number) => {
  //   return openCardIndices.includes(index);
  // };

  const handleSubmit = async (values: any) => {
    const LocalPassengerDetail = sessionStorage.getItem("PassengerDetails");
    if (typeof LocalPassengerDetail === "string") {
      const PassengerDetails = JSON.parse(LocalPassengerDetail);
      if (values?.SectorID) {
        const payload = {
          SectorID: values?.SectorID,
          SightseenTiming: values?.SightseenTiming,
          PassengerDetails: PassengerDetails,
        };
        await dispatch(getAllActiveTourSightseen(payload));
      }
    }
  };

  return (
    <>
      <GridLayout gap={{ rows: 0, cols: 10 }} cols={[{ width: "100%" }]}>
        <GridLayoutItem>
          <ShadowCard>
            <GridLayout cols={[{ width: "100%" }]}>
              <GridLayoutItem>
                <Form
                  onSubmit={handleSubmit}
                  initialValues={TourDetail}
                  render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{ width: "100%" }}>
                      <GridLayout
                        gap={{ rows: 10, cols: 10 }}
                        cols={[
                          { width: "10%" },
                          { width: "35%" },
                          { width: "35%" },
                          { width: "20%" },
                        ]}
                      >
                        <GridLayoutItem></GridLayoutItem>
                        <GridLayoutItem
                          className="radiogroup-alighment"
                          style={{ display: "flex", alignItems: "end" }}
                        >
                          <Field
                            className="radiogroup"
                            id={"SightseenTiming"}
                            name={"SightseenTiming"}
                            // label={"Gender"}
                            layout={"horizontal"}
                            component={FormRadioGroup}
                            data={DAYTYPE}
                          />
                        </GridLayoutItem>
                        <GridLayoutItem style={{ width: "100%" }}>
                          <Field
                            id={"SectorID"}
                            name={"SectorID"}
                            label={"Destination"}
                            component={FormSelectionField}
                            disabled={true}
                            options={SectorList?.map((sector: ISector) => {
                              return {
                                value: sector?.ID,
                                label: sector?.SectorName,
                              };
                            })}
                          />
                        </GridLayoutItem>
                        <GridLayoutItem></GridLayoutItem>
                        <GridLayoutItem
                          colSpan={4}
                          style={{
                            textAlign: "center",
                            marginTop: 20,
                          }}
                        >
                          <div>
                            <ButtonWithLoading
                              label="Search"
                              type="submit"
                              disabled={!formRenderProps.allowSubmit}
                              //   loading={formLoading}
                            />
                          </div>
                        </GridLayoutItem>
                      </GridLayout>
                      <SectorChangeWatcher formRenderProps={formRenderProps} />
                    </FormElement>
                  )}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
        </GridLayoutItem>
        {TourList?.length > 0 && (
          <GridLayoutItem
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography.h4>Tour Details</Typography.h4>
            <div>
              <RippleButton
                style={{ marginTop: 5 }}
                onClick={() => handleAddtoPlanner()}
                disabled={checkedTours?.length < 1}
              >
                Add to Planner
              </RippleButton>
            </div>
          </GridLayoutItem>
        )}
        <GridLayoutItem>
          {loading ? (
            <LoadingPanel gridRef={gridRef} />
          ) : (
            TourList?.map((item: any) => {
              return (
                <ShadowCard
                  key={item?.ID}
                  orientation="horizontal"
                  style={{
                    padding: 10,
                    marginBottom: 10,
                  }}
                >
                  <Checkbox onChange={(e) => onChange(e, item?.ID)}></Checkbox>
                  <CardImage
                    src={item?.TourImage}
                    style={{
                      height: "137px",
                      width: "200px",
                      marginLeft: 10,
                      background: "whitesmoke",
                      borderRadius: 5,
                    }}
                  />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <CardBody style={{ padding: "0 10px 0" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography.h3>{item?.SightseenName} </Typography.h3>
                        <span className="text-end">
                          <div className="pb-2">
                            Rate : <b>INR {item?.Rate}/-</b>
                          </div>
                        </span>
                        {/* <span className="text-end">
                          <div className="pb-2">
                            Adults Rate : <b>INR {item?.AdultRate}/-</b>
                          </div>
                          <div>
                            Child Rate : <b>INR {item?.ChildRate}/-</b>
                          </div>
                        </span> */}
                      </div>
                      <p>{item?.Description}</p>
                      {/* <div style={{ display: "flex", gap: 20 }}>
                        <RippleButton
                          style={{ marginTop: 5 }}
                          onClick={() => handleAddMoreOptionsClick(index)}
                        >
                          {isDescriptionOpen(index)
                            ? "Remove More Options"
                            : "Add More Options"}
                        </RippleButton>
                        <RippleButton
                          style={{ marginTop: 5 }}
                          // onClick={() => navigate("/planner")}
                        >
                          Entrance Included
                        </RippleButton>
                        <Field
                          name={"FromDate"}
                          label="From Date"
                          format="dd/MM/yyyy"
                          component={FormDatePicker}
                        />
                        <div style={{ display: "flex", alignItems: "center" }}>
                          05-Apr-2024
                        </div>
                      </div>
                      {isDescriptionOpen(index) && (
                        <div>Your description div</div>
                      )} */}
                    </CardBody>
                  </div>
                </ShadowCard>
              );
            })
          )}
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default TourDetails;
