import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import RippleButton from "../../components/common/RippleButton";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import {
  createTransfer,
  getTransferByID,
  updateTransfer,
  uploadTransferImage,
} from "./services/transfer.services";
import { clearTransferDetails } from "./transferSlice";
import {
  getAllActiveTransferLocation,
  getTransferLocationsByCityID,
} from "../transferlocation/services/transferLocation.services";
import {
  getAllActiveCities,
  getCityBySectorID,
} from "../city/services/city.services";
import { getAllActiveSectors } from "../sector/services/sector.services";
import { getAllActiveVehicleTypes } from "../vehicleType/services/vehicleType.services";
import { getAllActiveTermsAndConditions } from "../termsandcondition/services/termsAndCondition.services";
import { ISector } from "../sector/sectorModel";
import { ICity } from "../city/cityModel";
import { ICityTransferLocation } from "../transferlocation/transferLocationModel";
import { IVehicleType } from "../vehicleType/vehicleTypeModel";
import { ITermsAndCondition } from "../termsandcondition/termsAndConditionModel";
import TransferRateArray from "./TransferRateArray";
import moment from "moment";
import FormImageUpload from "../../components/formFields/FormImageUpload";
import ShadowCard from "../../components/common/ShadowCard";
import { STATUSARRAY } from "../../_contstants/common";
import { getAllActiveCurrencies } from "../currency/services/currency.services";
import { clearCityTransferLocationList } from "../transferlocation/transferLocationSlice";
import { clearSectorCityList } from "../city/citySlice";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const SectorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const isSectorRef = useRef(true);
  const SectorID = formRenderProps.valueGetter("SectorID");
  useEffect(() => {
    if (SectorID) {
      dispatch(getCityBySectorID(SectorID));
    }
    if (!isSectorRef.current) {
      formRenderProps.onChange("CityID", {
        value: null,
      });
      dispatch(clearCityTransferLocationList());
      dispatch(clearSectorCityList());
    } else {
      isSectorRef.current = false;
    }
  }, [SectorID]);
  return null;
};

const CityChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const isCItyRef = useRef(true);
  const CityID = formRenderProps.valueGetter("CityID");
  useEffect(() => {
    if (CityID) {
      dispatch(getTransferLocationsByCityID(+CityID));
    }
    if (!isCItyRef.current) {
      formRenderProps.onChange("FromLocationID", {
        value: null,
      });
      formRenderProps.onChange("ToLocationID", {
        value: null,
      });
      dispatch(clearCityTransferLocationList());
    } else {
      isCItyRef.current = false;
    }
  }, [CityID]);
  return null;
};

const CreateTransfer: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const TransferID = location.state?.TransferID;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state: RootState) => state.transfer.loading);
  const TransferDetail = useAppSelector(
    (state: RootState) => state.transfer.TransferDetail
  );
  // const CityList = useAppSelector((state: RootState) => state.city.CityList);
  const SectorCityList = useAppSelector((state) => state.city.SectorCityList);
  const SectorList = useAppSelector(
    (state: RootState) => state.sector.SectorList
  );
  const TermsAndConditionList = useAppSelector(
    (state: RootState) => state.termsandcondition.TermsAndConditionList
  );
  const VehicleTypeList = useAppSelector(
    (state: RootState) => state.vehicleType.VehicleTypeList
  );
  const CityTransferLocationList = useAppSelector(
    (state: RootState) => state.transferLocation.CityTransferLocationList
  );

  useEffect(() => {
    if (TransferID) {
      dispatch(getTransferByID(TransferID));
    }
    dispatch(getAllActiveTransferLocation());
    dispatch(getAllActiveCities());
    dispatch(getAllActiveSectors());
    dispatch(getAllActiveCurrencies());
    dispatch(getAllActiveVehicleTypes());
    dispatch(getAllActiveTermsAndConditions());
    return () => {
      dispatch(clearTransferDetails());
    };
  }, [TransferID]);

  const handleSubmit = async (values: any) => {
    const TransferImage = values.TransferImage;
    if (TransferID) {
      const editPayload = {
        ID: values.ID,
        TransferName: values.TransferName,
        SectorID: values.SectorID,
        FromLocationID: values.FromLocationID,
        ToLocationID: values.ToLocationID,
        CityID: values.CityID,
        VehicleTypeID: values.VehicleTypeID,
        TermsAndConditionsID: values.TermsAndConditionsID,
        Pax: values.Pax ? +values.Pax : null,
        IsActive: values.IsActive === 1,
        TrasferRateList: values.TrasferRateList
          ? values.TrasferRateList.map((rate: any) => {
              return {
                FromDate: rate.FromDate
                  ? moment(rate.FromDate).format("YYYY-MM-DD")
                  : "",
                ToDate: rate.ToDate
                  ? moment(rate.ToDate).format("YYYY-MM-DD")
                  : "",
                CurrencyID: rate.CurrencyID ? +rate.CurrencyID : null,
                OnewayRate: rate.OnewayRate ? +rate.OnewayRate : null,
                TwowayRate: rate.TwowayRate ? +rate.TwowayRate : null,
              };
            })
          : [],
      };
      try {
        const response = await dispatch(updateTransfer(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          if (
            TransferImage &&
            TransferID &&
            typeof TransferImage !== "string"
          ) {
            const ImageData = new FormData();
            const file = TransferImage[0].getRawFile();
            ImageData.append("ID", TransferID);
            ImageData.append("TransferImage", file);
            const responseData = await dispatch(uploadTransferImage(ImageData));
            if (responseData?.meta?.requestStatus === "fulfilled") {
              navigate(-1);
            } else {
              navigate(-1);
            }
          } else {
            navigate(-1);
          }
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      const insertPayload = {
        TransferName: values.TransferName,
        SectorID: values.SectorID,
        FromLocationID: values.FromLocationID,
        ToLocationID: values.ToLocationID,
        CityID: values.CityID,
        VehicleTypeID: values.VehicleTypeID,
        TermsAndConditionsID: values.TermsAndConditionsID,
        Pax: values.Pax ? +values.Pax : null,
        IsActive: values.IsActive === 1,
        TrasferRateList: values.TrasferRateList
          ? values.TrasferRateList.map((rate: any) => {
              return {
                FromDate: rate.FromDate
                  ? moment(rate.FromDate).format("YYYY-MM-DD")
                  : "",
                ToDate: rate.ToDate
                  ? moment(rate.ToDate).format("YYYY-MM-DD")
                  : "",
                CurrencyID: rate.CurrencyID ? +rate.CurrencyID : null,
                OnewayRate: rate.OnewayRate ? +rate.OnewayRate : null,
                TwowayRate: rate.TwowayRate ? +rate.TwowayRate : null,
              };
            })
          : [],
      };
      try {
        const response = await dispatch(createTransfer(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          if (
            TransferImage &&
            response.payload.Data.ID &&
            typeof TransferImage !== "string"
          ) {
            const ImageData = new FormData();
            const file = TransferImage[0].getRawFile();
            ImageData.append("ID", response.payload.Data.ID);
            ImageData.append("TransferImage", file);
            const responseData = await dispatch(uploadTransferImage(ImageData));
            if (responseData?.meta?.requestStatus === "fulfilled") {
              navigate(-1);
            } else {
              navigate(-1);
            }
          } else {
            navigate(-1);
          }
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={TransferDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {TransferID ? "Update Transfer" : "Create Transfer"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="TransferName"
                    maxLength="100"
                    label="Transfer Name"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id={"SectorID"}
                    name={"SectorID"}
                    label={"Sector"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={SectorList.map((sector: ISector) => {
                      return {
                        value: sector.ID,
                        label: sector.SectorName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id={"CityID"}
                    name={"CityID"}
                    label={"City"}
                    component={FormSelectionField}
                    // validator={requiredValidator}
                    options={SectorCityList.map((city: ICity) => {
                      return {
                        value: city.ID,
                        label: city.CityName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id={"FromLocationID"}
                    name={"FromLocationID"}
                    label={"From Location"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={CityTransferLocationList.map(
                      (location: ICityTransferLocation) => {
                        return {
                          value: location.ID,
                          label: location.LocationName,
                        };
                      }
                    )}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id={"ToLocationID"}
                    name={"ToLocationID"}
                    label={"To Location"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={CityTransferLocationList.map(
                      (location: ICityTransferLocation) => {
                        return {
                          value: location.ID,
                          label: location.LocationName,
                        };
                      }
                    )}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id={"VehicleTypeID"}
                    name={"VehicleTypeID"}
                    label={"Vehicle Type"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={VehicleTypeList.map((country: IVehicleType) => {
                      return {
                        value: country.ID,
                        label: country.VehicleType,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="Pax"
                    maxLength="100"
                    type="number"
                    label="Pax"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>

                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id={"TermsAndConditionsID"}
                    name={"TermsAndConditionsID"}
                    label={"Terms And Conditions"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={TermsAndConditionList.map(
                      (tc: ITermsAndCondition) => {
                        return {
                          value: tc.ID,
                          label: tc.TermsAndConditions,
                        };
                      }
                    )}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`IsActive`}
                    label="Status"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={STATUSARRAY?.map((status: any) => {
                      return {
                        value: status?.value,
                        label: status?.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ marginTop: 10 }} colSpan={3}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={TransferRateArray}
                    name="TrasferRateList"
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="TransferImage"
                    label="Image"
                    component={FormImageUpload}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={2}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <RippleButton
                    type="button"
                    fillMode="outline"
                    themeColor="primary"
                    style={{ marginRight: 4 }}
                    onClick={() => navigate("/transfer")}
                  >
                    Cancel
                  </RippleButton>
                  <ButtonWithLoading
                    label={TransferID ? "Update" : "Create"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <SectorChangeWatcher formRenderProps={formRenderProps} />
            <CityChangeWatcher formRenderProps={formRenderProps} />
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateTransfer;
