import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  createSightseenType,
  getAllSightseenTypes,
  getSightseenTypeByID,
  updateSightseenType,
} from "./services/sightseenType.services";
import { clearSightseenTypeDetails } from "./sightseenTypeSlice";
import { closed } from "../../components/drawer/drawerSlice";

const CreateSightseenType: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const SightseenTypeDetail = useAppSelector(
    (state) => state.sightseenType.SightseenTypeDetail
  );
  const SightseenTypeID = useAppSelector((state) => state.drawer.data);
  const formLoading = useAppSelector(
    (state) => state.sightseenType.formLoading
  );

  useEffect(() => {
    if (SightseenTypeID) {
      dispatch(getSightseenTypeByID(SightseenTypeID));
    }
  }, [SightseenTypeID]);

  useEffect(() => {
    return () => {
      dispatch(clearSightseenTypeDetails());
      dispatch(closed());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      if (!SightseenTypeID) {
        const insertPayload = {
          SightseenType: values.SightseenType,
          IsActive: values.IsActive ? values.IsActive : true,
        };
        const response = await dispatch(createSightseenType(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearSightseenTypeDetails());
          dispatch(getAllSightseenTypes());
        }
      } else {
        const payload = {
          ID: SightseenTypeID,
          SightseenType: values.SightseenType,
          IsActive: values.IsActive ? values.IsActive : false,
        };
        const response = await dispatch(updateSightseenType(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearSightseenTypeDetails());
          dispatch(getAllSightseenTypes());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };


  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
            <Form
              onSubmit={handleSubmit}
              initialValues={SightseenTypeDetail}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement style={{ width: "100%" }}>
                  <GridLayout
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                    gap={{ rows: 10 }}
                    cols={[{ width: "100%" }]}
                  >
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        name="SightseenType"
                        label="Sightseen Type"
                        component={FormTextField}
                        validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem
                      colSpan={3}
                      style={{
                        textAlign: "end",
                        marginTop: "20px",
                        width: "100%",
                      }}
                    >
                      <Button
                        type="button"
                        fillMode={"outline"}
                        themeColor={"primary"}
                        style={{ marginRight: 4 }}
                        onClick={handleFormClose}
                      >
                        Cancel
                      </Button>
                      <ButtonWithLoading
                        label={SightseenTypeID ? "Update" : "Create"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || formLoading}
                        loading={formLoading}
                      />
                    </GridLayoutItem>
                  </GridLayout>
                </FormElement>
              )}
            />
  );
};

export default CreateSightseenType;
