import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ISightseen,
  ISightseenCancellation,
  ISightseenInitialState,
  ISightseenRate,
} from "./sightseenModel";
import {
  createSightseen,
  createSightseenCancellation,
  createSightseenRate,
  deleteSightseen,
  deleteSightseenCancellation,
  deleteSightseenRate,
  getAllCancellationBySightseenID,
  getAllRateBySightseenID,
  getAllSightseens,
  getSightseenByID,
  getSightseenCancellationByID,
  getSightseenRateByID,
  updateSightseen,
  updateSightseenCancellation,
  updateSightseenRate,
} from "./services/sightseen.services";

const initialState: ISightseenInitialState = {
  loading: false,
  rateLoading: false,
  CCLoading: false,
  error: "",
  SightseenID: null,
  SightseenList: [],
  SightseenDetail: {
    ID: null,
    SightseenName: "",
    SightseenTypeID: null,
    Description: "",
    VehicleTypeID: null,
    Address: "",
    CountryID: null,
    CityID: null,
    StateID: null,
    SectorID: null,
    TermsAndConditionsID: null,
    SightseenTiming: null,
    IsActive: 1,
  },
  SightseenRateID: null,
  SightseenRateList: [],
  SightseenRateDetail: {
    ID: null,
    SightseenID: null,
    NationalityID: null,
    FromDate: "",
    ToDate: "",
    AdultRate: null,
    ChildRate: null,
    IsActive: true,
  },
  CancellationChargeID: null,
  SightseenCancellationList: [],
  SightseenCancellationDetail: {
    ID: null,
    SightseenID: null,
    FromDays: null,
    ToDays: null,
    CancellationCharges: null,
    IsActive: true,
  },
};

const sightseenSlice = createSlice({
  name: "sightseen",
  initialState,
  reducers: {
    clearSightseenDetails: (state) => {
      state.SightseenDetail = initialState.SightseenDetail;
    },
    setSightseenID: (state, action) => {
      state.SightseenID = action.payload;
    },
    clearSightseenID: (state) => {
      state.SightseenID = initialState.SightseenID;
    },
    setSightseenRateID: (state, action) => {
      state.SightseenRateID = action.payload;
    },
    clearSightseenRateID: (state) => {
      state.SightseenRateID = initialState.SightseenRateID;
    },
    setSightseenRateDetails: (state, action) => {
      state.SightseenRateDetail = action.payload;
    },
    clearSightseenRateDetails: (state) => {
      state.SightseenRateDetail = initialState.SightseenRateDetail;
    },
    setCancellationChargeID: (state, action) => {
      state.CancellationChargeID = action.payload;
    },
    clearCancellationChargeID: (state) => {
      state.CancellationChargeID = initialState.CancellationChargeID;
    },
    setCancellationChargeDetails: (state, action) => {
      state.SightseenCancellationDetail = action.payload;
    },
    clearCancellationChargeDetails: (state) => {
      state.SightseenCancellationDetail =
        initialState.SightseenCancellationDetail;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSightseens.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllSightseens.fulfilled,
      (state, action: PayloadAction<ISightseen[]>) => {
        state.loading = false;
        state.SightseenList = action.payload || [];
      }
    );
    builder.addCase(getAllSightseens.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SightseenList = [];
    });
    builder.addCase(createSightseen.pending, (state) => {
      //   state.loading = true;
      state.error = "";
    });
    builder.addCase(createSightseen.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createSightseen.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateSightseen.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateSightseen.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateSightseen.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteSightseen.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteSightseen.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteSightseen.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getSightseenByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getSightseenByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.SightseenDetail = action.payload;
    });
    builder.addCase(getSightseenByID.rejected, (state, action) => {
      state.loading = false;
      state.SightseenDetail = initialState.SightseenDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getAllRateBySightseenID.pending, (state) => {
      state.rateLoading = true;
      state.error = "";
    });
    builder.addCase(
      getAllRateBySightseenID.fulfilled,
      (state, action: PayloadAction<ISightseenRate[]>) => {
        state.rateLoading = false;
        state.SightseenRateList = action.payload || [];
      }
    );
    builder.addCase(getAllRateBySightseenID.rejected, (state, action) => {
      state.rateLoading = false;
      state.error = action.error.message || "Something went wrong";
      state.SightseenRateList = [];
    });
    builder.addCase(createSightseenRate.pending, (state) => {
      state.rateLoading = true;
      state.error = "";
    });
    builder.addCase(createSightseenRate.fulfilled, (state) => {
      state.rateLoading = false;
    });
    builder.addCase(createSightseenRate.rejected, (state, action) => {
      state.rateLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateSightseenRate.pending, (state) => {
      state.rateLoading = true;
      state.error = "";
    });
    builder.addCase(updateSightseenRate.fulfilled, (state) => {
      state.rateLoading = false;
    });
    builder.addCase(updateSightseenRate.rejected, (state, action) => {
      state.rateLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteSightseenRate.pending, (state) => {
      state.rateLoading = true;
      state.error = "";
    });
    builder.addCase(deleteSightseenRate.fulfilled, (state) => {
      state.rateLoading = false;
    });
    builder.addCase(deleteSightseenRate.rejected, (state, action) => {
      state.rateLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getSightseenRateByID.pending, (state) => {
      state.rateLoading = true;
      state.error = "";
    });
    builder.addCase(getSightseenRateByID.fulfilled, (state, action: any) => {
      state.rateLoading = false;
      state.SightseenRateDetail = action.payload;
    });
    builder.addCase(getSightseenRateByID.rejected, (state, action) => {
      state.rateLoading = false;
      state.SightseenRateDetail = initialState.SightseenRateDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getAllCancellationBySightseenID.pending, (state) => {
      state.CCLoading = true;
      state.error = "";
    });
    builder.addCase(
      getAllCancellationBySightseenID.fulfilled,
      (state, action: PayloadAction<ISightseenCancellation[]>) => {
        state.CCLoading = false;
        state.SightseenCancellationList = action.payload || [];
      }
    );
    builder.addCase(
      getAllCancellationBySightseenID.rejected,
      (state, action) => {
        state.CCLoading = false;
        state.error = action.error.message || "Something went wrong";
        state.SightseenCancellationList = [];
      }
    );
    builder.addCase(createSightseenCancellation.pending, (state) => {
      state.CCLoading = true;
      state.error = "";
    });
    builder.addCase(createSightseenCancellation.fulfilled, (state) => {
      state.CCLoading = false;
    });
    builder.addCase(createSightseenCancellation.rejected, (state, action) => {
      state.CCLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateSightseenCancellation.pending, (state) => {
      state.CCLoading = true;
      state.error = "";
    });
    builder.addCase(updateSightseenCancellation.fulfilled, (state) => {
      state.CCLoading = false;
    });
    builder.addCase(updateSightseenCancellation.rejected, (state, action) => {
      state.CCLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteSightseenCancellation.pending, (state) => {
      state.CCLoading = true;
      state.error = "";
    });
    builder.addCase(deleteSightseenCancellation.fulfilled, (state) => {
      state.CCLoading = false;
    });
    builder.addCase(deleteSightseenCancellation.rejected, (state, action) => {
      state.CCLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getSightseenCancellationByID.pending, (state) => {
      state.CCLoading = true;
      state.error = "";
    });
    builder.addCase(
      getSightseenCancellationByID.fulfilled,
      (state, action: any) => {
        state.CCLoading = false;
        state.SightseenCancellationDetail = action.payload;
      }
    );
    builder.addCase(getSightseenCancellationByID.rejected, (state, action) => {
      state.CCLoading = false;
      state.SightseenCancellationDetail =
        initialState.SightseenCancellationDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearSightseenDetails,
  setSightseenID,
  clearSightseenID,
  setSightseenRateID,
  clearSightseenRateID,
  setSightseenRateDetails,
  clearSightseenRateDetails,
  setCancellationChargeID,
  clearCancellationChargeID,
  setCancellationChargeDetails,
  clearCancellationChargeDetails,
} = sightseenSlice.actions;
export default sightseenSlice.reducer;
