import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AddSightseenQuotation,
  AddToCartSightseenSearch,
  deleteSightseenCart,
  getAllSightseenCartDetails,
  getAllSightseenQuotationDetails,
  getAllSightseenSearchs,
} from "./services/sightseenSearch.services";
import {
  ISightseenCartList,
  ISightseenQuotationDetailsList,
  ISightseenSearch,
  ISightseenSearchState,
} from "./sightseenSearchModel";

const initialState: ISightseenSearchState = {
  loading: false,
  error: "",
  SightseenSearchID: null,
  SightseenSearchList: [],
  SightseenSearchDetail: {
    VehicleTypeID: null,
    CountryID: null,
    CityID: null,
    NoOfAdult: "1",
    NoOfChild: "0",
    NationalityID: null,
    SightseenDate: "",
  },
  SightseenCartList: [],
  AddtoCartSightseenDetails: {
    SightseenID: null,
    AdultRate: null,
    ChildRate: null,
    TotalRate: null,
    Remarks: "",
  },
  SightseenGuestDetails: {
    Initial: "",
    FirstName: "",
    LastName: "",
    MobileNo: "",
    AlternateMobileNo: "",
    BookingConfirmedBy: "",
    MailedQuotation: "",
    Remarks: "",
    IsActive: true,
  },
  SightseenQuotationDetailsList: [],
};

const sightseenSearchSlice = createSlice({
  name: "sightseenSearch",
  initialState,
  reducers: {
    setSightseenSearchID: (state, action) => {
      state.SightseenSearchID = action.payload;
    },
    clearSightseenSearchID: (state) => {
      state.SightseenSearchID = initialState.SightseenSearchID;
    },
    setSightseenSearchDetails: (state, action) => {
      state.SightseenSearchDetail = action.payload;
    },
    clearSightseenSearchDetails: (state) => {
      state.SightseenSearchDetail = initialState.SightseenSearchDetail;
    },
    setAddToCartSightseenDetails: (state, action) => {
      state.AddtoCartSightseenDetails = action.payload;
    },
    clearAddToCartSightseenDetails: (state) => {
      state.AddtoCartSightseenDetails = initialState.AddtoCartSightseenDetails;
    },
    clearSightseenGuestDetails: (state) => {
      state.SightseenGuestDetails = initialState.SightseenGuestDetails;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSightseenSearchs.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllSightseenSearchs.fulfilled,
      (state, action: PayloadAction<ISightseenSearch[]>) => {
        state.loading = false;
        state.SightseenSearchList = action.payload || [];
      }
    );
    builder.addCase(getAllSightseenSearchs.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SightseenSearchList = [];
    });
    builder.addCase(AddToCartSightseenSearch.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(AddToCartSightseenSearch.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(AddToCartSightseenSearch.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllSightseenCartDetails.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllSightseenCartDetails.fulfilled,
      (state, action: PayloadAction<ISightseenCartList[]>) => {
        state.loading = false;
        state.SightseenCartList = action.payload || [];
      }
    );
    builder.addCase(getAllSightseenCartDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SightseenCartList = [];
    });
    builder.addCase(deleteSightseenCart.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteSightseenCart.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteSightseenCart.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(AddSightseenQuotation.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(AddSightseenQuotation.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(AddSightseenQuotation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllSightseenQuotationDetails.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllSightseenQuotationDetails.fulfilled,
      (state, action: PayloadAction<ISightseenQuotationDetailsList[]>) => {
        state.loading = false;
        state.SightseenQuotationDetailsList = action.payload || [];
      }
    );
    builder.addCase(
      getAllSightseenQuotationDetails.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.SightseenQuotationDetailsList = [];
      }
    );
  },
});

export const {
  setSightseenSearchID,
  clearSightseenSearchID,
  setSightseenSearchDetails,
  clearSightseenSearchDetails,
  setAddToCartSightseenDetails,
  clearAddToCartSightseenDetails,
  clearSightseenGuestDetails,
} = sightseenSearchSlice.actions;
export default sightseenSearchSlice.reducer;
