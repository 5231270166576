import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRoomCategory, IRoomCategoryInitialState } from "./roomCategoryModel";
import {
  ActiveInactiveRoomCategory,
  createRoomCategory,
  deleteRoomCategory,
  getAllActiveRoomCategories,
  getAllRoomCategories,
  getRoomCategoryByID,
  updateRoomCategory,
} from "./services/roomCategory.services";

const initialState: IRoomCategoryInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  RoomCategoryList: [],
  RoomCategoryID: null,
  RoomCategoryDetail: {
    RoomCategory: "",
    IsActive: 1,
  },
};

const roomCategorySlice = createSlice({
  name: "roomCategory",
  initialState,
  reducers: {
    clearRoomCategoryDetails: (state) => {
      state.RoomCategoryDetail = initialState.RoomCategoryDetail;
    },
    setRoomCategoryID: (state, action) => {
      state.RoomCategoryID = action.payload;
    },
    clearRoomCategoryID: (state) => {
      state.RoomCategoryID = initialState.RoomCategoryID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRoomCategories.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllRoomCategories.fulfilled,
      (state, action: PayloadAction<IRoomCategory[]>) => {
        state.loading = false;
        state.RoomCategoryList = action.payload || [];
      }
    );
    builder.addCase(getAllRoomCategories.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RoomCategoryList = [];
    });
    builder.addCase(createRoomCategory.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createRoomCategory.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createRoomCategory.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateRoomCategory.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateRoomCategory.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateRoomCategory.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteRoomCategory.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteRoomCategory.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteRoomCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getRoomCategoryByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getRoomCategoryByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.RoomCategoryDetail = action.payload;
    });
    builder.addCase(getRoomCategoryByID.rejected, (state, action) => {
      state.formLoading = false;
      state.RoomCategoryDetail = initialState.RoomCategoryDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveRoomCategories.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveRoomCategories.fulfilled,
      (state, action: PayloadAction<IRoomCategory[]>) => {
        state.loading = false;
        state.RoomCategoryList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveRoomCategories.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RoomCategoryList = [];
    });
    builder.addCase(ActiveInactiveRoomCategory.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveRoomCategory.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveRoomCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearRoomCategoryDetails,
  clearRoomCategoryID,
  setRoomCategoryID,
} = roomCategorySlice.actions;
export default roomCategorySlice.reducer;
