import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

export const getAllFixedORCustomizedPackageByTour = createAsyncThunk(
  "Tour/FixedORCustomizedPackageByTour",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/FixedORCustomizedPackageByTour`,
        formdata
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Customized Packages by Tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const insertTourQuotation = createAsyncThunk(
  "Tour/TourQuotationInsert",
  async (MealTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/TourQuotationInsert`,
        MealTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error inserting tour quotation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateTourQuotation = createAsyncThunk(
  "Tour/TourQuotationUpdate",
  async (MealTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/TourQuotationUpdate`,
        MealTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating tour quotation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllPlannerQuotation = createAsyncThunk(
  "Tour/FindAllQuotation",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Tour/FindAllQuotation`
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Quotations:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourQuotationByID = createAsyncThunk(
  "Tour/TourQuotationFindByID",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/TourQuotationFindByID`,
        { TourQuotationID: ID }
      );
      return response.data?.Data as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching meal category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
