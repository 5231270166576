import React from "react";
import {
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  CodeSandboxOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

export interface IMenu {
  text: string;
  data?: any;
  items: IMenu[];
}

const items: MenuItem[] = [
  getItem("Dashboard", "", <PieChartOutlined />),
  getItem("Masters", "masters", <UserOutlined />, [
    getItem("Country", "country"),
    getItem("State", "state"),
    getItem("City", "city"),
    getItem("Company", "company"),
    getItem("Nationality", "nationality"),
    getItem("Sightseen Type", "sightseentype"),
    getItem("Sector", "sector"),
    getItem("Transfer Location", "transferlocation"),
    getItem("Transfer", "transfer"),
    getItem("Region", "region"),
    getItem("Terms And Conditions", "termsandcondition"),
    getItem("Sightseen", "sightseen"),
    getItem("GST", "gst"),
    getItem("Currency", "currency"),
    getItem("Meal", "meal"),
    getItem("Meal Category", "mealcategory"),
    getItem("Room Type", "roomtype"),
    getItem("Room Category", "roomcategory"),
    getItem("Speciality Type", "specialitytype"),
    getItem("Vehicle Type", "vehicletype"),
    getItem("Quotation", "plannerquotation"),
    getItem("Tour", "tour"),
    getItem("Hotel", "hotel"),
  ]),
  getItem("Sightseen Search", "sightseensearch", <TeamOutlined />),
  getItem("Transfer Search", "transfersearch", <FileOutlined />),
  getItem("Readymade Packages", "readymadepackage", <CodeSandboxOutlined />),
];

interface HeaderProps {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClick: MenuProps["onClick"] = (e) => {
    navigate(e.key);
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className="drawer-logo">
        <h4 style={{ textAlign: "center", color: "white" }}>JD</h4>
      </div>
      <Menu
        className="sider-scroll"
        style={{
          overflow: "auto",
          height: "calc(100vh - 115px)",
        }}
        onClick={onClick}
        theme="dark"
        defaultSelectedKeys={[""]}
        selectedKeys={[location.pathname?.substring(1)?.split("/")[0]]}
        mode="inline"
        items={items}
      />
    </Sider>
  );
};

export default Header;
