import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  activeInActiveUsers,
  createUser,
  deleteUser,
  getAllActiveUsers,
  getAllUsers,
  getUserByID,
  updateUser,
} from "./services/user.services";
import { IUser, IUserDetails } from "./userModel";

export interface IUserState {
  loading: boolean;
  error: string;
  UserID: null | number;
  UserList: IUser[];
  UserDetails: IUserDetails;
}

const initialState: IUserState = {
  loading: false,
  error: "",
  UserList: [],
  UserID: null,
  UserDetails: {
    ID: null,
    FirstName: "",
    LastName: "",
    MobileNo: "",
    AlternateMobileNo: "",
    EmailID: "",
    AlternateEmailID: "",
    Password: "",
    IsActive: false,
    UserTypeID: null,
    IsNationality: false,
    IsCountry: false,
    IsVehicleType: false,
    IsTermsAndConditions: false,
    IsState: false,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUserDetails: (state) => {
      state.UserDetails = initialState.UserDetails;
    },
    setUserID: (state, action) => {
      state.UserID = action.payload;
    },
    clearUserID: (state) => {
      state.UserID = initialState.UserID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAllUsers.fulfilled,
      (state, action: PayloadAction<IUser[]>) => {
        state.loading = false;
        state.UserList = action.payload || [];
        state.error = "";
      }
    );
    builder.addCase(getAllUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.UserList = [];
    });
    builder.addCase(createUser.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createUser.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateUser.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getUserByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getUserByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.UserDetails = action.payload;
    });
    builder.addCase(getUserByID.rejected, (state, action) => {
      state.loading = false;
      state.UserDetails = initialState.UserDetails;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteUser.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveUsers.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveUsers.fulfilled,
      (state, action: PayloadAction<IUser[]>) => {
        state.loading = false;
        state.UserList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.UserList = [];
    });
    builder.addCase(activeInActiveUsers.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(activeInActiveUsers.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(activeInActiveUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});
export const { clearUserDetails, setUserID, clearUserID } =
  userSlice.actions;
export default userSlice.reducer;
