import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Typography } from "@progress/kendo-react-common";
import RippleButton from "../../../components/common/RippleButton";
import AppDialog, {
  DialogCloseButton,
} from "../../../components/dialog/Dialog";
import { Button } from "@progress/kendo-react-buttons";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import AlertBox from "../../../components/common/AlertBox";
import ShadowCard from "../../../components/common/ShadowCard";
import IconButton from "../../../components/common/IconButton";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { LoadingPanel } from "../../../components/layout/Loading";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import {
  deleteTourMeal,
  getTourMealsByID,
  insertTourMeal,
  updateTourMeal,
} from "../services/tour.services";
import {
  clearTourMealDetails,
  clearTourMealID,
  decreaseActiveStep,
  setTourMealDetails,
  setTourMealID,
} from "../tourSlice";
import { getAllActiveMealCategories } from "../../mealcategory/services/mealCategory.services";
import { getAllActiveMeals } from "../../meals/services/meals.services";
import { generateDaysArray } from "../../../_helper/commonHelpers";
import { MEALTYPE } from "../../../_contstants/common";
import { IMealCategory } from "../../mealcategory/mealCategoryModel";
import { IMeals } from "../../meals/mealsModel";

const TourFixedMeals = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const TourID = location.state?.TourID;

  useEffect(() => {
    dispatch(getTourMealsByID(TourID));
  }, [TourID]);

  useEffect(() => {
    dispatch(getAllActiveMealCategories());
    dispatch(getAllActiveMeals());

    return () => {
      dispatch(clearTourMealDetails());
    };
  }, []);

  return (
    <>
      <DeleteTourMealsDialog />
      <TourMealsForm />
      <TourMealGridComponent />
    </>
  );
};

const TourMealsForm: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const TourID = location.state?.TourID;
  const NoOfDays = location.state?.NoOfDays;
  const loading = useAppSelector((state) => state.tour.loading);
  const TourMealDetails = useAppSelector((state) => state.tour.TourMealDetails);
  const MealCategoryList = useAppSelector(
    (state) => state.mealCategory.MealCategoryList
  );
  const MealsList = useAppSelector((state) => state.meals.MealsList);
  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [TourMealDetails]);

  const handleSubmit = async (values: any) => {
    try {
      if (!TourMealDetails?.ID) {
        const insertPayload = {
          ID: values.ID,
          TourID: TourID,
          DayNo: values.DayNo ? +values.DayNo : null,
          LunchORDinner: values.LunchORDinner ? +values.LunchORDinner : null,
          MealTypeID: values.MealTypeID ? +values.MealTypeID : null,
          MealID: values.MealID ? +values.MealID : null,
        };
        const response = await dispatch(insertTourMeal(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(getTourMealsByID(TourID));
          setFormKey(formKey + 1);
          dispatch(clearTourMealDetails());
        }
      } else {
        const editPayload = {
          ID: values.ID,
          TourID: TourID,
          DayNo: values.DayNo ? +values.DayNo : null,
          LunchORDinner: values.LunchORDinner ? +values.LunchORDinner : null,
          MealTypeID: values.MealTypeID ? +values.MealTypeID : null,
          MealID: values.MealID ? +values.MealID : null,
        };
        const response = await dispatch(updateTourMeal(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(getTourMealsByID(TourID));
          setFormKey(formKey + 1);
          dispatch(clearTourMealDetails());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      key={formKey}
      initialValues={TourMealDetails}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <ShadowCard style={{ padding: 10 }}>
            <GridLayout
              style={{ marginRight: 30 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
              ]}
            >
              <GridLayoutItem colSpan={4}>
                <Typography.h4 style={{ margin: 0 }}>
                  {"Add Tour Fixed Meal"}
                </Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`DayNo`}
                  label="Day"
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={generateDaysArray(+NoOfDays)}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`LunchORDinner`}
                  label="Lunch / Dinner"
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={MEALTYPE}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={"MealTypeID"}
                  label={"Meal Type"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={MealCategoryList?.map((mealtype: IMealCategory) => {
                    return {
                      value: mealtype?.ID,
                      label: mealtype?.Title,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={"MealID"}
                  label={"Meal"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={MealsList?.map((meal: IMeals) => {
                    return {
                      value: meal?.ID,
                      label: meal?.MealName,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem
                colSpan={4}
                style={{ textAlign: "end", marginTop: 10 }}
              >
                <RippleButton
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => dispatch(decreaseActiveStep())}
                >
                  Previous
                </RippleButton>
                <RippleButton
                  fillMode="solid"
                  themeColor="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => navigate("/tour")}
                >
                  Home
                </RippleButton>
                <RippleButton
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setFormKey(formKey + 1);
                    dispatch(clearTourMealDetails());
                  }}
                >
                  Cancel
                </RippleButton>
                <ButtonWithLoading
                  label={TourMealDetails?.ID ? "Update" : "Add"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || loading}
                  loading={loading}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
        </FormElement>
      )}
    />
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const TourMealGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const TourMealList = useAppSelector((state) => state.tour.TourMealList);
  const loading = useAppSelector((state) => state.tour.loading);

  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? TourMealList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (item: any) => {
      const payload = {
        ID: item.ID,
        TourID: item.TourID,
        DayNo: item.DayNo ? +item.DayNo : null,
        LunchORDinner: item.LunchORDinner ? +item.LunchORDinner : null,
        MealTypeID: item.MealTypeID ? +item.MealTypeID : null,
        MealID: item.MealID ? +item.MealID : null,
      };
      dispatch(setTourMealDetails(payload));
    };
    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteTourMeal"));
      dispatch(setTourMealID(ID));
    };

    return (
      <td
        className={props.className}
        style={{
          ...props.style,
          textAlign: "center",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEdit(props.dataItem)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem.ID)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 4 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {TourMealList && TourMealList.length ? (
        <KendoGrid
          style={{ marginTop: 20 }}
          data={TourMealList}
          skip={page.skip}
          take={page.take}
          total={TourMealList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column field="DayNo" title="Day No" />
          <Column
            field="LunchORDinner"
            title="Lunch / Dinner"
            cell={(props: GridCellProps) => (
              <td>
                {props.dataItem?.LunchORDinner === 1
                  ? "LUNCH"
                  : props.dataItem?.LunchORDinner === 2
                  ? "DINNER"
                  : ""}
              </td>
            )}
          />
          <Column field="MealType" title="Meal Type" />
          <Column field="MealName" title="Meal Name" />
          <Column
            field="ID"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
            locked={true}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteTourMealsDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const TourID = location.state?.TourID;
  const TourMealID = useAppSelector((state) => state.tour.TourMealID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteTourMeal(ID));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(getTourMealsByID(TourID));
        dispatch(closeDialog());
        dispatch(clearTourMealID());
      } else {
        dispatch(getTourMealsByID(TourID));
        dispatch(closeDialog());
        dispatch(clearTourMealID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteTourMeal" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Tour Meal"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Tour Meal?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(TourMealID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default TourFixedMeals;
