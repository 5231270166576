import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Layout from "../components/layout/Layout";
import LogIn from "../features/login/Login";
import Country from "../features/country/Country";
import User from "../features/user/User";
import CreateUser from "../features/user/CreateUser";
import State from "../features/state/State";
import City from "../features/city/City";
import Nationality from "../features/nationality/Nationality";
import Sector from "../features/sector/Sector";
import TermsAndCondition from "../features/termsandcondition/TermsAndCondition";
import VehicleType from "../features/vehicleType/VehicleType";
import MealType from "../features/mealtype/MealType";
import UserType from "../features/userType/UserType";
import TransferLocation from "../features/transferlocation/TransferLocation";
import Transfer from "../features/transfer/Transfer";
import CreateTransfer from "../features/transfer/CreateTransfer";
import TransferSearch from "../features/transfersearch/TransferSearchForm";
import TransferSearchList from "../features/transfersearch/TransferSearchList";
import TransferCart from "../features/transfersearch/TransferCart";
import TransferCustomerDetails from "../features/transfersearch/TransferCustomerDetails";
import QuotationList from "../features/transfersearch/QuotationList";
import SightseenType from "../features/sightseentype/SightseenType";
import Sightseen from "../features/sightseen/Sightseen";
import CreateSightseen from "../features/sightseen/CreateSightseen";
import Region from "../features/region/Region";
import SightseenSearchForm from "../features/sightseensearch/SightseenSearchForm";
import SightseenSearchList from "../features/sightseensearch/SightseenSearchList";
import SightseenCart from "../features/sightseensearch/SightseenCart";
import Tour from "../features/tour/Tour";
import CreateTour from "../features/tour/CreateTour";
import SightseenCustomerDetails from "../features/sightseensearch/SightseenCustomerDetails";
import SightseenQuotationList from "../features/sightseensearch/SightseenQuotationList";
import RoomType from "../features/roomtype/RoomType";
import SpecialityType from "../features/specialitytype/SpecialityType";
import Company from "../features/company/Company";
import CreateCompany from "../features/company/CreateCompany";
import Gst from "../features/gst/Gst";
// import PlannerCard from "../features/PlannerCard";
import Dashboard from "../features/dashboard/Dashboard";
import RippleButton from "../components/common/RippleButton";
import RoomCategory from "../features/roomcategory/RoomCategory";
import Currency from "../features/currency/Currency";
import Meal from "../features/meals/Meal";
import MealCategory from "../features/mealcategory/MealCategory";
import ReadymadePackage from "../features/readymadepackage/ReadymadePackage";
import PackageDetails from "../features/readymadepackage/PackageDetails";
import ToursAndTrasnfers from "../features/toursandtransfers/ToursAndTrasnfers";
import HotelAndResort from "../features/hotelandresorts/HotelAndResort";
import CustomizeSearch from "../features/customizesearch/CustomizeSearch";
import Planner from "../features/planner/Planner";
import PlannerQuotation from "../features/planner/PlannerQuotation";
import ConfirmPlanner from "../features/planner/ConfirmPlanner";
import Hotel from "../features/hotel/Hotel";
import CreateHotel from "../features/hotel/CreateHotel";

const MainRouter = () => {
  const navigate = useNavigate();
  return (
    <Routes>
      <Route path="login" element={<LogIn />} />
      <Route path="/" element={<Layout />}>
        <Route index element={<Dashboard />} />
        <Route path="planner" element={<Planner/>} />
        <Route path="country" element={<Country />} />
        <Route path="state" element={<State />} />
        <Route path="city" element={<City />} />
        <Route path="nationality" element={<Nationality />} />
        <Route path="sector" element={<Sector />} />
        <Route path="termsandcondition" element={<TermsAndCondition />} />
        <Route path="vehicleType" element={<VehicleType />} />
        <Route path="region" element={<Region />} />
        <Route path="mealtype" element={<MealType />} />
        <Route path="transferlocation" element={<TransferLocation />} />
        <Route path="user" element={<User />} />
        <Route path="user/create" element={<CreateUser />} />
        <Route path="user/edit" element={<CreateUser />} />
        <Route path="usertype" element={<UserType />} />
        <Route path="transfer" element={<Transfer />} />
        <Route path="transfer/create" element={<CreateTransfer />} />
        <Route path="transfer/edit" element={<CreateTransfer />} />
        <Route path="transfersearch" element={<TransferSearch />} />
        <Route path="transfersearchlist" element={<TransferSearchList />} />
        <Route path="transfercart" element={<TransferCart />} />
        <Route
          path="transfercustomerdetails"
          element={<TransferCustomerDetails />}
        />
        <Route path="transferquotation" element={<QuotationList />} />
        <Route path="sightseentype" element={<SightseenType />} />
        <Route path="sightseen" element={<Sightseen />} />
        <Route path="sightseen/create" element={<CreateSightseen />} />
        <Route path="sightseen/edit" element={<CreateSightseen />} />
        <Route path="sightseensearch" element={<SightseenSearchForm />} />
        <Route path="sightseensearchlist" element={<SightseenSearchList />} />
        <Route path="sightseencart" element={<SightseenCart />} />
        <Route
          path="sightseencustomerdetails"
          element={<SightseenCustomerDetails />}
        />
        <Route path="sightseenquotation" element={<SightseenQuotationList />} />
        <Route path="tour" element={<Tour />} />
        <Route path="tour/create" element={<CreateTour />} />
        <Route path="tour/edit" element={<CreateTour />} />
        <Route path="roomtype" element={<RoomType />} />
        <Route path="roomcategory" element={<RoomCategory />} />
        <Route path="specialitytype" element={<SpecialityType />} />
        <Route path="gst" element={<Gst />} />
        <Route path="currency" element={<Currency />} />
        <Route path="meal" element={<Meal />} />
        <Route path="mealcategory" element={<MealCategory />} />
        <Route path="company" element={<Company />} />
        <Route path="company/create" element={<CreateCompany />} />
        <Route path="company/edit" element={<CreateCompany />} />
        <Route path="readymadepackage" element={<ReadymadePackage />} />
        <Route path="package" element={<PackageDetails />} />
        <Route path="toursandtransfers" element={<ToursAndTrasnfers />} />
        <Route path="hotelandresort" element={<HotelAndResort />} />
        <Route path="customizesearch" element={<CustomizeSearch />} />
        <Route path="plannerquotation" element={<PlannerQuotation />} />
        <Route path="confirmplanner" element={<ConfirmPlanner/>} />
        <Route path="hotel" element={<Hotel/>} />
        <Route path="hotel/create" element={<CreateHotel/>} />
        <Route path="hotel/edit" element={<CreateHotel/>} />
      </Route>
      <Route
        path="*"
        element={
          <RippleButton
            onClick={() => navigate("/")}
            style={{ margin: "20px" }}
          >
            Back to Home
          </RippleButton>
        }
      />
    </Routes>
  );
};

export default MainRouter;
