import React, { useEffect } from "react";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormDatePicker from "../../components/formFields/FormDateField";
import { MdDelete } from "react-icons/md";
import IconButton from "../../components/common/IconButton";
import { setDeleteIndex } from "./transferSlice";
import moment from "moment";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { ICurrency } from "../currency/currencyModel";

const TransferRateArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const rateDeleteIndex = useAppSelector((state) => state.transfer.deleteIndex);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const dispatch = useAppDispatch();
  const CurrencyList = useAppSelector((state) => state.currency.CurrencyList);

  useEffect(() => {
    return () => {
      dispatch(closeDialog());
    };
  }, []);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("transferRateDeleteDialog"));
    dispatch(setDeleteIndex(index));
  };

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        FromDate: "",
        ToDate: "",
        OnewayRate: null,
        TwowayRate: null,
        CurrencyID: null,
      },
    });
  }, [fieldArrayRenderProps]);

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {fieldArrayRenderProps.value && fieldArrayRenderProps.value.length === 0
        ? pushElementInarray()
        : null}
      <GridLayoutItem style={{ marginBottom: 10 }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Transfer Rate
        </Button>
      </GridLayoutItem>
      {dialogName === "transferRateDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Transfer Rate"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(rateDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map(
          (TrasferRateList: any, index: number) => (
            <GridLayoutItem key={index}>
              <Card style={{ padding: 8, marginBottom: 8 }}>
                <GridLayout
                  style={{ marginRight: 40 }}
                  gap={{ rows: 0, cols: 10 }}
                  cols={[
                    { width: "20%" },
                    { width: "20%" },
                    { width: "20%" },
                    { width: "20%" },
                    { width: "20%" },
                  ]}
                >
                  <GridLayoutItem
                    colSpan={5}
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(index)}
                      disabled={fieldArrayRenderProps.value.length === 1}
                      themeColor={"error"}
                      size={"small"}
                      fillMode={"solid"}
                      type="button"
                      style={{ height: 24, width: 24 }}
                    >
                      <MdDelete
                        className="absolute-position"
                        style={{ fontSize: "16px" }}
                      />
                    </IconButton>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`TrasferRateList.${index}.FromDate`}
                      label="From Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                      minDate={
                        fieldArrayRenderProps.value[index - 1]?.ToDate
                          ? moment(
                              fieldArrayRenderProps.value[index - 1]?.ToDate
                            ).toDate()
                          : undefined
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`TrasferRateList.${index}.ToDate`}
                      label="To Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      minDate={
                        fieldArrayRenderProps.value[index]?.FromDate
                          ? moment(
                              fieldArrayRenderProps.value[index]?.FromDate
                            ).toDate()
                          : moment().toDate()
                      }
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`TrasferRateList.${index}.CurrencyID`}
                      label={"Currency"}
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={CurrencyList?.map((currency: ICurrency) => {
                        return {
                          value: currency.ID,
                          label: currency.CurrencyName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`TrasferRateList.${index}.OnewayRate`}
                      label="One Way Rate"
                      type="number"
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`TrasferRateList.${index}.TwowayRate`}
                      label="Two Way Rate"
                      type="number"
                      component={FormTextField}
                      // validator={requiredValidator}
                    />
                  </GridLayoutItem>
                </GridLayout>
              </Card>
            </GridLayoutItem>
          )
        )}
    </GridLayout>
  );
};

export default TransferRateArray;
