import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ISpecialityType,
  ISpecialityTypeInitialState,
} from "./specialityTypeModel";
import {
  ActiveInactiveSpecialityType,
  createSpecialityType,
  deleteSpecialityType,
  getAllActiveSpecialityTypes,
  getAllSpecialityTypes,
  getSpecialityTypeByID,
  updateSpecialityType,
} from "./services/specialityType.services";

const initialState: ISpecialityTypeInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  SpecialityTypeList: [],
  SpecialityTypeID: null,
  SpecialityTypeDetail: {
    SpecialityType: "",
    Prefix: "",
    IsActive: true,
  },
};

const specialityTypeSlice = createSlice({
  name: "specialityType",
  initialState,
  reducers: {
    clearSpecialityTypeDetails: (state) => {
      state.SpecialityTypeDetail = initialState.SpecialityTypeDetail;
    },
    setSpecialityTypeID: (state, action) => {
      state.SpecialityTypeID = action.payload;
    },
    clearSpecialityTypeID: (state) => {
      state.SpecialityTypeID = initialState.SpecialityTypeID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSpecialityTypes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllSpecialityTypes.fulfilled,
      (state, action: PayloadAction<ISpecialityType[]>) => {
        state.loading = false;
        state.SpecialityTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllSpecialityTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SpecialityTypeList = [];
    });
    builder.addCase(createSpecialityType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createSpecialityType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createSpecialityType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateSpecialityType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateSpecialityType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateSpecialityType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteSpecialityType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteSpecialityType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteSpecialityType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getSpecialityTypeByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getSpecialityTypeByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.SpecialityTypeDetail = action.payload;
    });
    builder.addCase(getSpecialityTypeByID.rejected, (state, action) => {
      state.formLoading = false;
      state.SpecialityTypeDetail = initialState.SpecialityTypeDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveSpecialityTypes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveSpecialityTypes.fulfilled,
      (state, action: PayloadAction<ISpecialityType[]>) => {
        state.loading = false;
        state.SpecialityTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveSpecialityTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SpecialityTypeList = [];
    });
    builder.addCase(ActiveInactiveSpecialityType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveSpecialityType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveSpecialityType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearSpecialityTypeDetails,
  clearSpecialityTypeID,
  setSpecialityTypeID,
} = specialityTypeSlice.actions;
export default specialityTypeSlice.reducer;
