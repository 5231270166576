import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  IPackageDetail,
  IReadymadePackage,
  IReadymadePackageState,
} from "./readymadePackageModel";
import {
  getAllReadymadeTourPackages,
  getTourPackageDetails,
} from "./services/readymadePackage.services";

const initialState: IReadymadePackageState = {
  loading: false,
  error: "",
  ReadymadePackageList: [],
  ReadymadePackageDetails: {
    SectorID: null,
    NoOfNights: null,
  },
  PackageDetail: {
    ID: null,
    TourName: "",
    NoOfNights: null,
    NoOfDays: null,
    Inclusion: "",
    Exclusion: "",
    ItineraryList: [],
    PackagesList: [],
  },
};

const readymadePackageSlice = createSlice({
  name: "readymadePackage",
  initialState,
  reducers: {
    setReadymadePackageDetail: (state, action) => {
      state.ReadymadePackageDetails = action.payload;
    },
    clearPackageDetail: (state) => {
      state.PackageDetail = initialState.PackageDetail;
    },
    // setMealsID: (state, action) => {
    //   state.MealsID = action.payload;
    // },
    // clearMealsID: (state) => {
    //   state.MealsID = initialState.MealsID;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllReadymadeTourPackages.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllReadymadeTourPackages.fulfilled,
      (state, action: PayloadAction<IReadymadePackage[]>) => {
        state.loading = false;
        state.ReadymadePackageList = action.payload || [];
      }
    );
    builder.addCase(getAllReadymadeTourPackages.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ReadymadePackageList = [];
    });
    builder.addCase(getTourPackageDetails.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getTourPackageDetails.fulfilled,
      (state, action: PayloadAction<IPackageDetail>) => {
        state.loading = false;
        state.PackageDetail = action.payload;
      }
    );
    builder.addCase(getTourPackageDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PackageDetail = initialState.PackageDetail;
    });
  },
});

export const {
  // clearMealsID, setMealsID,
  clearPackageDetail,
  setReadymadePackageDetail,
} = readymadePackageSlice.actions;
export default readymadePackageSlice.reducer;
