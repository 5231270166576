import React, { useEffect } from "react";
import { Typography } from "@progress/kendo-react-common";
import {
  CardBody,
  CardImage,
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { TextBox } from "@progress/kendo-react-inputs";
import ShadowCard from "../../components/common/ShadowCard";
import { FaStar } from "react-icons/fa";
import FormCheckboxGroup from "../../components/formFields/FormCheckboxGroup";
import RippleButton from "../../components/common/RippleButton";
// import { Table, Tooltip } from "antd";
// import Column from "antd/es/table/Column";
// import { AiFillPlusCircle } from "react-icons/ai";
// import { BiSolidInfoCircle } from "react-icons/bi";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { getHotelListWithRate } from "../hotel/services/hotel.services";
import { MdCurrencyRupee } from "react-icons/md";
import FormRadioGroup from "../../components/formFields/FormRadioGroup";
import HotelImage from "../../assets/Images/clipart-hotel-free-5.png";
import { insertPlannerItem } from "../planner/plannerSlice";

const renderStars = (value: number) => {
  const stars = [];
  for (let i = 0; i < value; i++) {
    stars.push(
      <FaStar
        key={i}
        color="#ffb600"
        fontSize={"x-large"}
        style={{ marginRight: 5 }}
      />
    );
  }
  return stars;
};
// const data = [
//   {
//     title: "Jimbaran Bay Beach Resort & Spa",
//     desc: "A Bali holiday isn’t complete without an authentic spa experience. Balinese spa treatments are well-known the world over for their healing properties as well as the hospitality by the locals. A Bali holiday isn’t complete without an authentic spa experience. Balinese spa treatments are well-known the world over for their healing properties as well as the hospitality by the locals. A Bali holiday isn’t complete without an authentic spa experience. Balinese spa treatments are well-known the world over for their healing properties as well as the hospitality by the locals. A Bali holiday isn’t complete without an authentic spa experience. Balinese spa treatments are well-known the world over for their healing properties as well as the hospitality by the locals.",
//     price: "USD 65",
//     star: 3,
//     img: "https://uandiholidays.net/Admin/UploadFiles/Hotels/Image/1.%20Swimming%20Pool2.png",
//   },
//   {
//     title: "Grand Seminyak",
//     desc: "An ideal Bali holiday is incomplete without water sports & what better than the famous Tanjung Benoa beach. This beach best known for its aquatic adventure is located in the Benoa peninsula.",
//     price: "USD 29",
//     star: 4,
//     img: "https://uandiholidays.net/Admin/UploadFiles/Hotels/Image/0%20-Anantara_Seminyak_Bali_Exterior_view_1920x1037.png",
//   },
//   {
//     title: "Maya Ubud Resort & Spa",
//     desc: "An ideal Bali holiday is incomplete without water sports & what better than the famous Tanjung Benoa beach. This beach best known for its aquatic adventure is located in the Benoa peninsula.",
//     price: "USD 29",
//     star: 5,
//     img: "https://uandiholidays.net/Admin/UploadFiles/Hotels/Image/Mayaubud.png",
//   },
//   {
//     title: "Jambuluwuk Oceano Seminyak",
//     desc: "A Bali holiday isn’t complete without an authentic spa experience. Balinese spa treatments are well-known the world over for their healing properties as well as the hospitality by the locals.",
//     price: "USD 65",
//     star: 3,
//     img: "https://uandiholidays.net/Admin/UploadFiles/Hotels/Image/01%20Villa%20Two%20Bed%20Room-Left.png",
//   },
//   {
//     title: "Ayana Resort And Spa Bali",
//     desc: "An ideal Bali holiday is incomplete without water sports & what better than the famous Tanjung Benoa beach. This beach best known for its aquatic adventure is located in the Benoa peninsula.",
//     price: "USD 29",
//     star: 4,
//     img: "https://uandiholidays.net/Admin/UploadFiles/Hotels/Image/Ayana.png",
//   },
//   {
//     title: "Sun Island Hotel & Spa Kuta",
//     desc: "An ideal Bali holiday is incomplete without water sports & what better than the famous Tanjung Benoa beach. This beach best known for its aquatic adventure is located in the Benoa peninsula.",
//     price: "USD 29",
//     star: 5,
//     img: "https://uandiholidays.net/Admin/UploadFiles/Hotels/Image/Sun%20Island%20Kuta.png",
//   },
// ];

export const BUDGET_OPTIONS = [
  { label: "USD 1 - 500", value: "USD 1 - 500" },
  { label: "USD 501 - 1000", value: "USD 501 - 1000" },
  { label: "USD 1001 - 1500", value: "USD 1001 - 1500" },
  { label: "USD 1501 - 2000", value: "USD 1501 - 2000" },
  { label: "More than USD 2000", value: "More than USD 2000" },
];

export const STAR_RATINGS = [
  { label: renderStars(5), value: 5 },
  { label: renderStars(4), value: 4 },
  { label: renderStars(3), value: 3 },
  { label: renderStars(2), value: 2 },
  { label: renderStars(1), value: 1 },
  { label: "All", value: 0 },
];

export const ROOM_CATEGORIES = [
  { label: "Room", value: "Room" },
  { label: "Ocean Room View", value: "Ocean Room View" },
  { label: "One Bedroom View", value: "One Bedroom View" },
  { label: "Two Bedroom View", value: "Two Bedroom View" },
  { label: "Three Bedroom View", value: "Three Bedroom View" },
  { label: "Four Bedroom View", value: "Four Bedroom View" },
  { label: "Five Bedroom View", value: "Five Bedroom View" },
  { label: "Family Room", value: "Family Room" },
  { label: "Bamboo House", value: "Bamboo House" },
];

export const LOCATIONS = [
  { label: "India", value: "India" },
  { label: "America", value: "America" },
  { label: "Canada", value: "Canada" },
  { label: "Japan", value: "Japan" },
  { label: "Europe", value: "Europe" },
  { label: "Russia", value: "Russia" },
  { label: "China", value: "China" },
];

// interface DataType {
//   key: React.Key;
//   RoomType: string;
//   MealPlan: string;
//   TotalPrice: string;
//   Inclusions: string;
//   RoomStatus: string;
// }

// const roomdata: DataType[] = [
//   {
//     key: "1",
//     RoomType: "Trunajaya Room",
//     MealPlan: "BB ",
//     TotalPrice: "USD 54",
//     Inclusions: "Inclusions",
//     RoomStatus: "On Request",
//   },
//   {
//     key: "2",
//     RoomType: "Blood Moon Hotel",
//     MealPlan: "BB ",
//     TotalPrice: "USD 54",
//     Inclusions: "Inclusions",
//     RoomStatus: "On Request",
//   },
//   {
//     key: "3",
//     RoomType: "Puspanjali Room",
//     MealPlan: "BB ",
//     TotalPrice: "USD 67",
//     Inclusions: "Inclusions",
//     RoomStatus: "On Request",
//   },
// ];

const HotelAndResort: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const FromDate = location.state?.FromDate;
  const ToDate = location.state?.ToDate;
  const DayNo = location.state?.DayNo;
  // const [expandedDesc, setExpandedDesc] = React.useState<any>({});
  // const [selectedRoomIndex, setSelectedRoomIndex] = React.useState(null);

  const PlannerHotelList = useAppSelector(
    (state) => state.hotel.PlannerHotelList
  );
  console.log("PlannerHotelList", PlannerHotelList);
  const LocalPassengerDetail = sessionStorage.getItem(
    "PassengerDetails"
  ) as any;
  const PassengerDetails = JSON.parse(LocalPassengerDetail);

  useEffect(() => {
    const payload = {
      StarRating: 0,
      FromDate: FromDate ? FromDate : "",
      ToDate: ToDate ? ToDate : "",
      PassengerDetails: PassengerDetails,
    };

    dispatch(getHotelListWithRate(payload));
  }, []);

  const handleStarRatingChange = async (e: any) => {
    console.log("e?.value", e?.value);
    if (e?.value || e?.value === 0) {
      const payload = {
        StarRating: +e?.value || 0,
        FromDate: FromDate ? FromDate : "",
        ToDate: ToDate ? ToDate : "",
        PassengerDetails: PassengerDetails,
      };

      await dispatch(getHotelListWithRate(payload));
    }
  };

  // const toggleExpand = (index: any) => {
  //   setExpandedDesc((prevState: any) => ({
  //     ...prevState,
  //     [index]: !prevState[index],
  //   }));
  // };

  // const toggleRoomDetails = (index: any) => {
  //   setSelectedRoomIndex(index === selectedRoomIndex ? null : index);
  // };

  const handleAddtoPlanner = (id: number) => {
    // const selectedHotel = PlannerHotelList?.filter((item) =>

    // ).map((e: any) => ({
    //   DayNo: DayNo,
    //   ID: e?.ID,
    //   HotelAddress: e?.HotelAddress,
    //   HotelCode: e?.HotelCode,
    //   HotelDisplayName: e?.HotelDisplayName,
    //   HotelName: e?.HotelName,
    // }));
    const selectedHotel = PlannerHotelList.filter((hotel: any) => {
      return hotel.ID === id;
    })?.map((e: any) => ({
      DayNo: DayNo,
      ID: e?.ID,
      HotelAddress: e?.HotelAddress,
      HotelCode: e?.HotelCode,
      HotelDisplayName: e?.HotelDisplayName,
      HotelName: e?.HotelName,
    }));
    dispatch(
      insertPlannerItem({
        dataItem: selectedHotel,
        arrayName: "HotelList",
        plannerRowID: DayNo,
      })
    );
    navigate("/planner");
  };

  // const rowSelection = {
  //   onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
  //     console.log(
  //       `selectedRowKeys: ${selectedRowKeys}`,
  //       "selectedRows: ",
  //       selectedRows
  //     );
  //   },
  //   // getCheckboxProps: (record: DataType) => ({
  //   //   disabled: record.hotel === "Disabled User", // Column configuration not to be checked
  //   //   hotel: record.hotel,
  //   // }),
  // };

  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0 }}>
            Bali | 4 Night/s | 06 APR 2024 - 10 APR 2024
          </Typography.h4>
        </GridLayoutItem>
        <GridLayoutItem
          style={{ background: "lightgray", padding: 10, marginBottom: 20 }}
        >
          <GridLayout
            style={{
              marginRight: 50,
              fontWeight: 600,
            }}
            gap={{ rows: 10, cols: 10 }}
            cols={[
              { width: "20%" },
              { width: "18%" },
              { width: "18%" },
              { width: "18%" },
              { width: "18%" },
              { width: "8%" },
            ]}
          >
            <GridLayoutItem>
              <Form
                //   onSubmit={handleSubmit}
                //   initialValues={GSTDetail}
                render={() => (
                  <FormElement style={{ width: "100%" }}>
                    <TextBox
                      // onChange={handleChange}
                      placeholder="Filter by Hotel Name"
                    />
                  </FormElement>
                )}
              />
            </GridLayoutItem>
            <GridLayoutItem
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRight: "1px solid black",
              }}
            >
              Sort By
            </GridLayoutItem>
            <GridLayoutItem
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRight: "1px solid black",
              }}
            >
              U & I Recommends
            </GridLayoutItem>
            <GridLayoutItem
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRight: "1px solid black",
              }}
            >
              Price Low to High
            </GridLayoutItem>
            <GridLayoutItem
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Price High to Low
            </GridLayoutItem>
            <GridLayoutItem></GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
        <GridLayoutItem>
          <div className="container-fluid">
            <div className="row">
              <div className="col-2 p-2 bg-white shadow rounded">
                <Form
                  //   onSubmit={handleSubmit}
                  //   initialValues={GSTDetail}
                  render={() => (
                    <FormElement style={{ width: "100%" }}>
                      <Typography.h5
                        style={{ background: "lightgray" }}
                        className="p-2"
                      >
                        Budget
                      </Typography.h5>
                      <GridLayoutItem>
                        <Field
                          wrapperClassName="checkbox-group-verticle"
                          id={"PrConsulting"}
                          name={"PrConsulting"}
                          label={"PrConsulting"}
                          component={FormCheckboxGroup}
                          options={BUDGET_OPTIONS}
                        />
                      </GridLayoutItem>
                      <Typography.h5
                        style={{ background: "lightgray" }}
                        className="p-2 mt-4"
                      >
                        Star Rating
                      </Typography.h5>
                      <GridLayoutItem>
                        <Field
                          wrapperClassName="checkbox-group-verticle"
                          id={"StarRating"}
                          name={"StarRating"}
                          // label={"StarRating"}
                          component={FormRadioGroup}
                          onChange={(e: any) => handleStarRatingChange(e)}
                          options={STAR_RATINGS}
                        />
                      </GridLayoutItem>
                      <Typography.h5
                        style={{ background: "lightgray" }}
                        className="p-2 mt-4"
                      >
                        Room Category
                      </Typography.h5>
                      <GridLayoutItem>
                        <Field
                          wrapperClassName="checkbox-group-verticle"
                          id={"PrConsulting"}
                          name={"PrConsulting"}
                          label={"PrConsulting"}
                          component={FormCheckboxGroup}
                          options={ROOM_CATEGORIES}
                        />
                      </GridLayoutItem>
                      <Typography.h5
                        style={{ background: "lightgray" }}
                        className="p-2 mt-4"
                      >
                        Locations
                      </Typography.h5>
                      <GridLayoutItem>
                        <Field
                          wrapperClassName="checkbox-group-verticle"
                          id={"PrConsulting"}
                          name={"PrConsulting"}
                          label={"PrConsulting"}
                          component={FormCheckboxGroup}
                          options={LOCATIONS}
                        />
                      </GridLayoutItem>
                    </FormElement>
                  )}
                />
              </div>
              <div className="col-10 ps-4">
                {PlannerHotelList &&
                  PlannerHotelList.length > 0 &&
                  PlannerHotelList?.map((item: any, index: any) => {
                    // const isExpanded = expandedDesc[index] || false;
                    // const truncatedDesc = isExpanded
                    //   ? item?.HotelAddress
                    //   : item?.HotelAddress.split("\n").slice(0, 2).join("\n");
                    // const descHeight = isExpanded ? "auto" : "2.8em";
                    // const overflow = isExpanded ? "visible" : "hidden";

                    return (
                      <ShadowCard
                        key={index}
                        orientation="horizontal"
                        style={{
                          padding: 10,
                          marginBottom: 10,
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <CardImage
                            src={
                              item?.HotelImage ? item?.HotelImage : HotelImage
                            }
                            // "https://clipground.com/images/clipart-hotel-free-5.png"
                            style={{
                              height: "137px",
                              width: "200px",
                              marginLeft: 10,
                              background: "whitesmoke",
                              borderRadius: 5,
                            }}
                          />
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <CardBody style={{ padding: "0 10px 0" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography.h4 className="mb-1">
                                  {item?.HotelName}{" "}
                                </Typography.h4>
                                <span style={{ fontSize: 16 }}>
                                  <b className="d-flex align-items-center">
                                    <MdCurrencyRupee /> {item?.Rate}
                                  </b>
                                </span>
                              </div>
                              <p className="mb-1 starline">
                                {renderStars(item?.star || 5)}
                              </p>
                              <div style={{ display: "flex" }}>
                                <div
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  <p className="mt-3">{item?.HotelAddress}</p>
                                  {/* <p
                                    style={{
                                      height: descHeight,
                                      overflow,
                                      textAlign: "justify",
                                    }}
                                    className="m-0"
                                  >
                                    {truncatedDesc}
                                  </p>
                                  <span
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => toggleExpand(index)}
                                  >
                                    {isExpanded ? "Show Less" : "View More"}
                                  </span> */}
                                </div>
                                <div
                                  style={{ width: "180px", textAlign: "end" }}
                                >
                                  {/* <RippleButton
                                    style={{ marginTop: 5 }}
                                    onClick={() => toggleRoomDetails(index)}
                                  >
                                    Select Room{" "}
                                    {selectedRoomIndex === index ? "▲" : "▼"}
                                  </RippleButton> */}
                                  <RippleButton
                                    style={{ marginTop: 5 }}
                                    onClick={() => handleAddtoPlanner(item?.ID)}
                                    // disabled={TransferSearchList?.length < 1}
                                  >
                                    Add to Planner
                                  </RippleButton>
                                </div>
                              </div>
                            </CardBody>
                          </div>
                        </div>
                        {/* {selectedRoomIndex === index && (
                          <div
                            className="roomdetails w-100 m-2 mt-3"
                            style={{ boxShadow: "0 0 10px gray" }}
                          >
                            <p className="p-3 m-0 text-center fw-bold border border-dark border-bottom-0">
                              Check In: 06 Apr 2024 Check Out: 07 Apr 2024 | 1
                              Room/s (Adult/s : 2 CWB : 0 CNB : 0 Infant/s : 0 )
                            </p>
                            {/* <Table
                            className="border border-dark"
                            rowSelection={{
                              type: "radio",
                              ...rowSelection,
                            }}
                            columns={columns}
                            dataSource={roomdata}
                            pagination={false}
                            bordered
                          /> *********************

                            <Table
                              className="border border-dark"
                              rowSelection={{
                                type: "radio",
                                ...rowSelection,
                              }}
                              dataSource={roomdata}
                              pagination={false}
                              bordered
                            >
                              <Column
                                title="Room Type"
                                dataIndex="RoomType"
                                key="RoomType"
                              />
                              <Column
                                title="Meal Plan"
                                dataIndex="MealPlan"
                                key="MealPlan"
                                render={(_: any, record: DataType) => {
                                  return (
                                    <td
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 10,
                                      }}
                                    >
                                      {record?.MealPlan}
                                      <AiFillPlusCircle
                                        style={{ fontSize: 20 }}
                                      />
                                    </td>
                                  );
                                }}
                              />
                              <Column
                                title="Total Price"
                                dataIndex="TotalPrice"
                                key="TotalPrice"
                                render={(_: any, record: DataType) => {
                                  const priceData = () => {
                                    return (
                                      <>
                                        <p className="m-0">
                                          2 Adult/s = USD 53.38
                                        </p>
                                      </>
                                    );
                                  };
                                  return (
                                    <td
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 10,
                                      }}
                                    >
                                      {record?.TotalPrice}
                                      <Tooltip title={priceData}>
                                        <BiSolidInfoCircle
                                          style={{ fontSize: 20 }}
                                        />
                                      </Tooltip>
                                    </td>
                                  );
                                }}
                              />
                              <Column
                                title="Inclusions"
                                dataIndex="Inclusions"
                                key="Inclusions"
                                render={(_: any, record: DataType) => {
                                  const titleData = () => {
                                    return (
                                      <>
                                        <p className="m-0">
                                          • 1 Night/s stay on BB basis
                                        </p>
                                        <p className="m-0">
                                          • All Indonesian Taxes
                                        </p>
                                      </>
                                    );
                                  };
                                  return (
                                    <Tooltip title={titleData}>
                                      <td
                                        style={{
                                          color: "red",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {record?.Inclusions}
                                      </td>
                                    </Tooltip>
                                  );
                                }}
                              />
                              <Column
                                title="Room Status"
                                dataIndex="RoomStatus"
                                key="RoomStatus"
                              />
                            </Table>
                          </div>
                        )}
                        {selectedRoomIndex === index && (
                          <div className="text-center w-100 mt-1 mb-2">
                            <RippleButton
                              style={{ marginTop: 5 }}
                              themeColor="error"
                              //   onClick={() => toggleRoomDetails(index)}
                            >
                              Book Now
                            </RippleButton>
                          </div>
                        )} */}
                      </ShadowCard>
                    );
                  })}
              </div>
            </div>
          </div>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default HotelAndResort;
