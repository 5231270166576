import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import { useLocation } from "react-router-dom";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import {
  deleteTourSightseen,
  getTourByID,
  getTourSightseensByTourID,
  insertTourSightseen,
  updateTourSightseen,
} from "../services/tour.services";
import {
  setTourSightseenDetails,
  setTourSightseenID,
  increaseActiveStep,
  decreaseActiveStep,
  clearTourSightseenDetails,
  clearTourSightseenID,
} from "../tourSlice";
import RippleButton from "../../../components/common/RippleButton";
import AppDialog, {
  DialogCloseButton,
} from "../../../components/dialog/Dialog";
import { Button } from "@progress/kendo-react-buttons";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import IconButton from "../../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import ShadowCard from "../../../components/common/ShadowCard";
import { generateDaysArray } from "../../../_helper/commonHelpers";
import { SIGHTSEENTYPE } from "../../../_contstants/common";
import { getCityBySectorID } from "../../city/services/city.services";
import { ICity } from "../../city/cityModel";
import { getAllSightseens } from "../../sightseen/services/sightseen.services";
import { ISightseen } from "../../sightseen/sightseenModel";

const TourFixedSightseens: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const TourID = location.state?.TourID;
  const TourDetail = useAppSelector((state) => state.tour.TourDetail);

  useEffect(() => {
    dispatch(getTourSightseensByTourID(TourID));
    dispatch(getTourByID(TourID));
  }, [TourID]);

  useEffect(() => {
    if (TourDetail?.SectorID) {
      dispatch(getCityBySectorID(TourDetail.SectorID));
    }
  }, [TourDetail?.SectorID]);

  useEffect(() => {
    dispatch(getAllSightseens());
    return () => {
      dispatch(clearTourSightseenDetails());
    };
  }, []);

  return (
    <>
      <DeleteTourSightseenDialog />
      <TourSightseenForm />
      <TourSightseenGridComponent />
    </>
  );
};

const TourSightseenForm: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const TourID = location.state?.TourID;
  const NoOfDays = location.state?.NoOfDays;
  const loading = useAppSelector((state) => state.tour.loading);
  const TourSightseenDetails = useAppSelector(
    (state) => state.tour.TourSightseenDetails
  );
  const SectorCityList = useAppSelector((state) => state.city.SectorCityList);
  const SightseenList = useAppSelector(
    (state) => state.sightseen.SightseenList
  );

  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [TourSightseenDetails]);

  const handleSubmit = async (values: any) => {
    try {
      if (!TourSightseenDetails?.ID) {
        const insertPayload = {
          ID: values.ID,
          TourID: TourID,
          DayNo: values.DayNo ? +values.DayNo : null,
          CityID: values.CityID ? +values.CityID : null,
          SightseenTiming: values.SightseenTiming
            ? +values.SightseenTiming
            : null,
          SightseenID: values.SightseenID ? +values.SightseenID : null,
        };
        const response = await dispatch(insertTourSightseen(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(getTourSightseensByTourID(TourID));
          setFormKey(formKey + 1);
          dispatch(clearTourSightseenDetails());
        }
      } else {
        const editPayload = {
          ID: values.ID,
          TourID: TourID,
          DayNo: values.DayNo ? +values.DayNo : null,
          CityID: values.CityID ? +values.CityID : null,
          SightseenTiming: values.SightseenTiming
            ? +values.SightseenTiming
            : null,
          SightseenID: values.SightseenID ? +values.SightseenID : null,
        };
        const response = await dispatch(updateTourSightseen(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(getTourSightseensByTourID(TourID));
          setFormKey(formKey + 1);
          dispatch(clearTourSightseenDetails());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      key={formKey}
      initialValues={TourSightseenDetails}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <ShadowCard style={{ padding: 10 }}>
            <GridLayout
              style={{ marginRight: 30 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
              ]}
            >
              <GridLayoutItem colSpan={4}>
                <Typography.h4 style={{ margin: 0 }}>
                  {"Add Tour Fixed Sightseen"}
                </Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`DayNo`}
                  label="Day"
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={generateDaysArray(+NoOfDays)}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`SightseenTiming`}
                  label="Sightseen Time"
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={SIGHTSEENTYPE}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={"CityID"}
                  label={"Place"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={SectorCityList.map((city: ICity) => {
                    return {
                      value: city.ID,
                      label: city.CityName,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={"SightseenID"}
                  label={"Sightseen"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={SightseenList.map((city: ISightseen) => {
                    return {
                      value: city.ID,
                      label: city.SightseenName,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem
                colSpan={4}
                style={{ textAlign: "end", marginTop: 10 }}
              >
                <RippleButton
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => dispatch(decreaseActiveStep())}
                >
                  Previous
                </RippleButton>
                <RippleButton
                  fillMode="solid"
                  themeColor="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => dispatch(increaseActiveStep())}
                >
                  Next
                </RippleButton>
                <RippleButton
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setFormKey(formKey + 1);
                    dispatch(clearTourSightseenDetails());
                  }}
                >
                  Cancel
                </RippleButton>
                <ButtonWithLoading
                  label={TourSightseenDetails?.ID ? "Update" : "Add"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || loading}
                  loading={loading}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
        </FormElement>
      )}
    />
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const TourSightseenGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const TourSightseenList = useAppSelector(
    (state) => state.tour.TourSightseenList
  );
  const loading = useAppSelector((state) => state.tour.loading);

  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? TourSightseenList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (item: any) => {
      const payload = {
        ID: item.ID,
        TourID: item.TourID,
        DayNo: item.DayNo ? +item.DayNo : null,
        CityID: item.CityID ? +item.CityID : null,
        SightseenTiming: item.SightseenTiming ? +item.SightseenTiming : null,
        SightseenID: item.SightseenID ? +item.SightseenID : null,
      };
      dispatch(setTourSightseenDetails(payload));
    };
    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteTourSightseen"));
      dispatch(setTourSightseenID(ID));
    };

    return (
      <td
        className={props.className}
        style={{
          ...props.style,
          textAlign: "center",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEdit(props.dataItem)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem.ID)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 4 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {TourSightseenList && TourSightseenList.length ? (
        <KendoGrid
          style={{ marginTop: 20 }}
          data={TourSightseenList}
          skip={page.skip}
          take={page.take}
          total={TourSightseenList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column field="DayNo" title="Day No" />
          <Column
            field="SightseenTiming"
            title="Sightseen Time"
            cell={(props: GridCellProps) => (
              <td>
                {props.dataItem?.SightseenTiming === 1
                  ? "Half Day"
                  : props.dataItem?.SightseenTiming === 2
                  ? "Full Day"
                  : ""}
              </td>
            )}
          />
          <Column field="Sightseen" title="Sightseen" />
          <Column field="Place" title="Place" />
          <Column
            field="ID"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
            locked={true}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteTourSightseenDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const TourID = location.state?.TourID;
  const TourSightseenID = useAppSelector((state) => state.tour.TourSightseenID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteTourSightseen(ID));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(getTourSightseensByTourID(TourID));
        dispatch(closeDialog());
        dispatch(clearTourSightseenID());
      } else {
        dispatch(getTourSightseensByTourID(TourID));
        dispatch(closeDialog());
        dispatch(clearTourSightseenID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteTourSightseen" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Tour Sightseen"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Tour Sightseen?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(TourSightseenID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default TourFixedSightseens;
