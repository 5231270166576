import React from "react";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
  GridCellProps,
} from "@progress/kendo-react-grid";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import moment from "moment";
import RippleButton from "../../components/common/RippleButton";
import { openDialog } from "../../components/dialog/dialogSlice";
import CreatePlannerQuotation from "./CreatePlannerQuotation";

const ConfirmPlanner: React.FC = () => {
  const dispatch = useAppDispatch();
  const PlannerList = useAppSelector((state) => state.planner.PlannerList);

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const HotelCell = (props: GridCellProps) => {
    return (
      <td
        style={{
          textAlign: "center",
          padding: "30px 8px 25px 8px",
          position: "relative",
        }}
      >
        {props.dataItem?.HotelList?.map((hotel: any) => {
          return (
            <span style={{ paddingRight: 10, display: "block" }}>
              {hotel.HotelName}
            </span>
          );
        })}
      </td>
    );
  };

  const TransferCell = (props: GridCellProps) => {
    return (
      <td
        style={{
          textAlign: "center",
          padding: "30px 8px 25px 8px",
          position: "relative",
        }}
      >
        {props.dataItem?.TransferList?.map((transfer: any) => {
          return (
            <span style={{ paddingRight: 10, display: "block" }}>
              {transfer?.FromLocationName} to {transfer?.ToLocationName}
            </span>
          );
        })}
      </td>
    );
  };

  const TourCell = (props: GridCellProps) => {
    return (
      <td
        style={{
          textAlign: "center",
          padding: "30px 8px 25px 8px",
          position: "relative",
        }}
      >
        {props.dataItem?.SightseenList?.map((tour: any) => {
          return (
            <span style={{ paddingRight: 10, display: "block" }}>
              {tour?.SightseenName}
            </span>
          );
        })}
      </td>
    );
  };

  const MealCell = (props: GridCellProps) => {
    return (
      <td
        style={{
          textAlign: "center",
          padding: "30px 8px 25px 8px",
          position: "relative",
        }}
      >
        {props.dataItem?.MealList?.map((meal: any) => {
          return (
            <span style={{ paddingRight: 10, display: "block" }}>
              {meal?.MealName}
            </span>
          );
        })}
      </td>
    );
  };
  const handleQuotationDialog = () => {
    dispatch(openDialog("plannerQuotationForm"));
  };

  return (
    <div>
      <CreatePlannerQuotation />
      <KendoGrid
        style={{ maxHeight: "calc(100vh - 152px)" }}
        data={PlannerList}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          field="Date"
          title="Date"
          cell={(props) => {
            return (
              <td>
                {props.dataItem.Date
                  ? moment(props.dataItem.Date).format("DD/MM/YYYY")
                  : ""}
              </td>
            );
          }}
        />
        <Column
          field="DayNo"
          title="Hotel"
          cell={HotelCell}
          filterable={false}
          locked={true}
        />
        <Column
          field="DayNo"
          title="Transfer"
          cell={TransferCell}
          filterable={false}
          locked={true}
        />
        <Column
          field="DayNo"
          title="Tour"
          cell={TourCell}
          filterable={false}
          locked={true}
        />
        <Column
          field="DayNo"
          title="Meal"
          cell={MealCell}
          filterable={false}
          locked={true}
        />
      </KendoGrid>
      <div
        className="mt-3"
        style={{ display: "flex", gap: 10, justifyContent: "end" }}
      >
        <RippleButton
          type="button"
          fillMode="outline"
          themeColor="primary"
          onClick={() => handleQuotationDialog()}
        >
          Make Quotation
        </RippleButton>
      </div>
    </div>
  );
};

export default ConfirmPlanner;
