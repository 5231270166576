import React from "react";
import {
  GridLayout,
  GridLayoutItem,
  TabStrip,
  TabStripTab,
} from "@progress/kendo-react-layout";
// import { Tabs, TabsProps } from "antd";
import TourDetails from "./TourDetails";
import MealDetails from "./MealDetails";
import TransferDetails from "./TransferDetails";
import { useLocation } from "react-router-dom";

// const onChange = (key: string) => {
//   console.log(key);
// };

// const items: TabsProps["items"] = [
//   {
//     key: "1",
//     label: "Transfers",
//     children: <TransferDetails />,
//   },
//   {
//     key: "2",
//     label: "Tours",
//     children: <TourDetails />,
//   },
//   {
//     key: "3",
//     label: "Meals",
//     children: <MealDetails />,
//   },
// ];

const ToursAndTrasnfers: React.FC = () => {
  const location = useLocation();
  const selectedTab = location.state?.selectedTab;
  const [selected, setSelected] = React.useState(selectedTab);
  const handleSelect = (e: any) => {
    setSelected(e.selected);
  };

  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "4px 0 20px",
          }}
        >
          Bali | 05 APR 2024 - 05 APR 2024
        </GridLayoutItem>
        <GridLayoutItem>
          {/* <Tabs onChange={onChange} type="card" items={items} centered={true} /> */}
          <TabStrip selected={selected} onSelect={handleSelect}>
            <TabStripTab title="Transfers" contentClassName="tabContainer">
              <TransferDetails />
            </TabStripTab>
            <TabStripTab title="Tours" contentClassName="tabContainer">
              <TourDetails />
            </TabStripTab>
            <TabStripTab title="Meals" contentClassName="tabContainer">
              <MealDetails />
            </TabStripTab>
          </TabStrip>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default ToursAndTrasnfers;
