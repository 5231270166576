import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ActiveInactiveCurrency,
  createCurrency,
  deleteCurrency,
  getAllActiveCurrencies,
  getAllCurrencies,
  getCurrencyByID,
  updateCurrency,
} from "./services/currency.services";
import { ICurrency, ICurrencyState } from "./currencyModel";

const initialState: ICurrencyState = {
  loading: false,
  formLoading: false,
  error: "",
  CurrencyID: null,
  CurrencyList: [],
  CurrencyDetail: {
    CurrencyName: "",
    CurrencyConversion: null,
    IsActive: true,
  },
};

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    clearCurrencyDetails: (state) => {
      state.CurrencyDetail = initialState.CurrencyDetail;
    },
    setCurrencyID: (state, action) => {
      state.CurrencyID = action.payload;
    },
    clearCurrencyID: (state) => {
      state.CurrencyID = initialState.CurrencyID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCurrencies.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllCurrencies.fulfilled,
      (state, action: PayloadAction<ICurrency[]>) => {
        state.loading = false;
        state.CurrencyList = action.payload || [];
      }
    );
    builder.addCase(getAllCurrencies.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CurrencyList = [];
    });
    builder.addCase(createCurrency.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createCurrency.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createCurrency.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateCurrency.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateCurrency.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateCurrency.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteCurrency.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteCurrency.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCurrency.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getCurrencyByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getCurrencyByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.CurrencyDetail = action.payload;
    });
    builder.addCase(getCurrencyByID.rejected, (state, action) => {
      state.formLoading = false;
      state.CurrencyDetail = initialState.CurrencyDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveCurrencies.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveCurrencies.fulfilled,
      (state, action: PayloadAction<ICurrency[]>) => {
        state.loading = false;
        state.CurrencyList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveCurrencies.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CurrencyList = [];
    });
    builder.addCase(ActiveInactiveCurrency.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveCurrency.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveCurrency.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearCurrencyDetails, clearCurrencyID, setCurrencyID } =
  currencySlice.actions;
export default currencySlice.reducer;
