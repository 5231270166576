import * as React from "react";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import {
  InputSuffix,
  // NumericTextBox,
  TextBox,
  TextBoxProps,
} from "@progress/kendo-react-inputs";
import { FloatingLabel, Hint, Error } from "@progress/kendo-react-labels";
import { checkIcon, warningTriangleIcon } from "@progress/kendo-svg-icons";
import { Button } from "@progress/kendo-react-buttons";

const FormTextField = (props: TextBoxProps & FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type = "text",
    optional,
    placeholder,
    wrapperClassName,
    max,
    // step,
    // min,
    // value,
    ...other
  } = props;

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";

  // const defaultValue =
  //   typeof other.value === "string" ? parseFloat(other.value) : other.value;

  return (
    <FieldWrapper className={wrapperClassName}>
      <FloatingLabel
        label={label}
        editorId={id}
        editorValue={other.value}
        editorValid={valid}
        editorDisabled={disabled}
        optional={optional}
      >
        {/* {type === "number" ? (
          <NumericTextBox
            ariaDescribedBy={`${hintId} ${errorId}`}
            valid={valid}
            id={id}
            disabled={disabled}
            
            // onChange={handleInputChange}
            defaultValue={isNaN(defaultValue) ? null : defaultValue}
            step={typeof step === "number" ? step : undefined}
            min={typeof min === "number" ? min : undefined}
            max={typeof max === "number" ? max : undefined}
            {...other}
          />
        ) : ( */}
        <TextBox
          valid={valid}
          type={type}
          placeholder={placeholder}
          suffix={() =>
            touched ? (
              <InputSuffix>
                {valid ? (
                  <Button
                    themeColor={"success"}
                    fillMode={"flat"}
                    svgIcon={checkIcon}
                  />
                ) : (
                  <Button
                    themeColor={"error"}
                    fillMode={"flat"}
                    svgIcon={warningTriangleIcon}
                  />
                )}
              </InputSuffix>
            ) : null
          }
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (max) {
              return (e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, +max));
            } else {
              return e;
            }
          }}
          id={id}
          disabled={disabled}
          aria-describedby={`${hintId} ${errorId}`}
          {...other}
        />
        {/* )} */}
      </FloatingLabel>
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export default FormTextField;
