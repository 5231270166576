import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import RippleButton from "../../components/common/RippleButton";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { getAllActiveCities } from "../city/services/city.services";
import { getAllActiveStates } from "../state/services/state.services";
import { getAllActiveCountries } from "../country/services/country.services";
import { getAllActiveVehicleTypes } from "../vehicleType/services/vehicleType.services";
import { getAllActiveTermsAndConditions } from "../termsandcondition/services/termsAndCondition.services";
import { clearSightseenDetails } from "./sightseenSlice";
import {
  createSightseen,
  updateSightseen,
  getSightseenByID,
  getAllRateBySightseenID,
  getAllCancellationBySightseenID,
} from "./services/sightseen.services";
import { ISightseenType } from "../sightseentype/sightseenTypeModel";
import { IState } from "../state/stateModel";
import { ICountry } from "../country/countryModel";
import { ICity } from "../city/cityModel";
import { findIndex } from "lodash";
import { ISector } from "../sector/sectorModel";
import { getAllActiveSectors } from "../sector/services/sector.services";
import { IVehicleType } from "../vehicleType/vehicleTypeModel";
import { ITermsAndCondition } from "../termsandcondition/termsAndConditionModel";
import { getAllActiveSightseenTypes } from "../sightseentype/services/sightseenType.services";
import SightseenRate from "./SightseenRate";
import ShadowCard from "../../components/common/ShadowCard";
import CancellationCharge from "./CancellationCharge";
import { SIGHTSEENTYPE, STATUSARRAY } from "../../_contstants/common";
import FormRichTextField from "../../components/formFields/FormRichTextField";

interface CityChangeWatcherProps {
  formRenderProps: FormRenderProps;
  CityList: ICity[];
}

const CityChangeWatcher: React.FC<CityChangeWatcherProps> = ({
  CityList,
  formRenderProps,
}) => {
  const cityID = formRenderProps.valueGetter("CityID");

  useEffect(() => {
    const CityID = cityID ? cityID : undefined;
    const cityIndex = findIndex(CityList, { ID: CityID });
    if (cityIndex > -1) {
      const city = CityList[cityIndex];
      formRenderProps.onChange("StateID", { value: city.StateID });
      formRenderProps.onChange("CountryID", { value: city.CountryID });
    } else {
      formRenderProps.onChange("CityID", { value: "" });
      formRenderProps.onChange("StateID", { value: "" });
      formRenderProps.onChange("CountryID", { value: "" });
    }
  }, [cityID]);
  return null;
};

const CreateSightseen: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const SightseenID = location.state?.SightseenID;

  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state: RootState) => state.sightseen.loading);
  const SightseenDetail = useAppSelector(
    (state: RootState) => state.sightseen.SightseenDetail
  );
  const SectorList = useAppSelector(
    (state: RootState) => state.sector.SectorList
  );
  const CityList = useAppSelector((state: RootState) => state.city.CityList);
  const StateList = useAppSelector((state: RootState) => state.state.StateList);
  const CountryList = useAppSelector(
    (state: RootState) => state.country.CountryList
  );
  const SightseenTypeList = useAppSelector(
    (state: RootState) => state.sightseenType.SightseenTypeList
  );
  const TermsAndConditionList = useAppSelector(
    (state: RootState) => state.termsandcondition.TermsAndConditionList
  );
  const VehicleTypeList = useAppSelector(
    (state: RootState) => state.vehicleType.VehicleTypeList
  );

  const [formKey, setFormKey] = React.useState(1);

  useEffect(() => {
    if (SightseenID) {
      dispatch(getSightseenByID(SightseenID));
      dispatch(getAllRateBySightseenID(SightseenID));
      dispatch(getAllCancellationBySightseenID(SightseenID));
    }
  }, [SightseenID]);

  useEffect(() => {
    dispatch(getAllActiveCities());
    dispatch(getAllActiveStates());
    dispatch(getAllActiveCountries());
    dispatch(getAllActiveSectors());
    dispatch(getAllActiveVehicleTypes());
    dispatch(getAllActiveSightseenTypes());
    dispatch(getAllActiveTermsAndConditions());

    return () => {
      dispatch(clearSightseenDetails());
    };
  }, []);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [SightseenDetail]);

  const handleSubmit = async (values: any) => {
    if (SightseenID) {
      const editPayload = {
        ID: SightseenID,
        SightseenName: values.SightseenName,
        SightseenTypeID: values.SightseenTypeID,
        Description: values.Description,
        VehicleTypeID: values.VehicleTypeID,
        Address: values.Address,
        CountryID: values.CountryID,
        CityID: values.CityID,
        StateID: values.StateID,
        SectorID: values.SectorID,
        TermsAndConditionsID: values.TermsAndConditionsID,
        SightseenTiming: values.SightseenTiming,
        IsActive: values.IsActive === 1,
      };
      const response = await dispatch(updateSightseen(editPayload));
      if (response.meta?.requestStatus === "fulfilled") {
        dispatch(getSightseenByID(SightseenID));
      }
    } else {
      const insertPayload = {
        SightseenName: values.SightseenName,
        SightseenTypeID: values.SightseenTypeID,
        Description: values.Description,
        VehicleTypeID: values.VehicleTypeID,
        Address: values.Address,
        CountryID: values.CountryID,
        CityID: values.CityID,
        StateID: values.StateID,
        SectorID: values.SectorID,
        TermsAndConditionsID: values.TermsAndConditionsID,
        SightseenTiming: values.SightseenTiming,
        IsActive: values.IsActive === 1,
      };
      const response = await dispatch(createSightseen(insertPayload));

      if (response.meta?.requestStatus === "fulfilled") {
        navigate("/sightseen/edit", {
          state: {
            SightseenID: response?.payload?.Data?.SightseenID,
          },
        });
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        key={formKey}
        initialValues={SightseenDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {SightseenID ? "Update Sightseen" : "Create Sightseen"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="SightseenName"
                    label="Sightseen Name"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id="SightseenTypeID"
                    name="SightseenTypeID"
                    label="Sightseen Type"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={SightseenTypeList.map((sector: ISightseenType) => {
                      return {
                        value: sector.ID,
                        label: sector.SightseenType,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem rowSpan={2}>
                  <Field
                    name="Description"
                    label="Description"
                    component={FormRichTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    name="Address"
                    label="Address"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id="SectorID"
                    name="SectorID"
                    label="Destination"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={SectorList.map((sector: ISector) => {
                      return {
                        value: sector.ID,
                        label: sector.SectorName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id="CityID"
                    name="CityID"
                    label="City"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={CityList.map((city: ICity) => {
                      return {
                        value: city.ID,
                        label: city.CityName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id="StateID"
                    name="StateID"
                    label="State"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={StateList.map((state: IState) => {
                      return {
                        value: state.ID,
                        label: state.StateName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id="CountryID"
                    name="CountryID"
                    label="Country"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={CountryList.map((country: ICountry) => {
                      return {
                        value: country.ID,
                        label: country.CountryName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id="VehicleTypeID"
                    name="VehicleTypeID"
                    label="Vehicle Type"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={VehicleTypeList.map((country: IVehicleType) => {
                      return {
                        value: country.ID,
                        label: country.VehicleType,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name="TermsAndConditionsID"
                    label="Cancellation policy"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={TermsAndConditionList.map(
                      (tc: ITermsAndCondition) => {
                        return {
                          value: tc.ID,
                          label: tc.TermsAndConditions,
                        };
                      }
                    )}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`IsActive`}
                    label="Status"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={STATUSARRAY?.map((status: any) => {
                      return {
                        value: status?.value,
                        label: status?.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`SightseenTiming`}
                    label="Sightseen Time"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={SIGHTSEENTYPE}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={1}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <div style={{ display: "flex", gap: "10px" }}>
                    <RippleButton
                      type="button"
                      fillMode="outline"
                      themeColor="primary"
                      onClick={() => navigate("/sightseen")}
                    >
                      Cancel
                    </RippleButton>
                    <ButtonWithLoading
                      label={SightseenID ? "Update" : "Create"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <RippleButton
                      type="button"
                      fillMode="solid"
                      themeColor="primary"
                      onClick={() => navigate("/sightseen")}
                    >
                      Go to Sightseen List
                    </RippleButton>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <CityChangeWatcher
              formRenderProps={formRenderProps}
              CityList={CityList}
            />
          </FormElement>
        )}
      />
      {SightseenID && <SightseenRate />}
      {SightseenID && <CancellationCharge />}
    </>
  );
};

export default CreateSightseen;
