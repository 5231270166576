import React from "react";
import { Drawer } from "antd";
import { closed, opened } from "./drawerSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  CreateButtonYype,
  EditButtonType,
  IDrawerProps,
  IDrawerState,
  Placement,
} from "./drawerModel";
import { Button } from "@progress/kendo-react-buttons";
import { FiEdit } from "react-icons/fi";

const AppDrawer: React.FC<IDrawerProps> = (props) => {
  const drawerState = useAppSelector((state: RootState) => state.drawer);
  const dispatch = useAppDispatch();
  const toggleDrawer = () => {
    dispatch(closed());
  };
  return (
    <Drawer
      open={drawerState.open}
      title={drawerState.title}
      placement={drawerState.placement}
      onClose={toggleDrawer}
      size={drawerState.size}
      style={{
        boxSizing: "border-box",
        padding: "0 8px",
        zIndex: 1,
      }}
    >
      {props.children}
    </Drawer>
  );
};

export const ButtonCreate: React.FC<CreateButtonYype> = (props) => {
  const dispatch = useAppDispatch();

  const handleCreate = () => {
    const drawerOptions: IDrawerState = {
      open: true,
      placement: props?.placement || ("right" as Placement),
      name: props.drawerName,
      title: props.title,
      size: props?.size || "default",
    };
    dispatch(opened(drawerOptions));
  };

  return (
    <Button
      onClick={handleCreate}
      fillMode={"solid"}
      themeColor={"primary"}
      style={props.style}
    >
      {props.label}
    </Button>
  );
};

export const ButtonEdit: React.FC<EditButtonType> = (props) => {
  const dispatch = useAppDispatch();

  const handleEdit = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    const drawerOptions: IDrawerState = {
      open: true,
      placement: props?.placement || "right",
      name: props.name,
      title: props.title,
      size: "default",
      data: props.ID,
    };
    dispatch(opened(drawerOptions));
  };
  return (
    <Button
      type="button"
      fillMode="flat"
      themeColor="primary"
      size="small"
      style={{ width: 35, height: 35, position: "relative" }}
      onClick={handleEdit}
    >
      <FiEdit style={{ fontSize: "20px" }} className="absolute-position" />
    </Button>
  );
};

export const LinkEdit: React.FC<EditButtonType> = (props) => {
  const dispatch = useAppDispatch();

  const handleEdit = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    const drawerOptions: IDrawerState = {
      open: true,
      placement: props?.placement || "right",
      name: props.name,
      title: props.title,
      size: props?.size || "default",
      data: props.ID,
    };
    dispatch(opened(drawerOptions));
  };
  return (
    <div className="hoverableName" onClick={handleEdit}>
      {props?.masterName}
    </div>
  );
};

export default AppDrawer;
