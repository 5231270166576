import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ICity } from "../cityModel";

export const getAllCities = createAsyncThunk("City/FindAllCity", async () => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/City/FindAllCity`,
      {
        CityName: "",
      }
    );
    // SuccessToast(response.data?.Details || "Success");
    return response.data?.Data;
  } catch (error: any) {
    ErrorToast(error?.response?.data?.Details || "Something went wrong");
    console.error("Error Fetching Cities:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const createCity = createAsyncThunk(
  "City/InsertCity",
  async (CityData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/City/InsertCity`,
        CityData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateCity = createAsyncThunk(
  "City/UpdateCity",
  async (CityData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/City/UpdateCity`,
        CityData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteCity = createAsyncThunk(
  "City/DeleteCity",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/City/DeleteCity`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCityByID = createAsyncThunk(
  "City/FindByIDCity",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/City/FindByIDCity`,
        { ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        CountryID: result.CountryID,
        StateID: result.StateID,
        CityName: result.CityName,
        IsActive: result.IsActive,
      } as ICity;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveCities = createAsyncThunk(
  "City/FindAllActiveCity",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/City/FindAllActiveCity`
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching cities:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const cityActiveInactive = createAsyncThunk(
  "City/ActiveInActiveCity",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/City/ActiveInActiveCity`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
export const getCityBySectorID = createAsyncThunk(
  "Sector/FindCityBySectorID",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sector/FindCityBySectorID`,
        {
          ID: ID,
        }
      );
      return response.data?.Data as any[];
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Cities by Sector:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
