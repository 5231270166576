import * as React from "react";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { FloatingLabel, Hint, Error } from "@progress/kendo-react-labels";
import { TextArea } from "@progress/kendo-react-inputs";

const FormTextArea = (props: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    optional,
    wrapperClassName,
    ...others
  } = props;

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";

  return (
    <FieldWrapper className={wrapperClassName}>
      <FloatingLabel
        label={label}
        editorId={id}
        editorValue={others.value}
        editorValid={valid}
        editorDisabled={disabled}
        optional={optional}
        // className="field-label"
      >
        <TextArea
          valid={valid}
          id={id}
          disabled={disabled}
          rows={4}
          ariaDescribedBy={`${hindId} ${errorId}`}
          {...others}
        />
      </FloatingLabel>
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export default FormTextArea;
