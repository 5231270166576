import React, { useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { MEALTYPE } from "../../_contstants/common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getAllActiveMealCategories } from "../mealcategory/services/mealCategory.services";
import { getAllActiveMeals } from "../meals/services/meals.services";
import { IMealCategory } from "../mealcategory/mealCategoryModel";

const MealDetailsArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const name = fieldArrayRenderProps?.name;
  const MealCategoryList = useAppSelector(
    (state) => state.mealCategory.MealCategoryList
  );
  const MealsList = useAppSelector((state) => state.meals.MealsList);

  useEffect(() => {
    dispatch(getAllActiveMealCategories());
    dispatch(getAllActiveMeals());
  }, []);

  const filterMeals = (MealCategory: number) => {
    if (MealCategory) {
      const result = MealsList.filter((meal: any) => {
        return meal.MealCategoryID === MealCategory;
      });
      return result.map((meal) => {
        return {
          value: meal?.ID,
          label: meal?.MealName,
        };
      });
    }
  };
  // const pushElementInarray = React.useCallback(() => {
  //   fieldArrayRenderProps.onPush({
  //     value: {
  //       Date: "",
  //       MealTime: null,
  //       MealType: null,
  //       MoreOption: null,
  //     },
  //   });
  // }, [fieldArrayRenderProps]);

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {/* {!fieldArrayRenderProps.value ? pushElementInarray() : null} */}
      {/* <GridLayoutItem style={{ marginBottom: 10 }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Meal Details
        </Button>
      </GridLayoutItem> */}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((RoomData: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card className="fieldarray-card" style={{margin:"5px 0"}}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem>
                  <Field
                    name={`${name}.${index}.MealTime`}
                    label={"Meal Time"}
                    component={FormSelectionField}
                    options={MEALTYPE.map((mealtype: any) => {
                      return {
                        value: mealtype.value,
                        label: mealtype.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`${name}.${index}.MealType`}
                    label={"Meal Type"}
                    component={FormSelectionField}
                    options={MealCategoryList?.map(
                      (mealcategory: IMealCategory) => {
                        return {
                          value: mealcategory?.ID,
                          label: mealcategory?.Title,
                        };
                      }
                    )}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`${name}.${index}.MoreOption`}
                    label={"More Options"}
                    component={FormSelectionField}
                    options={
                      MealCategoryList &&
                      filterMeals(
                        fieldArrayRenderProps.formRenderProps.valueGetter(
                          `${name}.${index}.MealType`
                        )
                      )
                    }
                  />
                </GridLayoutItem>
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
    </GridLayout>
  );
};

export default MealDetailsArray;
