import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { FiEdit } from "react-icons/fi";
import { Switch } from "@progress/kendo-react-inputs";
import AlertBox from "../../components/common/AlertBox";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { MdDelete } from "react-icons/md";
import {
  ActiveInactiveTour,
  deleteTour,
  getAllTours,
} from "./services/tour.services";
import { clearTourID, setTourID } from "./tourSlice";
import { useNavigate } from "react-router-dom";

const Tour: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllTours());
  }, []);

  const handleCreate = () => {
    navigate("/tour/create");
  };

  return (
    <>
      <DeleteTourDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Tour List
          </Typography.h4>
          <Button
            onClick={handleCreate}
            fillMode={"solid"}
            themeColor={"primary"}
          >
            {"Create Tour"}
          </Button>
        </GridLayoutItem>
        <GridLayoutItem>
          <TourGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const TourGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const TourList = useAppSelector((state) => state.tour.TourList);
  const loading = useAppSelector((state) => state.tour.loading);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? TourList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (item: any) => {
      navigate("/tour/edit", {
        state: {
          TourID: item?.ID,
          NoOfDays: item?.NoOfDays,
          SectorID: item?.SectorID,
        },
      });
    };
    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteTour"));
      dispatch(setTourID(ID));
    };

    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEdit(props.dataItem)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem.ID)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
    const [IsActive, setIsActive] = useState<boolean>(props.dataItem.IsActive);

    const handleActiveInActive = () => {
      setIsActive(!IsActive);
      const data = {
        ID: props.dataItem.ID,
        IsActive: !IsActive,
      };
      dispatch(ActiveInactiveTour(data)).then((response: any) => {
        if (response.payload?.data?.Status === 200) {
          dispatch(getAllTours());
        } else {
          dispatch(getAllTours());
        }
      });
    };

    return (
      <td style={{ textAlign: "center" }}>
        <Switch
          name="IsActive"
          checked={IsActive}
          onChange={handleActiveInActive}
          size="small"
        />
      </td>
    );
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {TourList && TourList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(TourList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : TourList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={TourList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="TourName"
            title="Tour"
            cell={(props: any) => (
              <td>
                <div
                  className="hoverableName"
                  onClick={() =>
                    navigate("/tour/edit", {
                      state: {
                        TourID: props.dataItem?.ID,
                        TourCode: props.dataItem?.TourCode,
                        SectorID: props.dataItem?.SectorID,
                      },
                    })
                  }
                >
                  {props.dataItem?.TourName}
                </div>
              </td>
            )}
          />
          <Column field="NoOfNights" title="Nights" />
          <Column field="TravelType" title="Travel Type" />
          <Column
            field="ID"
            title="Status"
            cell={MyActiveInActtiveCommandCell}
            width={"90px"}
            filterable={false}
            locked={true}
          />
          <Column
            field="ID"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteTourDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const TourID = useAppSelector((state) => state.tour.TourID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteTour(ID));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(getAllTours());
        dispatch(closeDialog());
        dispatch(clearTourID());
      } else {
        dispatch(getAllTours());
        dispatch(closeDialog());
        dispatch(clearTourID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteTour" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Tour"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Tour?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(TourID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default Tour;
