import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ISector } from "../sectorModel";

export const getAllSectors = createAsyncThunk(
  "Sector/FindAllSector",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sector/FindAllSector`,
        {
          SectorName: "",
        }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Sectors:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createSector = createAsyncThunk(
  "Sector/InsertSector",
  async (SectorData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sector/InsertSector`,
        SectorData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Sector:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateSector = createAsyncThunk(
  "Sector/UpdateSector",
  async (SectorData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sector/UpdateSector`,
        SectorData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Sector:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteSector = createAsyncThunk(
  "Sector/DeleteSector",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sector/DeleteSector`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Sector:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSectorByID = createAsyncThunk(
  "Sector/FindByIDSector",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sector/FindByIDSector`,
        { ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        CityID: result.CityID
          ? result.CityID?.split(",")?.map((e: string) => +e)
          : [],
        CountryID: result.CountryID
          ? result.CountryID?.split(",")?.map((e: string) => +e)
          : [],
        Prefix: result.Prefix,
        RegionID: result.RegionID,
        SectorName: result.SectorName,
        SectorType: result.SectorType,
        IsActive: result.IsActive,
      } as ISector;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Sector:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveSectors = createAsyncThunk(
  "Sector/FindAllActiveSector",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Sector/FindAllActiveSector`
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching sectors:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveSector = createAsyncThunk(
  "Sector/ActiveInActiveSector",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sector/ActiveInActiveSector`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive sector:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
