import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import RippleButton from "../../components/common/RippleButton";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import { getAllActiveCities } from "../city/services/city.services";
import {
  createHotel,
  getHotelByID,
  updateHotel,
  uploadHotelImage,
} from "./services/hotel.services";
import { clearHotelDetails } from "./hotelSlice";
import { ICity } from "../city/cityModel";
import { findIndex } from "lodash";
import { IState } from "../state/stateModel";
import { ICountry } from "../country/countryModel";
import { STARRATING, STATUSARRAY } from "../../_contstants/common";
import HotelContactArray from "./HotelContactArray";
import { getAllActiveStates } from "../state/services/state.services";
import { getAllActiveCountries } from "../country/services/country.services";
import HotelRatesArray from "./HotelRatesArray";
import moment from "moment";
import FormImageUpload from "../../components/formFields/FormImageUpload";

interface CityChangeWatcherProps {
  formRenderProps: FormRenderProps;
  CityList: ICity[];
}

const CityChangeWatcher: React.FC<CityChangeWatcherProps> = ({
  CityList,
  formRenderProps,
}) => {
  const cityID = formRenderProps.valueGetter("CityID");

  useEffect(() => {
    const CityID = cityID ? cityID : undefined;
    const cityIndex = findIndex(CityList, { ID: CityID });
    if (cityIndex > -1) {
      const city = CityList[cityIndex];
      formRenderProps.onChange("StateID", { value: city.StateID });
      formRenderProps.onChange("CountryID", { value: city.CountryID });
    } else {
      formRenderProps.onChange("CityID", { value: "" });
      formRenderProps.onChange("StateID", { value: "" });
      formRenderProps.onChange("CountryID", { value: "" });
    }
  }, [cityID]);
  return null;
};

const CreateHotel: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const HotelID = location.state?.HotelID;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();

  const loading = useAppSelector((state: RootState) => state.hotel.loading);
  const HotelDetail = useAppSelector(
    (state: RootState) => state.hotel.HotelDetail
  );
  const CityList = useAppSelector((state: RootState) => state.city.CityList);
  const StateList = useAppSelector((state) => state.state.StateList);
  const CountryList = useAppSelector((state) => state.country.CountryList);

  useEffect(() => {
    if (HotelID) {
      dispatch(getHotelByID(HotelID));
    }
    return () => {
      dispatch(clearHotelDetails());
    };
  }, [HotelID]);

  useEffect(() => {
    dispatch(getAllActiveCities());
    dispatch(getAllActiveStates());
    dispatch(getAllActiveCountries());
  }, []);

  const handleSubmit = async (values: any) => {
    const HotelImage = values.HotelImage;
    if (HotelID) {
      const editPayload = {
        ID: values.ID,
        HotelName: values.HotelName,
        HotelCode: values.HotelCode,
        HotelDisplayName: values.HotelDisplayName,
        HotelAddress: values.HotelAddress,
        LandMark: values.LandMark,
        CountryID: values.CountryID,
        StateID: values.StateID,
        CityID: values.CityID,
        LatitudeOfHotel: values.LatitudeOfHotel ? values.LatitudeOfHotel : "",
        LongitudeOfHotel: values.LongitudeOfHotel
          ? values.LongitudeOfHotel
          : "",
        MapURL: values.MapURL ? values.MapURL : "",
        StarRating: values.StarRating ? values.StarRating : null,
        PropertyType: values.PropertyType ? values.PropertyType : "",
        Inclusions: values.Inclusions ? values.Inclusions : "",
        Exclusions: values.Exclusions ? values.Exclusions : "",
        HotelInformation: values.HotelInformation
          ? values.HotelInformation
          : "",
        VendorID: values.VendorID ? values.VendorID : null,
        IsActive: values.IsActive === 1,
        HotelContacts: values.HotelContacts
          ? values.HotelContacts.map((contact: any) => {
              return {
                HotelID: contact.HotelID ? contact.HotelID : null,
                PersonName: contact.PersonName ? contact.PersonName : "",
                MobileNo: contact.MobileNo ? contact.MobileNo.toString() : "",
                EmailID: contact.EmailID ? contact.EmailID : "",
                AlternateMobileNo: contact.AlternateMobileNo
                  ? contact.AlternateMobileNo.toString()
                  : "",
                AlternateEmailID: contact.AlternateEmailID
                  ? contact.AlternateEmailID
                  : "",
                IsOwner: contact.IsOwner === 1,
              };
            })
          : [],
        HotelRates: values.HotelRates
          ? values.HotelRates.map((rates: any) => {
              return {
                FromDate: rates.FromDate
                  ? moment(rates.FromDate).format("YYYY-MM-DD")
                  : "",
                ToDate: rates.ToDate
                  ? moment(rates.ToDate).format("YYYY-MM-DD")
                  : "",
                Rate: rates.Rate ? +rates.Rate : null,
              };
            })
          : [],
      };
      try {
        const response = await dispatch(updateHotel(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          if (HotelImage && HotelID && typeof HotelImage !== "string") {
            const ImageData = new FormData();
            const file = HotelImage[0].getRawFile();
            ImageData.append("ID", HotelID);
            ImageData.append("Image", file);
            const responseData = await dispatch(uploadHotelImage(ImageData));
            if (responseData?.meta?.requestStatus === "fulfilled") {
              navigate(-1);
              dispatch(clearHotelDetails());
            } else {
              navigate(-1);
              dispatch(clearHotelDetails());
            }
          } else {
            navigate(-1);
          }
          // navigate("/hotel");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      const insertPayload = {
        HotelName: values.HotelName,
        HotelCode: values.HotelCode,
        HotelDisplayName: values.HotelDisplayName,
        HotelAddress: values.HotelAddress,
        LandMark: values.LandMark,
        CountryID: values.CountryID,
        StateID: values.StateID,
        CityID: values.CityID,
        LatitudeOfHotel: values.LatitudeOfHotel ? values.LatitudeOfHotel : "",
        LongitudeOfHotel: values.LongitudeOfHotel
          ? values.LongitudeOfHotel
          : "",
        MapURL: values.MapURL ? values.MapURL : "",
        StarRating: values.StarRating ? values.StarRating : null,
        PropertyType: values.PropertyType ? values.PropertyType : "",
        Inclusions: values.Inclusions ? values.Inclusions : "",
        Exclusions: values.Exclusions ? values.Exclusions : "",
        HotelInformation: values.HotelInformation
          ? values.HotelInformation
          : "",
        VendorID: values.VendorID ? values.VendorID : null,
        IsActive: values.IsActive === 1,
        HotelContacts: values.HotelContacts
          ? values.HotelContacts.map((contact: any) => {
              return {
                HotelID: contact.HotelID ? contact.HotelID : null,
                PersonName: contact.PersonName ? contact.PersonName : "",
                MobileNo: contact.MobileNo ? contact.MobileNo.toString() : "",
                EmailID: contact.EmailID ? contact.EmailID : "",
                AlternateMobileNo: contact.AlternateMobileNo
                  ? contact.AlternateMobileNo.toString()
                  : "",
                AlternateEmailID: contact.AlternateEmailID
                  ? contact.AlternateEmailID
                  : "",
                IsOwner: contact.IsOwner === 1,
              };
            })
          : [],
        HotelRates: values.HotelRates
          ? values.HotelRates.map((rates: any) => {
              return {
                FromDate: rates.FromDate
                  ? moment(rates.FromDate).format("YYYY-MM-DD")
                  : "",
                ToDate: rates.ToDate
                  ? moment(rates.ToDate).format("YYYY-MM-DD")
                  : "",
                Rate: rates.Rate ? +rates.Rate : null,
              };
            })
          : [],
      };
      try {
        const response = await dispatch(createHotel(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          if (
            HotelImage &&
            response.payload.Data.ID &&
            typeof HotelImage !== "string"
          ) {
            const ImageData = new FormData();
            const file = HotelImage[0].getRawFile();
            ImageData.append("ID", response.payload.Data.ID);
            ImageData.append("Image", file);
            const responseData = await dispatch(uploadHotelImage(ImageData));
            if (responseData?.meta?.requestStatus === "fulfilled") {
              navigate(-1);
              dispatch(clearHotelDetails());
            } else {
              navigate(-1);
              dispatch(clearHotelDetails());
            }
          } else {
            navigate(-1);
          }
          // navigate("/hotel");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={HotelDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {HotelID ? "Update Hotel" : "Create Hotel"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="HotelName"
                    label="Hotel Name"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="HotelCode"
                    label="Hotel Code"
                    component={FormTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="HotelDisplayName"
                    label="Hotel Display Name"
                    component={FormTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    name="HotelAddress"
                    label="Hotel Address"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="LandMark"
                    label="LandMark"
                    component={FormTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"CityID"}
                    name={"CityID"}
                    label={"City"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={CityList?.map((city: ICity) => {
                      return {
                        value: city?.ID,
                        label: city?.CityName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"StateID"}
                    name={"StateID"}
                    label={"State"}
                    component={FormSelectionField}
                    disabled={true}
                    // validator={requiredValidator}
                    options={StateList?.map((state: IState) => {
                      return {
                        value: state?.ID,
                        label: state?.StateName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"CountryID"}
                    name={"CountryID"}
                    label={"Country"}
                    component={FormSelectionField}
                    disabled={true}
                    // validator={requiredValidator}
                    options={CountryList?.map((country: ICountry) => {
                      return {
                        value: country?.ID,
                        label: country?.CountryName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="LatitudeOfHotel"
                    label="Latitude Of Hotel"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="LongitudeOfHotel"
                    label="Longitude Of Hotel"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="MapURL"
                    label="Map URL"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name={"StarRating"}
                    label={"Star Rating"}
                    component={FormSelectionField}
                    options={STARRATING?.map((rating: any) => {
                      return {
                        value: rating?.value,
                        label: rating?.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id={"VendorID"}
                    name={"VendorID"}
                    label={"Vendor"}
                    component={FormSelectionField}
                    options={VendorList?.map((vendor: IVendor) => {
                      return {
                        value: vendor?.ID,
                        label: vendor?.VendorName,
                      };
                    })}
                  />
                </GridLayoutItem> */}
                <GridLayoutItem>
                  <Field
                    name="PropertyType"
                    label="Property Type"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`IsActive`}
                    label="Status"
                    component={FormSelectionField}
                    // validator={requiredValidator}
                    options={STATUSARRAY?.map((status: any) => {
                      return {
                        value: status?.value,
                        label: status?.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="Inclusions"
                    label="Inclusions"
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="Exclusions"
                    label="Exclusions"
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="HotelInformation"
                    label="Hotel Information"
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="HotelImage"
                    label="Image"
                    component={FormImageUpload}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ marginTop: "10px" }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem style={{ marginTop: 10 }} colSpan={3}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={HotelContactArray}
                    name="HotelContacts"
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ marginTop: "10px" }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem style={{ marginTop: 10 }} colSpan={3}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={HotelRatesArray}
                    name="HotelRates"
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 10,
                  }}
                >
                  <RippleButton
                    type="button"
                    fillMode="outline"
                    themeColor="primary"
                    style={{ marginRight: 4 }}
                    onClick={() => navigate("/hotel")}
                  >
                    Cancel
                  </RippleButton>
                  <ButtonWithLoading
                    label={HotelID ? "Update" : "Create"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                </GridLayoutItem>
              </GridLayout>
              <CityChangeWatcher
                formRenderProps={formRenderProps}
                CityList={CityList}
              />
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateHotel;
