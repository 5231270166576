import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ITransferLocation } from "../transferLocationModel";

export const getAllTransferLocation = createAsyncThunk(
  "TransferLocation/FindAllTransferLocation",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TransferLocation/FindAllTransferLocation`,
        {
          LocationName: "",
        }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Transfer Locations:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveTransferLocation = createAsyncThunk(
  "TransferLocation/FindAllActiveTransferLocation",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/TransferLocation/FindAllActiveTransferLocation`
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Transfer Locations:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createTransferLocation = createAsyncThunk(
  "TransferLocation/InsertTransferLocation",
  async (TransferLocationData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TransferLocation/InsertTransferLocation`,
        TransferLocationData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Transfer Location:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateTransferLocation = createAsyncThunk(
  "TransferLocation/UpdateTransferLocation",
  async (TransferLocationData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TransferLocation/UpdateTransferLocation`,
        TransferLocationData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Transfer Location:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteTransferLocation = createAsyncThunk(
  "TransferLocation/DeleteTransferLocation",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TransferLocation/DeleteTransferLocation`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Transfer Location:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTransferLocationByID = createAsyncThunk(
  "TransferLocation/FindByIDTransferLocation",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TransferLocation/FindByIDTransferLocation`,
        { ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        SectorID: result.SectorID,
        CityID: result.CityID,
        LocationName: result.LocationName,
        IsActive: result.IsActive,
      } as ITransferLocation;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Transfer Location:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveTransferLocation = createAsyncThunk(
  "TransferLocation/ActiveInActiveTransferLocation",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TransferLocation/ActiveInActiveTransferLocation`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Transfer Location:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTransferLocationBySectorID = createAsyncThunk(
  "TransferLocation/FindTransferLocationBySectorID",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TransferLocation/FindTransferLocationBySectorID`,
        {
          SectorID: ID,
        }
      );
      return response.data?.Data as any[];
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Transfer Location by Sector:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTransferLocationsByCityID = createAsyncThunk(
  "TransferLocation/FindTransferLocationByCityID",
  async (CityID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TransferLocation/FindTransferLocationByCityID`,
        {
          CityID: CityID,
        }
      );
      return response.data?.Data as any[];
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Transfers:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
