import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  createSpecialityType,
  getAllSpecialityTypes,
  getSpecialityTypeByID,
  updateSpecialityType,
} from "./services/specialityType.services";
import { clearSpecialityTypeDetails } from "./specialityTypeSlice";
import { closed } from "../../components/drawer/drawerSlice";

const CreateSpecialityType: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const SpecialityTypeDetail = useAppSelector(
    (state) => state.specialityType.SpecialityTypeDetail
  );
  const SpecialityTypeID = useAppSelector((state) => state.drawer.data);
  const formLoading = useAppSelector(
    (state) => state.specialityType.formLoading
  );

  useEffect(() => {
    if (SpecialityTypeID) {
      dispatch(getSpecialityTypeByID(SpecialityTypeID));
    }
  }, [SpecialityTypeID]);

  useEffect(() => {
    return () => {
      dispatch(clearSpecialityTypeDetails());
      dispatch(closed());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      if (!SpecialityTypeID) {
        const insertPayload = {
          SpecialityType: values.SpecialityType,
          Prefix: values.Prefix,
          IsActive: values.IsActive,
        };
        const response = await dispatch(createSpecialityType(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearSpecialityTypeDetails());
          dispatch(getAllSpecialityTypes());
        }
      } else {
        const editPayload = {
          ID: SpecialityTypeID,
          SpecialityType: values.SpecialityType,
          Prefix: values.Prefix,
          IsActive: values.IsActive,
        };
        const response = await dispatch(updateSpecialityType(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearSpecialityTypeDetails());
          dispatch(getAllSpecialityTypes());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={SpecialityTypeDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="SpecialityType"
                label="Speciality Type"
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="Prefix"
                label="Prefix"
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "end",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginRight: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
              <ButtonWithLoading
                label={SpecialityTypeID ? "Update" : "Create"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateSpecialityType;
