import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMealCategory, IMealCategoryState } from "./mealCategoryModel";
import {
  ActiveInactiveMealCategory,
  createMealCategory,
  deleteMealCategory,
  getAllActiveMealCategories,
  getAllMealCategories,
  getMealCategoryByID,
  updateMealCategory,
} from "./services/mealCategory.services";

const initialState: IMealCategoryState = {
  loading: false,
  formLoading: false,
  error: "",
  MealCategoryList: [],
  MealCategoryID: null,
  MealCategoryDetail: {
    Title: "",
    Type: null,
    IsActive: false,
  },
};

const mealCategorySlice = createSlice({
  name: "mealCategory",
  initialState,
  reducers: {
    clearMealCategoryDetails: (state) => {
      state.MealCategoryDetail = initialState.MealCategoryDetail;
    },
    setMealCategoryID: (state, action) => {
      state.MealCategoryID = action.payload;
    },
    clearMealCategoryID: (state) => {
      state.MealCategoryID = initialState.MealCategoryID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllMealCategories.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllMealCategories.fulfilled,
      (state, action: PayloadAction<IMealCategory[]>) => {
        state.loading = false;
        state.MealCategoryList = action.payload || [];
      }
    );
    builder.addCase(getAllMealCategories.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.MealCategoryList = [];
    });
    builder.addCase(getAllActiveMealCategories.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveMealCategories.fulfilled,
      (state, action: PayloadAction<IMealCategory[]>) => {
        state.loading = false;
        state.MealCategoryList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveMealCategories.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.MealCategoryList = [];
    });
    builder.addCase(createMealCategory.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createMealCategory.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createMealCategory.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateMealCategory.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateMealCategory.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateMealCategory.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteMealCategory.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteMealCategory.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteMealCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(ActiveInactiveMealCategory.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveMealCategory.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveMealCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getMealCategoryByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getMealCategoryByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.MealCategoryDetail = action.payload;
    });
    builder.addCase(getMealCategoryByID.rejected, (state, action) => {
      state.formLoading = false;
      state.MealCategoryDetail = initialState.MealCategoryDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearMealCategoryID,
  setMealCategoryID,
  clearMealCategoryDetails,
} = mealCategorySlice.actions;
export default mealCategorySlice.reducer;
